import React, { useEffect, useRef, useState } from "react";
import userStyles from "../../styles/UI/FaceCamStyles";
import Webcam from "react-webcam";
import { useDispatch, useSelector, useStore } from "react-redux";
import { detectFace, setUnUsedCount, clearUnUsedCount} from "../../store/actions/faceCamActions";
import classnames from "classnames";
import DetectionInfoModal from "./modals/DetectionInfoModal";
import { setCanUseRecognitionAPI } from "../../store/actions/userActions";
import Button from "@material-ui/core/Button";
import { useApplicationSettings } from "../../hooks/settings/useApplicationSettings";
import PropTypes from "prop-types";
import {
    selectCameraInfo,
    selectDetection,
    selectDetectionInterval,
    selectDetections,
    selectIsUser,
    selectUserFalseCount,
} from "../../store/selectors/faceCamSelectors";
import {
    selectIsUserRecognized,
    selectUserFaceId,
    selectUserInfo,
} from "../../store/selectors/userSelectors";
import { selectIsChecked } from "../../store/selectors/chatSelectors";
import { selectUpdateSettings } from "../../store/selectors/settingSelectors";
import SupportedLanguages from "./SupportedLanguages";
import { env } from "../../config/env"; 

const FaceCam = (props) => {
    const classes = userStyles();
    const { width, height, faceCamStyle, ...other } = props;
    const dispatch = useDispatch();
    const store = useStore();
    const { faceRecognition = null, camera = null } = useApplicationSettings([
        "faceRecognition",
        "camera",
    ]);
    const webcamRef = useRef(null);
    const cameraInfo = useSelector(selectCameraInfo);
    const detectionInterval = useSelector(selectDetectionInterval);
    const detections = useSelector(selectDetections);
    const isUserRecognized = useSelector(selectIsUserRecognized);
    const userFaceId = useSelector(selectUserFaceId);
    const userInfo = useSelector(selectUserInfo);
    const isUser = useSelector(selectIsUser);
    const detection = useSelector(selectDetection);
    const unusedCount = useSelector((state) => state.faceCam.unusedCount);
    const [isDetectionInfoModalOpen, setIsDetectionInfoModalOpen] =
        useState(false);
    const [canDetectCount, setCanDetectCount] = useState(0);

    const detectionTimerIntervalRef = useRef(null);
    const registrationTimerIntervalRef = useRef(null);
    const runDetectionTimerRef = useRef(null);

    const [width_, setWidth_] = useState(null);
    const [height_, setHeight_] = useState(null);
    const canDetectCountRef = useRef(canDetectCount);
    canDetectCountRef.current = canDetectCount;
    const cameraSettingsRef = useRef(camera);
    cameraSettingsRef.current = camera;
    const faceRecognitionSettingsRef = useRef(faceRecognition);
    faceRecognitionSettingsRef.current = faceRecognition;
    const updateSettings = useSelector(selectUpdateSettings);
    const isUserFalseCount = useSelector(selectUserFalseCount);
    const isChecked = useSelector(selectIsChecked);

    const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);

    const sizeHeight = window?.innerHeight > 1700 ? true : false;

    const REGISTRATION_DETECTION_INTERVAL = parseInt(
        process.env.REACT_APP_REGISTRATION_DETECTION_INTERVAL
    );
    const DETECTION_INTERVAL = parseInt(
        process.env.REACT_APP_DETECTION_INTERVAL
    );
    const RECOGNITION_INTERVAL = parseInt(
        process.env.REACT_APP_RECOGINITION_INTERVAL
    );
    const MIN_DETECTION_AREA = parseInt(
        process.env.REACT_APP_MIN_DETECTION_AREA
    );

    useEffect(() => {
        runDetection();
        return () => {
            clearTimeout(runDetectionTimerRef.current);
            clearInterval(detectionTimerIntervalRef.current);
            clearInterval(registrationTimerIntervalRef.current);
        };
    }, [detectionInterval, updateSettings, isChecked]);

    useEffect(() => {
        if (!isUser && unusedCount <= REGISTRATION_DETECTION_INTERVAL) {
            const interval = setInterval(() => {
                dispatch(setUnUsedCount(unusedCount))
            }, 250);
            return () => clearInterval(interval);
        } else if (isUser && unusedCount > 0) {
            dispatch(clearUnUsedCount())
        }
      }, [unusedCount, isUser]);

    const calculateWidth = () => {
        if (propIsValid(height) && cameraInfo !== null) {
            if (sizeHeight) {
                return height * cameraInfo.aspectRatio * 2.2
            }
            return height * cameraInfo.aspectRatio;
        } else if (cameraInfo !== null) {
            return 240 * cameraInfo.aspectRatio;
        }
    };

    const calculateHeight = () => (propIsValid(height) ? sizeHeight ? height * 2.2 : height : 240);

    useEffect(() => {
        setWidth_(calculateWidth());
        setHeight_(calculateHeight());
    }, [height]);
    const runDetection = () => {
        const runDetection_ = () => {
            if (cameraSettingsRef.current.on) {
                // if camera is on, proceed with detection
                dispatch(
                    setCanUseRecognitionAPI(
                        faceRecognitionSettingsRef.current.on
                    )
                ); // dispatch and update canUseRecognitionAPI variable
                if (detectionInterval === DETECTION_INTERVAL) {
                    detectionTimerIntervalRef.current = setInterval(() => {
                        const recognitionURL =
                            faceRecognitionSettingsRef.current.server;
                        const canUseRecognitionAPI =
                            faceRecognitionSettingsRef.current.on;
                        const minDetectionArea =
                            cameraSettingsRef.current.minDetectionArea ||
                            MIN_DETECTION_AREA;
                        const canDetect = canUseRecognitionAPI
                            ? canDetectCountRef.current >= 1
                            : false;
                        dispatch(
                            detectFace(
                                webcamRef,
                                recognitionURL,
                                canDetect,
                                minDetectionArea,
                                false, 
                                isChecked
                            )
                        );
                        if (!canDetect && canUseRecognitionAPI)
                            setCanDetectCount(
                                (val) =>
                                    val +
                                    DETECTION_INTERVAL / RECOGNITION_INTERVAL
                            );
                        else if (canUseRecognitionAPI) setCanDetectCount(0);
                    }, DETECTION_INTERVAL);
                } else if (
                    detectionInterval === REGISTRATION_DETECTION_INTERVAL
                ) {
                    registrationTimerIntervalRef.current = setInterval(() => {
                        const recognitionURL =
                            faceRecognitionSettingsRef.current.server;
                        const minDetectionArea =
                            cameraSettingsRef.current.minDetectionArea ||
                            MIN_DETECTION_AREA;
                        dispatch(
                            detectFace(
                                webcamRef,
                                recognitionURL,
                                store.getState().user.canUseRecognitionAPI,
                                minDetectionArea,
                                true,
                                isChecked
                            )
                        );
                    }, REGISTRATION_DETECTION_INTERVAL);
                }
            }
        };
        if (
            cameraSettingsRef.current !== null &&
            faceRecognitionSettingsRef.current !== null &&
            DETECTION_INTERVAL <= RECOGNITION_INTERVAL
        )
            // if settings are not null
            runDetection_();
        else runDetectionTimerRef.current = setTimeout(runDetection, 1000);
    };

    const renderBoxes = ({ maxHeight, maxWidth }) => {
        return (
            <>
                {detections.map((detection, index) => (
                    <div
                        key={index}
                        className={classes.boxes}
                        onClick={() => handleShowDetectionInfo(detection)}
                        style={{
                            height: detection.detection._box._height + 60,
                            width: detection.detection._box._width,
                            transform: `translate(${getActualValue(
                                Number(maxWidth),
                                Number(detection.detection._box._x),
                                Number(detection.detection._box._width)
                            )}px,${getActualValue(
                                Number(maxHeight),
                                Number(detection.detection._box._y - 40),
                                Number(detection.detection._box._height + 60)
                            )}px)`,
                        }}
                    />
                ))}
            </>
        );
    };

    const getActualValue = (max, current, size) => {
        if (current < 0) {
            return 0;
        }
        if (current > max - size) {
            return max - size;
        }
        return current;
    };

    const renderUser = ({ maxHeight, maxWidth }) => {
        if (
            isUserRecognized &&
            detection !== null &&
            userFaceId !== null &&
            userInfo.displayName !== undefined &&
            userInfo.displayName !== null
        ) {
            return (
                <div
                    className={classes.userBox}
                    style={{
                        width: detection.detection._box._width,
                        height: detection.detection._box._height + 60,
                        transform: `translate(${getActualValue(
                            Number(maxWidth),
                            Number(detection.detection._box._x),
                            Number(detection.detection._box._width)
                        )}px,${getActualValue(
                            Number(maxHeight),
                            Number(detection.detection._box._y - 40),
                            Number(detection.detection._box._height + 60)
                        )}px)`,
                    }}
                >
                    <span
                        className={classes.usernameFrame}
                        style={{ width: detection.detection._box._width }}
                    >
                        {detection.gender === "male" ? "Mr. " : "Ms. "}{" "}
                        {userInfo.displayName}
                    </span>
                </div>
            );
        }
    };

    const handleShowDetectionInfo = () => {
        setIsDetectionInfoModalOpen(true);
    };

    // avoid webcam rerender
    const webcamStyle = React.useMemo(() => {
        return {
            visibility: camera?.show ? "visible" : "hidden",
        };
    }, [camera?.show]);

    // avoid webcam rerender
    const videoConstraints = React.useMemo(() => {
        return {
            width: width_,
            height: height_,
        };
    }, [width_, height_]);

    return (
        <>
            <SupportedLanguages width={calculateWidth()}/>
            <div {...other}>
                <div className={classes.face_position}>
                    {camera !== null && camera.on && (
                        <>
                            <div
                                className={classnames(
                                    classes.faceCam,
                                    faceCamStyle
                                )}
                                style={{ width: width_, height: height_}}
                                id={"faceCam"}
                            >
                                {camera.showFrame && camera.show && (
                                    <>
                                        {renderBoxes({
                                            maxWidth: width_,
                                            maxHeight: height_,
                                        })}
                                        {renderUser({
                                            maxWidth: width_,
                                            maxHeight: height_,
                                        })}
                                    </>
                                )}

                                <Webcam
                                    audio={false}
                                    style={webcamStyle}
                                    width={width_}
                                    height={height_}
                                    ref={webcamRef}
                                    screenshotQuality={1}
                                    videoConstraints={videoConstraints}
                                    screenshotFormat="image/jpeg"
                                />
                            </div>
                            {detection !== null && (
                                <DetectionInfoModal
                                    open={isDetectionInfoModalOpen}
                                    handleClose={() =>
                                        setIsDetectionInfoModalOpen(false)
                                    }
                                    detection={detection}
                                />
                            )}
                        </>
                    )}
                    {/*<img src={imgSrcCropped} alt=""/>*/}
                    {/*<img src={imgSrc} alt=""/>*/}
                    {/*<button onClick={capture}>capture</button>*/}
                    {camera !== null &&
                        camera.on &&
                        !camera.showFrame &&
                        (detection !== null || isUserFalseCount < env.REACT_APP_FALSE_COUNT_DETECT) && (
                            <div className={classes.nameContainer}>
                                <Button
                                    onClick={() =>
                                        handleShowDetectionInfo(detection)
                                    }
                                    className={classes.username}
                                >
                                    {userInfo.displayName !== undefined &&
                                        `${userInfo.displayName}`}
                                    {isUserFalseCount < env.REACT_APP_FALSE_COUNT_DETECT && <div className={classes.status} />}
                                </Button>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};

FaceCam.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    faceCamStyle: PropTypes.string,
    style: PropTypes.object,
};

export default FaceCam;
