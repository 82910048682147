import { makeStyles } from "@material-ui/core/styles";
import background from "../../images/background.jpg";

const useStyles = makeStyles(({ primaryColor, secondaryColor, primaryButtonTheme ,primaryButtonTextTheme }) => ({
    root: {
        // border: '2px solid black',
        backgroundImage: `url(${background})`,
        width: "100%",
        height: "100%",
    },
    subContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
    },
    subContainer2: {
        display: "flex",
    },
    helpSelection: {
        width: "70%",
        marginTop: "2em",
    },
    itemStyle: {
        height: "7em",
    },
    registrationRoot: {
        width: "60%",
        paddingTop: "2.5em",
    },
    title: {
        color: secondaryColor,
        fontSize: "1.6em",
        margin: 0,
        // fontWeight: "bold",
    },
    startButton: {
        width: "100%",
        alignItems: "center",
    },
    faceCam: {
        float: "none",
        display: "flex",
        // marginLeft: "5%"
    },
    registrationImage: {
        width: "5em",
        marginRight: "1em",
        marginTop: "1em",
    },
    grid: {
        // height: "65%",
    },
    margin_sm: {
        marginLeft: "0.5em",
    },
    start: {
        marginTop: "14%",
        position: "relative",
    },
    startBtnPosition: {
        position: "relative",
        zIndex: "1001",
        border: "6px solid " + primaryButtonTextTheme,
        borderRadius: 10,
    },
    start_item: {
        position: "absolute",
        left: "-10%",
        cursor: "pointer",
    },
    arrow_left: {
        fontSize: "4em",
        color: primaryButtonTheme
    },
}));

export default useStyles;
