import {
    TranscribeStreamingClient,
    StartStreamTranscriptionCommand,
} from "@aws-sdk/client-transcribe-streaming";
import MicrophoneStream from "microphone-stream";
import { useEffect, useState, useRef } from "react";
import ding_ from "../images/ding.wav";
import dong_ from "../images/dong.wav";
import { env } from "../config/env";

const ding = new Audio(ding_);
const dong = new Audio(dong_);

export const useTranscribeAWS = (languageCode) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [transcription, setTranscription] = useState("");
    const [transcriptionArray, setTranscriptionArray] = useState([]);
    // const [audioURL, setAudioURL] = useState("");
    const [base64, setBase64] = useState("");
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const micRef = useRef();
    const clientRef = useRef();
    const inputSampleRateRef = useRef();
    const MAX_AUDIO_CHUNK_SIZE = 48000;

    useEffect(() => {
        if (!mediaRecorder) {
            if (isRecording) {
                getMedia().then(setMediaRecorder, console.error);
            }
            return;
        }

        if (isRecording) {
            console.log("start recording");
            dong.play();
            mediaRecorder.start();
        } else {
            console.log("stop recording");
            ding.play();
            mediaRecorder.stop();
        }

        const handleData = (e) => {
            var reader = new window.FileReader();
            const blob = new Blob([e.data], { type: "audio/mpeg" });
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                let base64_ = reader.result;
                // cut data:audio/mpeg;base64,
                base64_ = base64_.split(",")[1];
                setBase64(base64_);
            };
            // setAudioURL(URL.createObjectURL(e.data));
        };

        mediaRecorder.addEventListener("dataavailable", handleData);
        return () =>
            mediaRecorder.removeEventListener("dataavailable", handleData);
    }, [mediaRecorder, isRecording]);

    const toggleStart = () => {
        window.navigator.mediaDevices
            .getUserMedia({
                video: false,
                audio: true,
            })
            .then(startRequest)
            .catch(function (error) {
                console.log(error);
                setErrorMessage(error.message);
            });
    };

    const toggleStop = () => {
        setIsRecording(false);
        if (micRef.current) {
            micRef.current.stop();
            // clientRef.current?.destroy();
            clientRef.current = null;
        }
    };

    const resetTranscript = () => {
        setTranscriptionArray([]);
        setTranscription("");
    };

    const updateTranscript = (val) => {
        setTranscriptionArray([val]);
    };

    async function startRequest(stream) {
        try {
            setIsRecording(true);
            setTranscriptionArray([]);
            setTranscription("");
            micRef.current = new MicrophoneStream();

            micRef.current.on("format", function (data) {
                inputSampleRateRef.current = data.sampleRate;
            });

            micRef.current.setStream(stream);

            clientRef.current = new TranscribeStreamingClient({
                region: process.env.REACT_APP_AWS_REGION,
                credentials: {
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey:
                        process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                },
            });

            const audioStream = async function* () {
                for await (const chunk of micRef.current) {
                    if (chunk.length <= MAX_AUDIO_CHUNK_SIZE) {
                        yield {
                            AudioEvent: {
                                AudioChunk:
                                    pcmEncodeChunk(
                                        chunk
                                    ) /* pcm Encoding is optional depending on the source */,
                            },
                        };
                    }
                }
            };

            const command = new StartStreamTranscriptionCommand({
                LanguageCode: languageCode,
                MediaEncoding: "pcm",
                MediaSampleRateHertz: env.REACT_APP_AWS_SAMPLERATE,
                AudioStream: audioStream(),
            });
            const response = await clientRef.current.send(command);
            onResult(response);
        } catch (err) {
            console.log(err);
            setErrorMessage(err.message);
            return;
        }
    }

    const onResult = async (response) => {
        if (response.TranscriptResultStream) {
            for await (const event of response.TranscriptResultStream) {
                if (event.TranscriptEvent) {
                    const results = event.TranscriptEvent.Transcript.Results;
                    if (results && results.length > 0) {
                        const [result] = results;
                        const final = !result.IsPartial;
                        const alternatives = result.Alternatives;
                        if (alternatives && alternatives.length > 0) {
                            const [alternative] = alternatives;
                            const text = alternative.Transcript;
                            if (final) {
                                setTranscription("");
                                setTranscriptionArray((pre) => [...pre, text]);
                            } else {
                                setTranscription(text);
                            }
                        }
                    }
                }
            }
        }
    };

    const pcmEncodeChunk = (chunk) => {
        const input = MicrophoneStream.toRaw(chunk);
        const buffer_ = downsampleBuffer(
            input,
            inputSampleRateRef.current,
            env.REACT_APP_AWS_SAMPLERATE
        );
        let offset = 0;
        const buffer = new ArrayBuffer(buffer_.length * 2);
        const view = new DataView(buffer);
        for (let i = 0; i < buffer_.length; i++, offset += 2) {
            let s = Math.max(-1, Math.min(1, buffer_[i]));
            view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
        return Buffer.from(buffer);
    };

    function downsampleBuffer(
        buffer,
        inputSampleRate = 44100,
        outputSampleRate = env.REACT_APP_AWS_SAMPLERATE
    ) {
        if (outputSampleRate === inputSampleRate) {
            return buffer;
        }

        var sampleRateRatio = inputSampleRate / outputSampleRate;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Float32Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;

        while (offsetResult < result.length) {
            var nextOffsetBuffer = Math.round(
                (offsetResult + 1) * sampleRateRatio
            );

            var accum = 0,
                count = 0;

            for (
                var i = offsetBuffer;
                i < nextOffsetBuffer && i < buffer.length;
                i++
            ) {
                accum += buffer[i];
                count++;
            }

            result[offsetResult] = accum / count;
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }

        return result;
    }

    async function getMedia() {
        let stream = null;
        try {
            stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            return new MediaRecorder(stream);
        } catch (err) {
            console.log(err.message);
            setErrorMessage(err.message);
            return;
        }
    }

    return [
        isRecording,
        base64,
        transcription,
        transcriptionArray,
        toggleStart,
        toggleStop,
        updateTranscript,
        resetTranscript,
        errorMessage,
    ];
};
