import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../constants";
import sizes from "../sizes";

const useStyles = makeStyles(({ primaryColor, widthButton, fontSizeButton }) => ({
    subRoot: {
        // marginTop: "2em",
        // paddingLeft: "1em",
        // border: "2px solid black",
        width: "100%",
        height: "20em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: "2rem",
        marginLeft: "0.5em",
        color: primaryColor,
        [sizes.down("lg")]: {
            fontSize: "1.5rem",
        },
        fontWeight: "bold",
    },
    info: {
        fontSize: "1.25rem",
        maxWidth: "70%",
        marginLeft: "0.5em",
        marginTop: "1em",
        color: primaryColor,
        [sizes.down("lg")]: {
            fontSize: "1rem !important",
            maxWidth: "85%",
        },
        [sizes.down("md")]: {
            fontSize: "0.65rem !important",
            maxWidth: "85%",
        },
        fontWeight: "bold",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        marginTop: "12%",
    },
    languageContainer: {
        marginTop: "1em",
        display: "flex",
    },
    langTitle: {
        fontSize: "1.5rem",
    },
    help: {
        padding: "0.75rem 1.5rem !important",
        whiteSpace: "normal !important",
        height: "95%",
        width: "90%",
        "& span:first-of-type": {
            padding: "2em 0",
        },
        [sizes.down("xl")]: {
            fontSize: "0.75rem !important",
        },
        [sizes.down("md")]: {
            fontSize: "0.5rem !important",
            // width: "70%"
        },
    },
    optionsIcon: {
        marginTop: "0.5em",
        width: "2em",
    },
    gridItem: {
        textAlign: "center",
        height: "6em",
        maxWidth: widthButton + "%" + " !important",
        flexBasis: widthButton + "%" + " !important",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    buttonContainer_span: {
        fontSize: fontSizeButton + "em"
    },
    grid: {
        height: "100%",
        overflowY: "auto",
    },
    '@media only screen and (max-width: 1100px) and (min-height: 1700px)': {
        root: {
            marginTop: 0,
        },
        title: {
            fontSize: "1.6rem",
        },
        info: {
            fontSize: "1.4rem !important",
        }
    },
}));

export default useStyles;
