import React, { useCallback } from "react";
import userStyles from "../../styles/UI/layout/HeaderStyles";
import { useDispatch, useSelector } from "react-redux";
import "moment-timezone";
import useTimer from "../../hooks/useTimer";
import timeIcon from "../../images/clock_icon_red.png";

const SupportedLanguages = ({ width }) => {
    const classes = userStyles();
    const language = useSelector((state) => state.language.currentLanguage);

    const format = useCallback(() => {
        let current = "";
        switch (language) {
            case "ja-JP":
                current = "YYYY年MM月DD日";
                break;
            case "ko-KR":
                current = "ddd, DD일 MMM YYYY";
                break;
            case "zh-CN":
                current = "星ddd YYYY年MM月DD日";
                break;
            case "zhTW-CN":
                current = "星ddd YYYY年MM月DD日";
                break;
            case "th-TH":
                current = "วันddd DD MMM YYYY";
                break;
            default:
                current = "ddd DD MMM YYYY";
        }
        return current;
    }, [language]);

    let { currentDate, currentTime } = useTimer("HH:mm:ss", format());

    return (
        <>
            <div
                className={classes.langSelectV2}
                style={{ width: width !== undefined ? width : "424px" }}
            >
                <img
                    src={timeIcon}
                    alt="clock-icon"
                    className={classes.clock_icon}
                />
                <div className={classes.time}>
                    <div className={classes.lang_date}>
                        <div className={classes.date_time}>{currentDate}</div>
                    </div>
                    <div className={classes.current_time}>
                        <div className={classes.timeV2}>
                            <div className={classes.date_time}>{currentTime}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SupportedLanguages;
