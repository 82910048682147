import React, { memo, useEffect, useState } from "react";
import userStyles from "../../../styles/UI/modals/FaceRegistrationModalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import { addToNotifications } from "../../../store/actions/notificationActions";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../utils/i18n";
import { useApplicationSettings } from "../../../hooks/settings/useApplicationSettings";
import PropTypes from "prop-types";
import { Transition } from "../settings/Settings";
import KeyboardWindow from "../KeyboardWindow";
import { selectCurrentLanguage } from "../../../store/selectors/languageSelectors"

const PasswordModal = (props) => {
    const classes = userStyles();
    const dispatch = useDispatch();
    const { open, handleClose, disabled, history, redirectTo, children } =
        props;
    const { password: appPassword } = useApplicationSettings(["password"]);

    const [step, setStep] = useState(0);
    const [layoutName, setLayoutName] = useState("default");
    const language = useSelector(selectCurrentLanguage);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isForcus, setIsForcus] = useState(false);
    const REGISTRATION_PASSWORD = appPassword || process.env.REACT_APP_PASSWORD;

    const verifyPassword = () => {
        if (password === REGISTRATION_PASSWORD) {
            if (redirectTo.length > 0) {
                history.push({
                    pathname: redirectTo,
                    state: { routeValidated: true },
                });
            } else setStep(1);
        } else
            dispatch(
                addToNotifications({
                    message: translate("Incorrect Password, try again!"),
                    type: "ERROR",
                    size: "sm",
                })
            );
    };

    const onKeyPress = (btn) => {
        if(btn === "{enter}"){
            if (password?.length > 0) {
                verifyPassword()
             }
         }
         else if(btn === "{lock}"){
            setIsForcus(false);
         }
         else if (btn === "{shift}"){
             setLayoutName(layoutName === "default" ? "shift" : "default");
         }
        
    }

    useEffect(() => {
        if (disabled) setStep(1);
        else if (open) {
            setStep(0);
            setPassword("");
        }
    }, [open]);

    const ModalPassword = (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={!isForcus ? handleClose : () => setIsForcus(false)}
                fullWidth={true}
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate("Password")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {translate("Enter password")}
                    </DialogContentText>
                    <FormControl className={classes.formItem}>
                        <TextField
                            label={translate("Password")}
                            placeholder={translate(
                                "Enter the application password to proceed"
                            )}
                            required
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={() => {setIsForcus(true)}}
                            onKeyPress={(e) => {
                                e.charCode === 13 && verifyPassword(); // if enter key is pressed redirect to product category and search
                            }}
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowPassword((val) => !val)
                                            }
                                        >
                                            {showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <DialogActions className={classes.button}>
                        <Button onClick={handleClose} color="primary">
                            {translate("Abort")}
                        </Button>
                        <Button
                            onClick={verifyPassword}
                            color="primary"
                            variant={"contained"}
                        >
                            {translate("Continue")}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {open && isForcus && <KeyboardWindow 
                language={language} 
                onChange={(e) => setPassword(e)}
                onKeyPress={onKeyPress} 
                layoutName={layoutName}/>
            }
        </>

    );

    return (
        <>
            {step === 0 && ModalPassword}
            {step === 1 && children}
        </>
    );
};
PasswordModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.element,
    disabled: PropTypes.bool,
    redirectTo: PropTypes.string,
};

PasswordModal.defaultProps = {
    redirectTo: "",
    disabled: false,
};
export default memo(PasswordModal);
