import { combineReducers } from "redux";
import chatReducer from "./chatReducer";
import notificationReducer from "./notificationReducer";
import faceCamReducer from "./faceCamReducer";
import receptionistReducer from "./receptionistReducer";
import languageReducer from "./languageReducer";
import userReducer from "./userReducer";
import settingsReducer from "./settingsReducer";
import teamReducer from "./teamReducer";

export default combineReducers({
    chat: chatReducer,
    notifications: notificationReducer,
    faceCam: faceCamReducer,
    receptionist: receptionistReducer,
    language: languageReducer,
    user: userReducer,
    teams: teamReducer,
    settings: settingsReducer,
});
