import React from "react";
import userStyles from "../../../styles/UI/layout/HeaderStyles";

const Header = ({logo, title}) => {
    // return null
    const classes = userStyles();
    return (
        <>
        <div className={classes.rootV2} >
            <div className={classes.header}>
                <h1 className={classes.header_welcome}>{title && title.text}</h1>
            </div>
        </div>
        <div className={classes.langLogoImage}>
            <img src={logo} alt="logo-chuzo" className={classes.image}/>
        </div>
        </>
    );
};

export default Header;
