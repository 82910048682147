import en from "./en.json";
import vi from "./vi.json";
import ja from "./ja.json";
import ko from "./ko.json";
import zh from "./zh.json";
import zhTW from "./zhTW.json";
import th from "./th.json";


export default {
    en,
    vi,
    ja,
    ko,
    zh,
    zhTW,
    th,
};
