import {
    SET_APPEARANCE_SETTINGS,
    SET_APPLICATION_SETTINGS,
    SET_IS_THEME_OVERRIDDEN,
    SET_KBOT_BUTTON_CONFIGS,
    SET_UPDATE_SETTINGS_FROM_DB,
    SET_WAITING_CALL_REQUEST_TO_TEAMS,
} from "../actions/types";

const initialState = {
    appearanceSettings: null,
    applicationSettings: null,
    kbotButtonConfigs: null,
    updateSettings: false,
    isThemeOverridden: false,
    isWaiting: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_APPEARANCE_SETTINGS:
            return {
                ...state,
                appearanceSettings: action.appearanceSettings,
            };
        case SET_APPLICATION_SETTINGS:
            return {
                ...state,
                applicationSettings: action.applicationSettings,
            };
        case SET_UPDATE_SETTINGS_FROM_DB:
            return {
                ...state,
                updateSettings: action.updateSettings,
            };
        case SET_IS_THEME_OVERRIDDEN:
            return {
                ...state,
                isThemeOverridden: action.isThemeOverridden,
            };
        case SET_KBOT_BUTTON_CONFIGS:
            return {
                ...state,
                kbotButtonConfigs: action.kbotButtonConfigs,
            };
        case SET_WAITING_CALL_REQUEST_TO_TEAMS:
            return {
                ...state,
                isWaiting: action.isWaiting,
            };
        default:
            return state;
    }
}
