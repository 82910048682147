import { makeStyles } from "@material-ui/core/styles";
import sizes from "../sizes";

const useStyles = makeStyles((theme) => ({
    root: {
        // border: '2px solid black',
        width: "33%",
        '@media only screen and (max-width: 1100px) and (min-height: 1700px)': {
            width: "35%",
        },
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        // [sizes.down('md2')]: {
        //     width: "25%",
        // }
    },
    receptionist: {
        width: "30%",
        position: "absolute",
        left: "0",
        maxHeight: "85%",
        maxWidth: "30%"
    },
    receptionist_img: {
        width: "100%",
    },
    receptionist_text: {
        position: "absolute",
        marginBottom: "34%",
        right: 0,
        bottom: 0,
        fontWeight: "bold",
        fontSize: (theme.receptionTextTheme.fontSize || "1.2") + "em",
        color: theme.receptionTextTheme.color,
    },
    receptionistContainer: {
        width: "100%",
        height: "100%",
        display: "none",
        alignItems: "flex-end",
    },
    flex: {
        display: "flex",
    },
    none: {
        display: "none",
    },
    block: {
        display: "block",
    },
    root_v2: {
        width: "30%",
    },
    '@media only screen and (max-width: 1100px) and (min-height: 1700px)': {
        root: {
            width: "35%",
        },
        receptionist: {
            maxWidth: "45%",
            width: "45%",
        }
    },
}));

export default useStyles;