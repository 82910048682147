import { makeStyles } from "@material-ui/core/styles";
import sizes from "../../sizes";

const useStyles = makeStyles(({ primaryColor }) => ({
    root: {
        width: "100%",
        height: "13%",
        display: "flex",
        padding: "1em 1.5em",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
    },
    logo: {
        height: "5em",
        width: "18em",
        [sizes.down("lg")]: {
            width: "20em",
            height: "6em",
        },
        [sizes.down("md1")]: {
            width: "16em",
            height: "4.5em",
        },
        [sizes.down("sm")]: {
            width: "7.5em",
            height: "2.5em",
        },
    },
    header: {
        // border: '2px solid black',
        // width: "60%",
        // display: "flex",
        textAlign: "center",
        alignItems: "center",
        // justifyContent: 'center',
        [sizes.down("sm")]: {
            width: "100%",
        },
    },
    title: {
        // display: 'inline-flex',
        fontSize: "2.25rem",
        fontWeight: "bold",
        marginTop: "0.35em",
        color: primaryColor,
        // color: colors.primaryColor,
        [sizes.down("lg")]: {
            fontSize: "2.25rem",
        },
        [sizes.down("md1")]: {
            fontSize: "1.75rem",
        },
        [sizes.down("sm")]: {
            fontSize: "1.25rem",
        },
    },
    subTitle: {
        fontSize: "3.25rem",
    },
    langSelect: {
        paddingTop: "1em",
        [sizes.down("md1")]: {
            marginLeft: "1em",
        },
        [sizes.down("md")]: {
            fontSize: "0.75rem",
        },
    },
    flag: {
        width: "2.5em",
        height: "1.5em",
        marginLeft: "0.25em",
        marginRight: "1em",
    },
    timer: {
        marginTop: "0.5em",
        display: "block",
        fontSize: "1.15em",
    },
    timer2: {
        display: "block",
        fontSize: "1.15em",
    },
    removeDefaultLink: {
        textDecoration: "none !important",
        color: "inherit !important",
    },
    rootV2: {
        width: "100%",
        height: "13%",
        display: "flex",
        padding: "1em 1.5em",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: primaryColor,
    },
    header_welcome: {
        color: '#fff',
        fontSize: "4.5em",
        margin: 0,
        padding: 0
    },
    langSelectV2: {
        position: "absolute",
        right: 0,
        top: "13%",
        display: "flex",
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        zIndex: 100,
        alignItems: "center",
    },
    time: {
        display: "flex",
        width: '90%'
    },
    timeV2: {
        display: "flex",
        flexDirection: "column",
    },
    current_time: {
        display: "flex",
        alignItems: "center",
        width: "30%"
    },
    langLogoImage: {
        marginLeft: "auto",
        marginRight: "auto",
        position: "absolute",
        left: 0,
        right: 0,
        textAlign: "center",
        width: "20%",    
        marginTop: "1.5em"
    },
    image: {
        height: "3em",
    },
    menuItem: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    clock_icon: {
        height: '2rem',
        marginLeft: 12
    },
    date_time: {
        fontWeight: 'bold',
        fontSize: "1.3em",
        marginRight: "0.8em",
        '@media only screen and (max-width: 1500px)': {
            fontSize: "1.2em",
            marginRight: 0
        },
    },
    selectItems: {
        width: '90%',
        "&::before": {
            border: 0
        },
        "&::after": {
            border: 0
        },
    },
    lang_date: {
        padding: 4,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "65%",
    },
    "@media only screen and (max-width: 1450px) and (min-height: 720px)": {
        clock_icon: {
            marginRight: 12
        },
        time: {
            flexDirection: "column",
            alignItems:"center",
        },
        lang_date: {
            width: "100%",
            display: "block",
            padding: 0
        },
        current_time: {
            width: "100%",
        }
    }
}));

export default useStyles;
