import React, { useEffect, useState } from "react";
import userStyles from "../../styles/pages/FaceRegistrationPageStyles";
import { Progress, Spin } from "antd";
import "antd/dist/antd.css";
import { useDispatch, useSelector, useStore } from "react-redux";
import FaceCam from "../UI/FaceCam";
import ButtonSecondary from "../Customized Libraries/CustomButtons/Button";
import {
    setDetectionInterval,
    setIsRegistering,
} from "../../store/actions/faceCamActions";
import { registerUser, setCurrentUserInfo } from "../../store/actions/userActions";
import { speakNoChunk, getQuestions, updateFaceId } from "../../store/actions/chatActions";
import { useApplicationSettings } from "../../hooks/settings/useApplicationSettings";
import { selectCurrentLanguage } from "../../store/selectors/languageSelectors";
import { Grid } from "@material-ui/core";
import { useWindowSize } from "../../hooks/useWindowSize";
import sizes from "../../styles/sizes";
import PropTypes from "prop-types";
import { translate } from "../../utils/i18n";
import { env } from "../../config/env";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const FaceRegistrationPage = (props) => {
    const dispatch = useDispatch();
    const store = useStore();
    const classes = userStyles();
    const { history } = props;
    const state = props.location.state;

    const [cameraHeight, setCameraHeight] = useState(290);
    const [width, height] = useWindowSize();

    const { faceRecognition } = useApplicationSettings(["faceRecognition"]);

    const registrationImages = useSelector(
        (state) => state.faceCam.registrationImages
    );
    const language = useSelector(selectCurrentLanguage);

    const isRegistering = useSelector((state) => state.faceCam.isRegistering);

    useEffect(() => {
        if (
            state === undefined ||
            state.firstName === undefined ||
            state.lastName === undefined ||
            state.role === undefined
        ) {
            history.push("/");
        } else {
            const handleWelcome = async () => {
                const voice =  translate("We'll take your pictures for the registration process.").replace("<name>", state?.displayName)
                            + translate("Click 'Start' when you're ready");
                await dispatch(speakNoChunk(voice, language, false, true))
            };
            handleWelcome();
        }
    }, []);

    useEffect(() => {
        if (width !== 0 && width < parseInt(sizes.sizesNoPx.xl)) {
            setCameraHeight(200);
        } else setCameraHeight(290);
    }, [width, height]);

    const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const handleFaceRecognition = async () => {
        dispatch(setIsRegistering(true));
        dispatch(
            setDetectionInterval(env.REACT_APP_REGISTRATION_DETECTION_INTERVAL)
        );
        setTimeout(() => {
            dispatch(setDetectionInterval(env.REACT_APP_DETECTION_INTERVAL));
        }, 6000);
        await timeout(6000);
        const {
            redirectTo = "/",
            showRegistrationModal = true,
            ...user
        } = state;
        const userInfo = await dispatch(
            registerUser(
                user,
                store.getState().faceCam.registrationImages,
                faceRecognition.server
            )
        );
        const state_ = { showRegistrationModal };
        dispatch(setCurrentUserInfo(userInfo));
        await dispatch(updateFaceId({
            user_id: userInfo.code,
            face_id: userInfo._id,
        }));
        await dispatch(getQuestions({
            face_id: userInfo?._id,
            qtime: 1
        }));
        history.push({
            pathname: redirectTo,
            state: state_,
        });
    };

    const handleBackStep = () => {
        history.push("/");
    }

    return (
        <>
            <div className={classes.registrationRoot}>
                <div className={classes.start}>
                    <div className={classes.start_item} onClick={handleBackStep}>
                        <ArrowBackIcon className={classes.arrow_left}/>
                    </div>
                    <div>
                        <p className={classes.title}>
                            {translate(
                                "We'll take your pictures for the registration process."
                            ).replace("<name>", state?.displayName)}
                        </p>
                        <p className={classes.title}>
                            {translate("Click 'Start' when you're ready")}
                        </p>
                    </div>
                </div>            
                <Grid
                    container
                    alignItems={"center"}
                    className={classes.grid}
                    id={"optionContent"}
                >
                    <Grid item xs={6} className={classes.gridItem}>
                        <FaceCam
                            faceCamStyle={classes.faceCam}
                            height={cameraHeight}
                        />
                        <div className={classes.startButton} id={"white"}>
                            <ButtonSecondary
                                color={"primaryButtonTheme"}
                                textColor={"buttonTextTheme"}
                                onClick={handleFaceRecognition}
                                size={"lg"}
                                disabled={isRegistering}
                                className={classes.startBtnPosition}
                            >
                                {isRegistering ? (
                                    <span>
                                        <Spin />{" "}
                                        <span className={classes.margin_sm}>
                                            {" "}
                                            {translate("Registering")}
                                        </span>
                                    </span>
                                ) : (
                                    <span>{translate("Start")}</span>
                                )}
                            </ButtonSecondary>
                        </div>
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        {registrationImages.map((imageSrc, index) => (
                            <img
                                src={imageSrc}
                                alt={`registration ${index}`}
                                key={index}
                                className={classes.registrationImage}
                            />
                        ))}
                    </Grid>
                </Grid>

                {registrationImages.length > 0 && (
                    <Progress
                        percent={registrationImages.length * 10}
                        status={"active"}
                    />
                )}
            </div>
        </>
    );
};

FaceRegistrationPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default FaceRegistrationPage;
