import React, { useEffect, useMemo, useState } from "react";
import useStyles from "../../styles/pages/HomePageStyles";
import FaceCam from "../UI/FaceCam";
import QaSelection from "../UI/qa/QaSelection";
import { selectUnusedCount } from "../../store/selectors/faceCamSelectors";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useWindowSize } from "../../hooks/useWindowSize";
import sizes from "../../styles/sizes";
import { env } from "../../config/env";

const faceCamStyle = { position: "absolute", right: 0, marginTop: "2em" }

const HomePage = ({ history }) => {
    const classes = useStyles();
    const unusedCount = useSelector(selectUnusedCount);
    
    const [cameraHeight, setCameraHeight] = useState(240);
    const [width] = useWindowSize();

    useEffect(() => {
        const { sizesNoPx } = sizes;
        if (width < parseInt(sizesNoPx["sm"])) {
            setCameraHeight(80);
        } else if (width < parseInt(sizesNoPx["md"])) {
            setCameraHeight(120);
        } else if (width < parseInt(sizesNoPx["md2"])) {
            setCameraHeight(140);
        } else if (width < parseInt(sizesNoPx["md2"]) - 100) {
            setCameraHeight(180);
        } else if (width < parseInt(sizesNoPx["lg"])) {
            setCameraHeight(200);
        } else setCameraHeight(240);
    }, [width]);

    return (
        <>
            <div className={classes.subContainer}>
                <div className={classes.subContainer2}>
                    {unusedCount < env.REACT_APP_REGISTRATION_DETECTION_INTERVAL && <QaSelection unusedCount={unusedCount}/>}
                    <FaceCam style={faceCamStyle} height={cameraHeight} />
                </div>
            </div>
        </>
    );
};

HomePage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default HomePage;
