import { createSelector } from "reselect";

function selectLanguage(state) {
    return state.language;
}

export const selectCurrentLanguage = createSelector(
    selectLanguage,
    (language) => language.currentLanguage
);
