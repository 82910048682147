import axios from "axios";
import { ADD_NOTIFICATION, SET_WAITING_CALL_REQUEST_TO_TEAMS, SET_USERS_TEAMS } from "./types";
import { translate } from "../../utils/i18n";
import { speakNoChunk } from "./chatActions";
import {
    deleteScheduledSlackMessage,
    scheduleSlackMessage,
    sendSlackMessage,
} from "./slackActions";
import store from "../../store";

export const getAllTeams = (appId) => async (dispatch) => {
    return await axios
        .get(`/api/v1/teams/${appId}`)
        .then(async (res) => {
            // console.log(res);
            if (res.data.success) {
                return res.data.data;
            } else {
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return [];
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return [];
        });
};

export const getAllChannelsInTeam = (appId, teamId) => async (dispatch) => {
    return await axios
        .get(`/api/v1/teams/${appId}/${teamId}/channels`)
        .then(async (res) => {
            // console.log(res);
            if (res.data.success) {
                return res.data.data;
            } else {
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return [];
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return [];
        });
};

export const getAllUserInWorkSpace = () => async (dispatch) => {
    const appId = store.getState()?.settings?.applicationSettings?._id;
    await axios
        .get(`/api/v1/teams/${appId}/users`)
        .then(async (res) => {
            if (res.data.success) {
                dispatch({
                    type: SET_USERS_TEAMS,
                    members: res.data.members
                })
            } else {
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return [];
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return [];
        });
};

const sendTeamsMessage =
    ({
        appId,
        data,
        speakOnSuccess,
        speakOnWaiting,
        language,
        isGapiReady,
        isWaiting,
    }) =>
    async (dispatch) => {
        dispatch(speakNoChunk(speakOnWaiting, language, isGapiReady));
        dispatch({
            type: SET_WAITING_CALL_REQUEST_TO_TEAMS,
            isWaiting: true,
        });
        if (!isWaiting) {
            return await axios
                .post(`/api/v1/teams/${appId}/message`, data)
                .then(async (res) => {
                    // console.log(res);
                    if (!res.data.success) {
                        dispatch({
                            type: SET_WAITING_CALL_REQUEST_TO_TEAMS,
                            isWaiting: false,
                        });
                        dispatch({
                            type: ADD_NOTIFICATION,
                            payload: {
                                type: "ERROR",
                                message: translate("Something went wrong!"),
                                size: "sm",
                            },
                        });
                    } else {
                        dispatch({
                            type: SET_WAITING_CALL_REQUEST_TO_TEAMS,
                            isWaiting: false,
                        });
                        dispatch({
                            type: ADD_NOTIFICATION,
                            payload: {
                                type: "SUCCESS",
                                message: translate(
                                    "Our staff got the message, please wait a second"
                                ),
                                size: "md",
                            },
                        });
                    }
                    dispatch(
                        speakNoChunk(speakOnSuccess, language, isGapiReady)
                    );
                    return res.data.success;
                })
                .catch((err) => {
                    console.log("Error");
                    console.log(err.response);
                    dispatch({
                        type: SET_WAITING_CALL_REQUEST_TO_TEAMS,
                        isWaiting: false,
                    });
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                    return false;
                });
        }
    };

export const scheduleTeamsMessage =
    ({ appId, data }) =>
    async (dispatch) => {
        return await axios
            .post(`/api/v1/teams/${appId}/schedule-message`, data)
            .then(async (res) => {
                // console.log(res);
                if (!res.data.success) {
                    console.log(res.data);
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                } else {
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "SUCCESS",
                            message: translate(
                                "Teams notifications scheduled successfully"
                            ),
                            size: "md",
                        },
                    });
                }
                return { success: res.data.success, res: res.data.data };
            })
            .catch((err) => {
                console.log("Error");
                console.log(err.response);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return { success: false, res: null };
            });
    };

export const getScheduledTeamsMessages =
    ({ appId }) =>
    async (dispatch) => {
        return await axios
            .get(`/api/v1/teams/${appId}/schedule-message`)
            .then(async (res) => {
                // console.log(res);
                if (!res.data.success) {
                    console.log(res.data);
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                }
                return res.data;
            })
            .catch((err) => {
                console.log("Error");
                console.log(err.response);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return [];
            });
    };

export const deleteScheduledTeamsMessages =
    ({ appId, scheduledMessageIds }) =>
    async (dispatch) => {
        return await axios
            .delete(`/api/v1/teams/${appId}/schedule-message`, {
                data: { scheduledMessageIds },
            })
            .then(async (res) => {
                // console.log(res);
                if (!res.data.success) {
                    console.log(res.data);
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                }
                return res.data.success;
            })
            .catch((err) => {
                console.log("Error");
                console.log(err.response);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return false;
            });
    };

export const sendMessageWithProvider =
    ({
        message,
        staffIdMessageProvider,
        channel,
        speakOnSuccess,
        speakOnWaiting,
        language,
        isGapiReady,
        messageProvider,
        isWaiting,
    }) =>
    async (dispatch) => {
        switch (messageProvider) {
            case "slack": {
                await dispatch(
                    sendSlackMessage({
                        message,
                        staffSlackId: staffIdMessageProvider,
                        channel,
                        speakOnSuccess,
                        language,
                        isGapiReady,
                    })
                );
                return;
            }
            case "teams": {
                const appId =
                    store.getState()?.settings?.applicationSettings._id;
                if (!Array.isArray(channel)) channel = [channel];
                if (typeof staffIdMessageProvider === "string")
                    staffIdMessageProvider = [staffIdMessageProvider];
                await dispatch(
                    sendTeamsMessage({
                        appId,
                        data: {
                            channels: channel,
                            message,
                            mentions: staffIdMessageProvider,
                        },
                        speakOnSuccess,
                        speakOnWaiting,
                        language,
                        isGapiReady,
                        isWaiting,
                    })
                );
                return;
            }
            default:
                return null;
        }
    };

export const scheduleMessageWithProvider =
    ({ message, staffIdMessageProvider, channel, postAt, messageProvider }) =>
    async (dispatch) => {
        switch (messageProvider) {
            case "slack": {
                const { success, res } = await dispatch(
                    scheduleSlackMessage({
                        message,
                        staffSlackId: staffIdMessageProvider,
                        channel,
                        postAt,
                    })
                );
                let ids = [];
                if (success) {
                    ids = res.map((response) => {
                        return {
                            id: response.scheduled_message_id,
                            channelId: response.channel,
                        };
                    });
                }
                return { success, ids };
            }
            case "teams": {
                const appId =
                    store.getState()?.settings?.applicationSettings._id;
                if (!Array.isArray(channel)) channel = [channel];
                if (typeof staffIdMessageProvider === "string")
                    staffIdMessageProvider = [staffIdMessageProvider];
                const { success, res } = await dispatch(
                    scheduleTeamsMessage({
                        appId,
                        data: {
                            channels: channel,
                            message,
                            mentions: staffIdMessageProvider,
                            postAt,
                        },
                    })
                );
                let ids = [];
                if (success) {
                    ids = res.map((message) => {
                        return {
                            id: message._id,
                        };
                    });
                }
                return { success, ids };
            }
            default:
                return { success: false, ids: [] };
        }
    };

export const deleteScheduledMessageWithProvider =
    ({ scheduledMessageIds, messageProvider }) =>
    async (dispatch) => {
        if (
            !Array.isArray(scheduledMessageIds) ||
            scheduledMessageIds.length === 0
        )
            return [];
        switch (messageProvider) {
            case "slack":
                return deleteScheduledSlackMessage(scheduledMessageIds);
            case "teams":
                const appId =
                    store.getState()?.settings?.applicationSettings._id;
                return dispatch(
                    deleteScheduledTeamsMessages({
                        appId,
                        scheduledMessageIds: scheduledMessageIds.map(
                            (message) => message.id
                        ),
                    })
                );
            default:
                return [];
        }
    };
