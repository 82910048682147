import { createSelector } from "reselect";

function selectSettings(state) {
    return state.settings;
}

export const selectUpdateSettings = createSelector(
    selectSettings,
    (settings) => settings.updateSettings
)

export const selectApplicationSettings = createSelector(
    selectSettings,
    (settings) => settings.applicationSettings
)
