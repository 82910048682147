import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/selectors/languageSelectors";
import {
    selectStep,
    selectQuestions,
    selectIsStart,
} from "../../../store/selectors/chatSelectors";
import { selectDetection } from "../../../store/selectors/faceCamSelectors";
import {
    selectUserInfo,
    selectNameRomaji,
    selectUserFaceId,
    selectIsRecorgnizedFalse,
} from "../../../store/selectors/userSelectors";
import { selectApplicationSettings } from "../../../store/selectors/settingSelectors";
import useStyles from "../../../styles/UI/qa/QaSelectionStyles";
// import { useSpeechRecognitionManager } from "../../hooks/useSpeechRecognitionManager";
import { useTranscribeAWS } from "../../../hooks/useTranscribeAWS";
import {
    Grid,
    Button,
    TextField,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormControl,
    Tooltip,
} from "@material-ui/core";
import heartIcon from "../../../images/heart.png";
import micIcon from "../../../images/mic_yellow.png";
import micRedRing from "../../../images/microphone_red_ring.png";
import {
    updateStepQa,
    speakNoChunk,
    submitAnswer,
    updateFaceId,
    getQuestions,
    getNameFromFaceId,
    setIsStart,
    setToggleCheck,
    clearAllQa,
    stopAllActions,
} from "../../../store/actions/chatActions";
import {
    clearUser,
    setIsRecornizedFalse,
} from "../../../store/actions/userActions";
import { addToNotifications } from "../../../store/actions/notificationActions";
import { cvFullWidthToHalfWidth } from "../../../utils/helpers";
import {
    CHECK_IN,
    CHECK_OUT,
    QA_FORM_TITLE,
    QA_BUTTON_START_VOICE_ANSWER,
    QA_BUTTON_STOP_VOICE_ANSWER,
} from "../../../styles/constants";
import { env } from "../../../config/env";
import { translate } from "../../../utils/i18n";
import classnames from "classnames";
import WaveForm from "../WaveForm";
import FormEnterStaffId from "../FormEnterStaffId";
import QaStart from "./QaStart";
import QaWaitting from "./QaWaitting";

function QaSelection({ unusedCount }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const step = useSelector(selectStep);
    const language = useSelector(selectCurrentLanguage);
    const userInfo = useSelector(selectUserInfo);
    const userFaceId = useSelector(selectUserFaceId);
    const isRecorgnizedFalse = useSelector(selectIsRecorgnizedFalse);
    const userFaceIdRef = useRef(userFaceId);
    userFaceIdRef.current = userFaceId;
    const totalRef = useRef(0);
    const sentimentRef = useRef({});
    const keysRef = useRef("");
    const questions = useSelector(selectQuestions);
    const isStart = useSelector(selectIsStart);
    const detection = useSelector(selectDetection);
    const romaji = useSelector(selectNameRomaji);
    const applicationSettings = useSelector(selectApplicationSettings);
    const isPerformingAction = useSelector(
        (state) => state.chat.isPerformingAction
    );
    const [
        isRecording,
        base64,
        transcription,
        transcriptionArray,
        toggleStart,
        toggleStop,
        updateTranscript,
        resetTranscript,
        errorMessage,
    ] = useTranscribeAWS(language);
    const [listAnswer, setListAnswer] = useState([]);
    const listAnswerRef = useRef();
    listAnswerRef.current = listAnswer;
    const [isWaiting, setIsWaiting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowFirst, setIsShowFirst] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [selectIndex, setSelectIndex] = useState(null);
    const [pickCheckbox, setPickCheckbox] = useState([]);
    const [qtype, setQType] = useState("");
    const END = useMemo(() => {
        if (qtype === 1) return CHECK_IN; // checkin
        return CHECK_OUT; // checkout
    }, [qtype]);

    const text = useMemo(() => {
        if (transcriptionArray.length > 0) return transcriptionArray.join(" ");
        if (transcription) return transcription;
        return "";
    }, [transcription, transcriptionArray]);

    useEffect(() => {
        if (unusedCount >= env.REACT_APP_REGISTRATION_DETECTION_INTERVAL - 1) {
            reset();
        }
    }, [unusedCount]);

    useEffect(() => {
        if (text.length > 0 && questions[step]?.options === undefined && !isRecording) {
            let timer = setTimeout(() => {
                setShowTooltip(true)
                dispatch(speakNoChunk(translate("Click here to confirm"), language, false, true));
            }, env.TIMEOUT_SHOW_TOOLTIP)
            return () => {
                console.log("clear timeout")
                clearTimeout(timer)
            }
        }
    }, [text, isRecording])

    useEffect(() => {
        if (
            unusedCount < env.REACT_APP_REGISTRATION_DETECTION_INTERVAL &&
            isStart &&
            questions.length > 0
        ) {
            if (step === 0) {
                const { staff } = applicationSettings.welcomeText;
                const welcomeVoiceStaff =
                    staff?.[language]?.replace(
                        "<name>",
                        userInfo.romaji || romaji || userInfo.displayName
                    ) ??
                    `${translate("Hello")} ${
                        userInfo.romaji || romaji || userInfo.displayName
                    }`;
                dispatch(
                    speakNoChunk(
                        welcomeVoiceStaff + "。" + questions[step].content,
                        language,
                        false,
                        true
                    )
                );
            } else {
                dispatch(
                    speakNoChunk(questions[step].content, language, false, true)
                );
            }
        }
    }, [step, isStart]);

    useEffect(() => {
        if (qtype && (userInfo.faceId || isRecorgnizedFalse)) {
            getData();
        }
    }, [userInfo, isRecorgnizedFalse, qtype]);

    useEffect(() => {
        if (
            questions.length > 0 &&
            listAnswerRef.current.length === questions.length
        ) {
            handleSubmit();
        }
    }, [listAnswer]);

    useEffect(() => {
        if (errorMessage) {
            isRecording && toggleStop();
            dispatch(
                addToNotifications({
                    message: errorMessage || "Error",
                    type: "ERROR",
                    size: "sm",
                    duration: 2,
                })
            );
        }
    }, [errorMessage]);

    useEffect(() => {
        if (isStart && userInfo.faceId && detection && !isWaiting) {
            keysRef.current = Object.keys(detection.expressions).find(
                (val) =>
                    detection.expressions[val] ===
                    Math.max(...Object.values(detection.expressions))
            );
            totalRef.current += 1;
            sentimentRef.current[keysRef.current] =
                sentimentRef.current[keysRef.current] === undefined
                    ? 1
                    : sentimentRef.current[keysRef.current] + 1;
        }
    }, [isStart, detection, isWaiting]);

    const nextStep = function (obj, index) {
        if (index !== null) {
            setListAnswer((val) => [...val, { ...obj, pick: index }]);
            setSelectIndex(null);
        } else {
            setListAnswer((val) => [
                ...val,
                { ...obj, answer: text, audio: base64 },
            ]);
        }
        isShowFirst && setIsShowFirst(false);
        text.length > 0 && resetTranscript();
        pickCheckbox.length > 0 && setPickCheckbox([]);
        if (isRecording) {
            toggleStop();
        }
        if (step !== questions.length - 1) {
            dispatch(updateStepQa("increase"));
        } else {
            setIsWaiting(true);
        }
    };

    const addAnswer = (index) => {
        const filterPickCheckbox =
            pickCheckbox.indexOf(index) > -1
                ? pickCheckbox.filter((each) => each !== index)
                : [...pickCheckbox, index];
        setPickCheckbox(filterPickCheckbox);
    };

    const handleSubmit = async () => {
        Object.keys(sentimentRef.current).forEach((key) => {
            if (parseFloat(sentimentRef.current[key] / totalRef.current) > 0) {
                sentimentRef.current = {
                    ...sentimentRef.current,
                    [key]: +parseFloat(
                        sentimentRef.current[key] / totalRef.current
                    ).toFixed(2),
                };
            }
        });
        const form = {
            face_id: userInfo.faceId,
            created_at: Date.now(),
            questions: listAnswerRef.current,
            sentiment: sentimentRef.current,
        };
        dispatch(submitAnswer(form));
        userInfo.code &&
            (await dispatch(
                updateFaceId({
                    user_id: cvFullWidthToHalfWidth(userInfo.code),
                    face_id: userInfo.faceId,
                })
            ));
        await dispatch(speakNoChunk(END, language, false, true));
        reset();
    };

    // const prevStep = async function () {
    //     setSelectIndex(null);
    //     if (step === 0 && userInfo.faceId) {
    //         reset();
    //         return;
    //     }
    //     isShowFirst && setIsShowFirst(false);
    //     if (isRecording) {
    //         toggleStop();
    //     }
    //     if (text.length > 0) {
    //         resetTranscript();
    //     }
    //     pickCheckbox.length > 0 && setPickCheckbox([]);
    //     if (step === 0) {
    //         dispatch(setIsStart(false));
    //     } else {
    //         let newAnswer = listAnswer;
    //         newAnswer.pop();
    //         setListAnswer(newAnswer);
    //         dispatch(updateStepQa("decrease"));
    //     }
    // };

    const clearAnswers = () => {
        text.length && resetTranscript();
        showTooltip && setShowTooltip(false);
        isRecording && toggleStop();
        pickCheckbox.length > 0 && setPickCheckbox([]);
        setSelectIndex(null);
    }

    const reset = () => {
        setQType("");
        dispatch(setToggleCheck(false));
        dispatch(clearAllQa());
        userInfo.faceId && dispatch(clearUser());
        isRecorgnizedFalse && dispatch(setIsRecornizedFalse(false));
        // listAnswer.length > 0 && toggleStop();
        text.length && resetTranscript();
        isRecording && toggleStop();
        setListAnswer([]);
        setShowForm(false);
        setIsWaiting(false);
        setSelectIndex(null);
        pickCheckbox.length > 0 && setPickCheckbox([]);
        isShowFirst && setIsShowFirst(false);
        isLoading && setIsLoading(false);
        showTooltip && setShowTooltip(false);
        totalRef.current = 0;
        sentimentRef.current = {};
    };

    const handleToogleRecordButton = (useRegex = false) => {
        if (!isRecording) {
            toggleStart();
            isPerformingAction && dispatch(stopAllActions());
            isShowFirst && setIsShowFirst(false);
        } else {
            toggleStop();
            setIsShowFirst(true);
            if (useRegex) {
                if (text.length === 0) return;
                const index = questions[step].regex.findIndex((each) => {
                    const reg = new RegExp(each, "i");
                    return reg.test(text);
                });
                if (index === -1) return;
                setSelectIndex(index);
            }
        }
    };

    const getData = async () => {
        if (userInfo.faceId && questions.length === 0) {
            userInfo.romaji === null &&
                (await dispatch(
                    getNameFromFaceId({ user_id: userInfo.faceId })
                ));
            const response = await dispatch(
                getQuestions({
                    face_id: userInfo.faceId,
                    qtime: qtype,
                })
            );
            if (response) {
                dispatch(setToggleCheck(false));
                dispatch(setIsStart(true));
            }
            setIsLoading(false);
        } else {
            setIsLoading(false);
            if (isRecorgnizedFalse) {
                setShowForm(true);
                dispatch(setToggleCheck(false));
                dispatch(setIsStart(true));

                await dispatch(
                    speakNoChunk(QA_FORM_TITLE, language, false, true)
                );
            }
        }
    };

    const handleCheckInCheckOut = async (type) => {
        // type = 1 => check in; type = 2 => check out
        setQType(type);
        setIsLoading(true);
        dispatch(setToggleCheck(true));
    };

    const renderMicAudio = () => {
        return (
            <div className={classes.subcontainer_sst}>
                <div onClick={() => handleToogleRecordButton(true)}>
                    {!isRecording ? (
                        <img
                            src={micIcon}
                            alt="mic-icon"
                            className={classes.icon_mic_v2}
                        />
                    ) : (
                        <img
                            src={micRedRing}
                            alt="mic-icon"
                            className={classes.icon_mic_v2}
                        />
                    )}
                </div>
                {isRecording && <WaveForm />}
            </div>
        );
    };

    const renderAudioVisualize = () => {
        return isRecording && <WaveForm />;
    };

    const renderTranscription = () => {
        return (
            <div className={classes.transcript_contain}>
                {isRecording ? (
                    <div className={classes.box_transcript_title}>{text}</div>
                ) : (
                    questions[step]?.options === undefined &&
                    isShowFirst && (
                        <Box className={classes.box_transcript}>
                            <div className={classes.box_transcript_input}>
                                <TextField
                                    multiline
                                    maxRows={4}
                                    minRows={4}
                                    fullWidth
                                    value={text}
                                    onChange={(e) =>
                                        updateTranscript(e.target.value)
                                    }
                                    variant="standard"
                                    className={classes.box_transcript_text}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </div>
                        </Box>
                    )
                )}
            </div>
        );
    };

    const renderQuestionOption = () => {
        return questions[step]?.options === undefined ? (
            <div
                className={classes.startListen}
                onClick={() => handleToogleRecordButton(false)}
            >
                {!isRecording && (
                    <img
                        src={micIcon}
                        alt="mic-icon"
                        className={classes.icon_mic}
                    />
                )}
                <span className={classes.start_text}>
                    {isRecording
                        ? QA_BUTTON_STOP_VOICE_ANSWER
                        : QA_BUTTON_START_VOICE_ANSWER}
                </span>
            </div>
        ) : questions[step].category === "check_box" ? (
            <div className={classes.box}>
                {questions[step].options.map((answer, index) => (
                    <FormControl component="fieldset"
                        className={
                            questions[step].options.length < 7
                                ? classes.gridCheckBox
                                : classes.gridCheckBoxV2
                        }
                    >
                        <FormGroup>
                            <FormControlLabel
                                onChange={() => addAnswer(index)}
                                checked={pickCheckbox.indexOf(index) > -1}
                                control={<Checkbox  classes={{
                                    checked: classes.check_box_checked
                                }}/>}
                                className={classes.form_control_label}
                                style={{height: questions[step].options.length > 6 && 50}}
                                label={<span className={classes.check_box_label}>{answer}</span>}
                            />
                        </FormGroup>
                    </FormControl>
                ))}
            </div>
        ) : (
            questions[step].options.map((answer, index) => (
                <Grid
                    item
                    className={
                        questions[step].options.length % 2 === 0
                            ? classes.gridItem
                            : classes.gridItemV2
                    }
                    key={index}
                >
                    <div onClick={() => nextStep(questions[step], index)}>
                        <Box boxShadow={selectIndex === index ? 12 : 0}>
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.answer}
                                style={{
                                    fontSize:
                                        questions[step].options.length > 4 &&
                                        "1em",
                                    backgroundColor:
                                        selectIndex === index && "#13CE8F",
                                }}
                            >
                                {answer}
                            </Button>
                        </Box>
                    </div>
                </Grid>
            ))
        );
    };

    const renderQuestionItem = () => {
        return (
            <div
                className={classnames(classes.container, classes.container_bg)}
            >
                <div className={classes.subcontainer}>
                    <img
                        src={heartIcon}
                        alt="heart-icon"
                        className={classes.icon}
                    />
                    {questions[step]?.options !== undefined && renderMicAudio()}
                    <div className={classes.subcontainer2}>
                        <h1 className={classes.container_question}>
                            {questions[step].content}
                        </h1>
                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            className={classes.grid}
                            id={"answerContent"}
                        >
                            {renderQuestionOption()}
                        </Grid>
                        {questions[step]?.options === undefined &&
                            renderAudioVisualize()}
                        {questions[step]?.options === undefined &&
                            renderTranscription()}
                    </div>
                </div>
            </div>
        );
    };

    const renderButtonBack = () => {
        return (
            <div
                className={classnames(
                    classes.btn_home,
                    classes.transcript_contain
                )}
            >
                {/* <Button
                    size="small"
                    variant="outlined"
                    className={classes.btn_back}
                    onClick={prevStep}
                >
                    {translate("Back")}
                </Button> */}

                <Button
                    size="small"
                    variant="outlined"
                    className={classes.btn_back}
                    onClick={clearAnswers}
                >
                    {translate("Clear")}
                </Button>

                {selectIndex !== null && text.length > 0 && !isRecording && (
                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.btn_back}
                        onClick={() => nextStep(questions[step], selectIndex)}
                    >
                        {translate("Ok")}
                    </Button>
                )}
                {questions[step]?.options === undefined &&
                    text.length > 0 &&
                    !isRecording && (
                        <Tooltip title={translate("Click here to confirm")} arrow open={showTooltip} placement="right">
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.btn_back}
                                onClick={() => nextStep(questions[step], null)}
                            >
                                {translate("Ok")}
                            </Button>
                        </Tooltip>
                    )}
                {questions[step]?.category === "check_box" && (
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.btn_back}
                            onClick={() =>
                                nextStep(questions[step], pickCheckbox)
                            }
                        >
                            {translate("Ok")}
                        </Button>
                    )}
            </div>
        );
    };

    const renderButtonHome = () => {
        return (
            <div
                className={classnames(
                    classes.btn_home,
                    classes.transcript_contain
                )}
                style={{ position: "relative", zIndex: 10 }}
            >
                <Button
                    size="small"
                    variant="outlined"
                    className={classnames(
                        classes.start,
                        classes.btn_home_color
                    )}
                    onClick={reset}
                >
                    {translate("Home")}
                </Button>
            </div>
        );
    };

    return (
        <>
            {!isStart ? (
                <QaStart
                    classes={classes}
                    isLoading={isLoading}
                    qtype={qtype}
                    onCheckInCheckOut={handleCheckInCheckOut}
                />
            ) : (userInfo.faceId || userFaceIdRef.current || userInfo._id) &&
              !showForm ? (
                <div className={classes.root}>
                    <div>
                        {isWaiting ? (
                            <QaWaitting classes={classes} content={END} />
                        ) : (
                            questions.length > 0 && renderQuestionItem()
                        )}
                        {!isWaiting && renderButtonBack()}
                        {renderButtonHome()}
                    </div>
                </div>
            ) : (
                isRecorgnizedFalse && (
                    <FormEnterStaffId title={QA_FORM_TITLE} reset={reset} />
                )
            )}
        </>
    );
}

export default QaSelection;
