import Keyboard from "react-simple-keyboard";
import 'react-simple-keyboard/build/css/index.css';
import { layOutKeyboard } from "../../styles/constants";
import userStyles from "../../styles/UI/ChatAndMicStyles";

const KeyboardWindow = ({onChange, onKeyPress, layoutName, language, onChangeAll, inputName}) => {
    const classes = userStyles();

    return (
        <>
            {process.env.REACT_APP_VIRTUAL_KEYBOARD === "true" && 
                <div className={classes.keyboard_position} tabIndex="0">
                    <Keyboard
                        theme={"hg-theme-default " + classes.keyboard_custom}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        layoutName={layoutName}
                        onChangeAll={onChangeAll}
                        inputName={inputName}
                        display= {
                            {
                                "{bksp}": "Backspace",
                                "{tab}": "Tab",
                                "{enter}": "Enter",
                                "{shift}": "Shift",
                                "{lock}": "X",
                                "{space}": "Space",
                            }
                        }
                        layout={{
                            default: layOutKeyboard.default?.["en-US"],
                            shift: layOutKeyboard.shift?.["en-US"]
                        }}
                        buttonTheme={[
                            {
                              class: classes.hg_red,
                              buttons: "{lock}"
                            },
                          ]}
                    />
                </div>
            }
        </>
    )
}
export default KeyboardWindow