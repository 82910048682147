import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { translate } from "../../../../../utils/i18n";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import { decodeDescription } from "../../../../../store/actions/googleCalendarActions";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { deleteScheduledMessageWithProvider } from "../../../../../store/actions/teamsActions";
import { useApplicationSettings } from "../../../../../hooks/settings/useApplicationSettings";
import { deleteCalendarEventWithProvider } from "../../../../../store/actions/outlookCalendarActions";
import { findIana } from "windows-iana";

const useStyles = makeStyles((theme) => ({
    cardItem: {
        margin: "0.25em 0",
    },
    cardRoot: {
        background: "rgba(256, 256, 256, 0.4)",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    space: {
        marginTop: "2em",
    },
    actionArea: {
        "&:hover": {
            color: "rgba(0, 0, 0, 0.5)",
        },
    },
}));

const MeetingCard = ({ event, onEventAddOrUpdate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.currentLanguage);
    const [description, setDescription] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState({});
    const [expanded, setExpanded] = useState(false);
    const [meetingModalOpen, setMeetingModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
        useState(false);
    const { messageProvider = "slack", calendarProvider = "google" } =
        useApplicationSettings(["messageProvider", "calendarProvider"]);

    const formatDate = useCallback(
        (date, timeZone) => {
            moment.locale(language);
            timeZone =
                findIana(timeZone).length > 0 ? findIana(timeZone) : timeZone;
            return moment
                .utc(date)
                .tz(timeZone)
                .format("HH:mm ddd DD MMM YYYY");
        },
        [language]
    );

    const handleMeetingModalClose = useCallback(
        () => setMeetingModalOpen(false),
        []
    );

    const handleDelete = async () => {
        setIsLoading(true);
        const success = await dispatch(
            deleteCalendarEventWithProvider(calendarProvider, event.id)
        );
        if (success) {
            dispatch(
                addToNotifications({
                    type: "SUCCESS",
                    message: translate("Event deleted successfully!"),
                    size: "md",
                })
            );
            setIsLoading(false);
            await dispatch(
                deleteScheduledMessageWithProvider({
                    scheduledMessageIds: additionalInfo.scheduledMessageIds,
                    messageProvider,
                })
            );
            onEventAddOrUpdate();
        } else setIsLoading(false);
    };

    const renderAdditionalAttendees = useCallback(() => {
        const { attendees = [] } = additionalInfo;
        const additionalAttendees = attendees.filter(
            (attendee) => attendee.email === undefined
        );
        return additionalAttendees.map((attendee, index) => (
            <Grid container justifyContent="space-between" key={index}>
                <Typography variant="body2" color="textSecondary" component="p">
                    {attendee.displayName}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <Tooltip
                        title={translate("needsAction")}
                        arrow={true}
                        placement={"bottom"}
                    >
                        <CancelIcon />
                    </Tooltip>
                </Typography>
            </Grid>
        ));
    }, [additionalInfo]);

    useEffect(() => {
        const { description, additionalInfo } = decodeDescription(
            event.description
        );
        setDescription(description);
        setAdditionalInfo(additionalInfo);
    }, [event]);

    return (
        <Card className={classes.cardRoot}>
            <CardActionArea
                className={classes.actionArea}
                href={event.htmlLink}
                target={"_blank"}
                rel={"noopener noreferrer"}
            >
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {event.summary}
                    </Typography>
                    {event.location && (
                        <Grid
                            container
                            justifyContent="space-between"
                            className={classes.cardItem}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {translate("Location")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {event.location}
                            </Typography>
                        </Grid>
                    )}
                    <Grid
                        container
                        justifyContent="space-between"
                        className={classes.cardItem}
                    >
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {translate("Starts")}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {formatDate(
                                event.start.dateTime,
                                event.start.timeZone
                            )}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="space-between"
                        className={classes.cardItem}
                    >
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {translate("Ends")}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            {formatDate(event.end.dateTime, event.end.timeZone)}
                        </Typography>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing>
                <Tooltip
                    title={translate("Edit")}
                    arrow={true}
                    placement={"bottom"}
                >
                    <IconButton
                        disabled={isLoading}
                        aria-describedby={"Edit"}
                        onClick={(e) => setMeetingModalOpen(true)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={translate("Delete")}
                    arrow={true}
                    placement={"bottom"}
                >
                    <IconButton
                        disabled={isLoading}
                        aria-describedby={"Delete"}
                        onClick={() => setIsDeleteConfirmationDialogOpen(true)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={translate(expanded ? "Collapse" : "Expand")}
                    arrow={true}
                    placement={"bottom"}
                >
                    <IconButton
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={() => setExpanded((val) => !val)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {description && (
                        <>
                            <Typography paragraph>
                                {translate("Description")}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {description}
                            </Typography>
                        </>
                    )}

                    <Typography paragraph className={classes.space}>
                        {translate("Attendees")}
                    </Typography>
                    {event.attendees &&
                        event.attendees.map((attendee, index) => (
                            <Grid container justifyContent="space-between" key={index}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {attendee.displayName} | {attendee.email}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    <Tooltip
                                        title={translate(
                                            attendee.responseStatus
                                        )}
                                        arrow={true}
                                        placement={"bottom"}
                                    >
                                        {attendee.responseStatus ===
                                        "accepted" ? (
                                            <CheckCircleIcon />
                                        ) : (
                                            <CancelIcon />
                                        )}
                                    </Tooltip>
                                </Typography>
                            </Grid>
                        ))}
                    {renderAdditionalAttendees()}

                    <Grid
                        container
                        justifyContent="space-between"
                        className={classes.space}
                    >
                        <Typography paragraph>
                            {translate("Last Updated")}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            <Moment
                                fromNow
                                locale={language.split("-").shift()}
                                style={{ textTransform: "capitalize" }}
                            >
                                {event.updated}
                            </Moment>
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Typography paragraph>
                            {translate("Created")}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        >
                            <Moment
                                fromNow
                                locale={language.split("-").shift()}
                                style={{ textTransform: "capitalize" }}
                            >
                                {event.created}
                            </Moment>
                        </Typography>
                    </Grid>
                </CardContent>
            </Collapse>
            <ScheduleMeetingModal
                open={meetingModalOpen}
                handleClose={handleMeetingModalClose}
                onEventAddOrUpdate={onEventAddOrUpdate}
                update
                event={event}
            />
            <ConfirmationModal
                open={isDeleteConfirmationDialogOpen}
                handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
                handleSuccess={handleDelete}
                title={translate("Delete Event")}
                message={translate(
                    "Are you sure you want to delete this event?"
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
        </Card>
    );
};

MeetingCard.propTypes = {
    onEventAddOrUpdate: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
};

export default MeetingCard;
