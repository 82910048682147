import React, {
    useEffect,
    useState,
    Suspense,
    useCallback,
    useMemo,
    forwardRef,
} from "react";
import userStyles from "../../../styles/UI/layout/FooterStyles";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import aimesoftLogo from "../../../images/aimesoft_logo.png";
// import stop_icon from '../../../images/Stop_button.png'
import { stopAllActions } from "../../../store/actions/chatActions";
import { Link } from "react-router-dom";
import Button from "../../Customized Libraries/CustomButtons/Button";
import back from "../../../images/back.png";
import settingsIcon from "../../../images/settings.png";
import stop from "../../../images/stop.png";
import dashboardIcon from "../../../images/admin.png";
import Settings from "../settings/Settings";
import { translate } from "../../../utils/i18n";
import PasswordModal from "../modals/PasswordModal";
import { SuspenseImg as SuspenseImgClone } from "../SuspenseImg";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

const SuspenseImg = forwardRef(function SuspenseImg(props, ref) {
    return (
        <Suspense fallback={<Spin />}>
            <SuspenseImgClone childRef={ref} {...props} />
        </Suspense>
    );
});
const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);

const Footer = ({ history, location }) => {
    // return null
    const classes = userStyles();
    const dispatch = useDispatch();
    const isPerformingAction = useSelector(
        (state) => state.chat.isPerformingAction
    );
    const language = useSelector((state) => state.language.currentLanguage);

    const state = location.state;
    const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
    const [stopIconOpen, setStopIconOpen] = useState(true);

    const [isAdminValidationModalOpen, setIsAdminValidationModalOpen] =
        useState(false);
    const [isRegistrationModalOpen, setIsRegistrationModalOpen] =
        useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    // const isOnScreenKeyboard = useSelector(state => state.chat.isOnScreenKeyboard)

    useEffect(() => {
        if (
            state !== undefined &&
            state.showRegistrationModal &&
            location.pathname === "/"
        ) {
            setIsPasswordDisabled(true);
            setIsRegistrationModalOpen(true);
            history.replace();
        } else setIsPasswordDisabled(false);
    }, [state, history.location.pathname]);

    useEffect(() => {
        const timer = setTimeout(() => setStopIconOpen(false), 1000);
        return () => clearTimeout(timer);
    }, [stopIconOpen]);

    const handleAdminRedirect = useCallback(
        () => setIsAdminValidationModalOpen(true),
        []
    );

    const handleAdminValidationModalClose = useCallback(
        () => setIsAdminValidationModalOpen(false),
        []
    );

    const handleSettingClose = useCallback(
        () => setIsSettingsModalOpen(false),
        []
    );
    const handleSettingModalOpen = useCallback(
        () => setIsSettingsModalOpen(true),
        []
    );
    const handleInterruptClick = useCallback(
        () => dispatch(stopAllActions()),
        [dispatch]
    );
    const handleInterruptMouseOver = useCallback(
        () => setStopIconOpen((val) => !val),
        []
    );
    const settings = useMemo(
        () => (
            <Settings
                open={isSettingsModalOpen}
                handleClose={handleSettingClose}
            />
        ),
        [isSettingsModalOpen, handleSettingClose]
    );

    const settingsSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Settings")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={settingsIcon}
                    alt="settings"
                    className={classes.iconV2}
                />
            </Tooltip>
        ),
        [classes.iconV2, language]
    );

    const adminSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Admin Page")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={dashboardIcon}
                    alt={"Admin Page"}
                    className={classes.iconV2}
                />
            </Tooltip>
        ),
        [classes.iconV2, language]
    );

    const backSuspenseImg = useMemo(
        () => (
            <Tooltip title={translate("Back")} arrow={true} placement={"top"}>
                <SuspenseImg src={back} alt="Back" className={classes.iconV2} />
            </Tooltip>
        ),
        [classes.iconV2, language]
    );

    const stopSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Click Here To Interrupt")}
                arrow={true}
                placement={"top"}
                open={stopIconOpen}
            >
                <SuspenseImg
                    src={stop}
                    alt={"stop Icon"}
                    className={classes.stopIcon}
                />
            </Tooltip>
        ),
        [classes.stopIcon, language]
    );

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {location.pathname !== "/" && (
                    <Button
                        color={"primaryButtonTheme"}
                        textColor={"buttonTextTheme"}
                        size={"lg"}
                        round
                        className={classes.buttonV2}
                        onClick={history.goBack}
                    >
                        {backSuspenseImg}
                    </Button>
                )}
                <Button
                    color={"primaryButtonTheme"}
                    textColor={"buttonTextTheme"}
                    size={"lg"}
                    round
                    className={classes.buttonV2}
                    onClick={handleSettingModalOpen}
                >
                    {settingsSuspenseImg}
                </Button>

                <Button
                    color={"primaryButtonTheme"}
                    textColor={"buttonTextTheme"}
                    size={"lg"}
                    round
                    className={classes.buttonV2}
                    onClick={handleAdminRedirect}
                >
                    {adminSuspenseImg}
                </Button>

                {isPerformingAction && (
                    <Button
                        color={"primaryButtonTheme"}
                        textColor={"buttonTextTheme"}
                        size={"lg"}
                        round
                        className={classes.buttonV2}
                        onClick={handleInterruptClick}
                        onMouseOver={handleInterruptMouseOver}
                    >
                        {stopSuspenseImg}
                    </Button>
                )}
            </div>
            <div className={classes.footerTitle}>
                <div className={classes.footer_info}>
                    {translate("AimeReception - Virtual Receptionist Software")}
                </div>
                <div className={classes.footer_info}>
                    {translate("Powered by Aimesoft JSC")}
                </div>
            </div>
            <Link to={"/"} className={classes.removeDefaultLink}>
                <Suspense fallback={<Spin />}>
                    <SuspenseImgClone
                        src={aimesoftLogo}
                        alt={"aimesoft logo"}
                        className={classes.logo}
                    />
                </Suspense>
            </Link>
            <PasswordModal
                open={isSettingsModalOpen}
                handleClose={handleSettingClose}
                history={history}
            >
                {settings}
            </PasswordModal>
            <PasswordModal
                open={isAdminValidationModalOpen}
                handleClose={handleAdminValidationModalClose}
                history={history}
                redirectTo={"/admin"}
            />
        </div>
    );
};

Footer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default Footer;
