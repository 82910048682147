import { memo } from 'react';
import classnames from "classnames";
import joyIcon from "../../../images/joy.png";

const QaWaitting = ({ classes, content }) => {
    return (
        <div
            className={classnames(
                classes.container,
                classes.container_bg
            )}
        >
            <div className={classes.subcontainer}>
                <img src={joyIcon} alt="joy-icon" className={classes.icon} />
                <div className={classes.subcontainer3}>
                    <h1 className={classes.container_question}>{content}</h1>
                </div>
            </div>
        </div>
    );
};

export default memo(QaWaitting);
