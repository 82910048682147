import React, { useCallback, useEffect, useMemo, useState } from "react";
import userStyles from "../../../styles/UI/layout/BaseLayoutStyles";
import Receptionist from "../Receptionist";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import FaceCam from "../FaceCam";
import ChatAndMic from "../ChatAndMic";
import { useAppearanceSettings } from "../../../hooks/settings/useAppearanceSettings";
import PropTypes from "prop-types";
import sizes from "../../../styles/sizes";
import { useWindowSize } from "../../../hooks/useWindowSize";

const BaseLayout = ({ history, location, Component }) => {
    const {
        theme_primaryColor: primaryColor = null,
        theme_secondaryColor: secondaryColor = null,
    } = useAppearanceSettings(["theme.primaryColor", "theme.secondaryColor"]);
    const classes = userStyles({ primaryColor, secondaryColor });
    const language = useSelector((state) => state.language);
    const faceCamStyle = useMemo(
        () => ({ height: "40%" }),
        []
    );
    const [cameraHeight, setCameraHeight] = useState(240);
    const [width, _] = useWindowSize();
    
    const showComponentsByLanguages = useCallback(() => {
        const lang = language.currentLanguage;
        if (lang === "zh-CN") {
            return false;
        } else if (lang === "zhTW-CN") {
            return false;
        } else if (lang === "th-TH") {
            return false;
        } else {
            return true;
        }
    }, [language.currentLanguage])

    useEffect(() => {
        const { sizesNoPx } = sizes;
        if (width < parseInt(sizesNoPx["sm"])) {
            setCameraHeight(80);
        } else if (width < parseInt(sizesNoPx["md"])) {
            setCameraHeight(120);
        } else if (width < parseInt(sizesNoPx["md2"])) {
            setCameraHeight(140);
        } else if (width < parseInt(sizesNoPx["md2"]) - 100) {
            setCameraHeight(180);
        } else if (width < parseInt(sizesNoPx["lg"])) {
            setCameraHeight(200);
        } else setCameraHeight(240);
    }, [width]);

    return (
        <>
            <div className={classes.container}>
                <Receptionist history={history} />
                <div className={classes.subContainer2}>
                    <div className={classes.subContainer3}>
                        <Component
                            history={history}
                            language={language}
                            location={location}
                        />
                    </div>
                    <div className={classes.subContainer4}>
                        <FaceCam style={faceCamStyle} height={cameraHeight} />
                        {showComponentsByLanguages() && <ChatAndMic />}
                    </div>
                </div>
                <Footer history={history} location={location} />
            </div>
        </>
    );
};

BaseLayout.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    Component: PropTypes.func.isRequired,
};

export default BaseLayout;
