import { createSelector } from "reselect";

function selectChat(state) {
    return state.chat;
}

export const selectIsGapiReady = createSelector(
    selectChat,
    (chat) => chat.isGapiReady
);

export const selectStep = createSelector(
    selectChat,
    (chat) => chat.step
)

export const selectIsStart = createSelector(
    selectChat,
    (chat) => chat.isStart
)

export const selectIsChecked = createSelector(
    selectChat,
    (chat) => chat.isChecked
)

export const selectQuestions = createSelector(
    selectChat,
    (chat) => chat.questions
)

