import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userStyles from "../../styles/UI/ChatAndMicStyles";
import Button from "../Customized Libraries/CustomButtons/Button";
import classnames from "classnames";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// import SpeechRecognition, {useSpeechRecognition} from "react-speech-recognition";
import {
    decodeHTMLEntities,
    scrollToBottom,
    sendMessage,
    setIsPerformingAction,
    speechSynthesisChunker,
    stopAllAudio,
} from "../../store/actions/chatActions";
import { setReceptionist } from "../../store/actions/receptionistActions";
import microphone_blue from "../../images/mic.png";
import microphone_red from "../../images/mic_listening.png";
import keyboard from "../../images/keyboard.png";
import TextField from "@material-ui/core/TextField";
import ReactLoading from "react-loading";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useSpeechRecognitionManager } from "../../hooks/useSpeechRecognitionManager";
import MeetStaffModal from "./modals/MeetStaffModal";
import { translate } from "../../utils/i18n";
import FindMeetingModal from "./modals/FindMeetingModal";
import { useHistory } from "react-router-dom";
import KeyboardWindow from "./KeyboardWindow";

// compress and convert to gif
// https://www.ps2pdf.com/compress-mov
// https://ezgif.com/video-to-gif/
// https://bgeraser.com/index.html#fileupload //to jpg
// https://gifmaker.me/ // to git

if (window.speechSynthesis) {
    window.speechSynthesis.onvoiceschanged = async (e) => {
        window.speechSynthesis.getVoices();
    };
    window.speechSynthesis.getVoices();
}

const Message = (props) => {
    const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);
    const { classes, message, disabled, isReceiver, color } = props;
    return (
        <div
            className={
                isReceiver
                    ? classes.messageContainerLeft
                    : classes.messageContainerRight
            }
        >
            {isReceiver ? (
                <Button
                    color={color}
                    round
                    className={classes.chatMessageLeft}
                    disabled={disabled}
                >
                    {propIsValid(props.component) && props.component}
                    {decodeHTMLEntities(message)}
                </Button>
            ) : (
                <Button
                    color={color}
                    round
                    className={classes.chatMessageRight}
                >
                    {propIsValid(props.component) && props.component}
                    {decodeHTMLEntities(message)}
                </Button>
            )}
        </div>
    );
};

const MessageList = memo(
    ({ messages, classes, primaryButtonTheme, secondaryButtonTheme }) => {
        return messages
            .slice(Math.max(messages.length - 2, 0))
            .map((message, index) => (
                <Message
                    classes={classes}
                    message={message.message}
                    key={index}
                    isReceiver={message.isReceiver}
                    color={
                        message.isReceiver
                            ? primaryButtonTheme
                            : secondaryButtonTheme
                    }
                />
            ));
    }
);

const ChatAndMic = (props) => {
    const classes = userStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        messages,
        session: sessionId,
        isThinking,
        isGapiReady,
    } = useSelector((state) => state.chat);
    const { isUserRecognized, userInfo } = useSelector((state) => state.user);
    const language = useSelector((state) => state.language.currentLanguage);
    const [isForcus, setIsForcus] = useState(false);
    const [iskeyboard, setIsKeyboard] = useState(false);
    const [messageInput, setMessageInput] = useState("");
    const [isMeetStaffModalOpen, setIsMeetStaffModalOpen] = useState(false);
    const [isFindMeetingModalOpen, setIsFindMeetingModalOpen] = useState(false);
    const [showStaffList, setShowStaffList] = useState(false);
    const [defaultMentions, setDefaultMentions] = useState([]);
    const [modalSpeakOnUnMount, setModalSpeakOnUnMount] = useState("");
    const [modalCustomSlackMessage, setModalCustomSlackMessage] = useState("");
    const [isShowKeyboard, setIsShowKeyboard] = useState(false);
    const [layoutName, setLayoutName] = useState("default");
    
    const handleMeetStaffModalClose = useCallback(
        () => setIsMeetStaffModalOpen(false),
        []
    );
    const handleFindMeetingModalClose = useCallback(
        () => setIsFindMeetingModalOpen(false),
        []
    );

    const getVoiceResponse = useCallback(
        (response) => {
            if (isUserRecognized) {
                return typeof response === "string"
                    ? response
                    : response?.known?.[language]?.replace(
                          "<name>",
                          userInfo.displayName || ""
                      );
            } else {
                return typeof response === "string"
                    ? response
                    : response?.unknown?.[language];
            }
        },
        [language, userInfo, isUserRecognized]
    );

    const showMeetStaffModal = useCallback(
        (
            showStaffList,
            defaultMentions,
            customSlackMessage,
            speakOnMount,
            speakOnUnmount
        ) => {
            setShowStaffList(showStaffList);
            setDefaultMentions(defaultMentions);
            setIsMeetStaffModalOpen(true);
            dispatch(
                speechSynthesisChunker(
                    getVoiceResponse(speakOnMount),
                    language,
                    isGapiReady
                )
            );
            setModalSpeakOnUnMount(getVoiceResponse(speakOnUnmount));
            setModalCustomSlackMessage(customSlackMessage);
        },
        [dispatch, language, isGapiReady, getVoiceResponse]
    );

    const modals = useMemo(() => {
        return {
            showMeetStaffModal,
            showFindMeetingModal: () => setIsFindMeetingModalOpen(true),
        };
    }, [showMeetStaffModal, setIsFindMeetingModalOpen]);

    const [transcript, isRecording, handleToggle, resetTranscript] =
        useSpeechRecognitionManager(modals, true);

    useEffect(() => {
        if ((isRecording && transcript.length > 0) || isThinking)
            scrollToBottom();
    }, [isRecording, transcript, isThinking]);

    useEffect(() => {
        if(!iskeyboard){
            setIsShowKeyboard(false); //
        }
    }, [iskeyboard]);

    const handleSendMessage = () => {
        if (messageInput.length > 0) {
            dispatch(stopAllAudio());
            dispatch(setReceptionist("IDLE"));
            dispatch(
                sendMessage(
                    messageInput,
                    sessionId,
                    modals,
                    history,
                    language,
                    isGapiReady
                )
            ); // dispatch send message here
            dispatch(setIsPerformingAction(false));
            setMessageInput("");
            setIsShowKeyboard(false);
        }
    };
    const onChange = (input) => {
        setMessageInput(input)
    }
    const onKeyPress = (btn) => {
        if(btn === "{enter}"){
           if (messageInput.length > 0) {
                handleSendMessage()
            }
            else {
                setIsShowKeyboard(false);
            }
        }
        else if(btn === "{lock}"){
            setIsForcus(false);
         }
        else if(btn === "{shift}"){
            setLayoutName(layoutName === "default" ? "shift" : "default");
        }
    }

    return (
        <div className={classes.root}>
            {!iskeyboard ? (
                <div className={classes.micAndKeyboardContainer}>
                    <div className={classes.microphone}>
                        <Tooltip
                            title={translate("Toggle Microphone")}
                            placement={"top"}
                            arrow
                        >
                            <img
                                src={
                                    isRecording
                                        ? microphone_red
                                        : microphone_blue
                                }
                                alt="microphone img"
                                onClick={handleToggle}
                                className={classnames(classes.microphoneBlue, {
                                    [classes.microphoneRed]: isRecording,
                                })}
                            />
                        </Tooltip>
                    </div>
                    <Tooltip
                        title={translate("Toggle Keyboard")}
                        placement={"top"}
                        arrow
                    >
                        <img
                            src={keyboard}
                            className={classes.keyboard}
                            alt={"keyboard"}
                            onClick={() => setIsKeyboard(true)}
                        />
                    </Tooltip>
                </div>
            ) : (
                <div className={classes.inputContainer}>
                    <div className={classes.microphoneForInput}>
                        <Tooltip
                            title={translate("Toggle Microphone")}
                            placement={"top"}
                            arrow
                        >
                            <img
                                src={
                                    isRecording
                                        ? microphone_red
                                        : microphone_blue
                                }
                                alt="microphone img"
                                onClick={handleToggle}
                                className={classnames(
                                    classes.microphoneBlue,
                                    classes.imgForInput,
                                    {
                                        [classes.microphoneRed]: isRecording,
                                    }
                                )}
                            />
                        </Tooltip>
                    </div>
                    <TextField
                        label={translate("Enter message")}
                        variant="outlined"
                        value={messageInput}
                        fullWidth
                        onChange={(e) => setMessageInput(e.target.value)}
                        onKeyPress={(e) => {
                            e.charCode === 13 && handleSendMessage(); // if enter key is pressed redirect to product category and search
                        }}
                        onFocus={() => {setIsForcus(true)}}
                        onClick={() => setIsShowKeyboard(true)}
                        onBlur={(e) => {
                            //console.log({t: e.relatedTarget?.className})
                            if(e.relatedTarget === null || e.relatedTarget?.className?.includes('MuiButton-root')){
                                setIsShowKeyboard(false);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setIsKeyboard(false)}
                                        edge="end"
                                    >
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            )}
            {isShowKeyboard && isForcus &&
                <KeyboardWindow
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    layoutName={layoutName}
                    language={language}
                />
            }
            <div className={classes.container}>
                <div className={classes.messages} id={"chatContent"}>
                    <MessageList
                        classes={classes}
                        messages={messages}
                        primaryButtonTheme={"primaryButtonTheme"}
                        secondaryButtonTheme={"secondaryButtonTheme"}
                    />
                    {isRecording && (
                        <Message
                            classes={classes}
                            isReceiver={false}
                            message={transcript}
                            color={"primaryButtonTheme"}
                        />
                    )}
                    {isThinking && (
                        <Message
                            classes={classes}
                            message={""}
                            component={
                                <ReactLoading
                                    type={"balls"}
                                    color={"#FFFFFF"}
                                    width={"20%"}
                                    height={"20%"}
                                    className={classes.loading}
                                />
                            }
                            disabled={true}
                            isReceiver={true}
                            color={"primaryButtonTheme"}
                        />
                    )}
                </div>
            </div>

            <MeetStaffModal
                open={isMeetStaffModalOpen}
                handleClose={handleMeetStaffModalClose}
                showStaffList={showStaffList}
                defaultMentions={defaultMentions}
                speakOnUnMount={modalSpeakOnUnMount}
                customSlackMessage={modalCustomSlackMessage}
                addSuccessMessageToChat
            />

            <FindMeetingModal
                handleClose={handleFindMeetingModalClose}
                open={isFindMeetingModalOpen}
            />
        </div>
    );
};

export default ChatAndMic;
