import React, { lazy, memo, Suspense, useRef, useState } from "react";
import userStyles from "../../../styles/UI/settings/SettingsStyles";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { translate } from "../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import appearance from "../../../images/appearance.svg";
import application from "../../../images/application.svg";
import others from "../../../images/settings.svg";

// import AppearanceSettingsTab from "./AppearanceSettingsTab";
import {
    overwriteKbotButtonConfigs,
    updateAppearanceSettings,
    updateApplicationSettings,
    updateSettingsFromDB,
} from "../../../store/actions/settingsActions";
import { useApplicationSettings } from "../../../hooks/settings/settingsModal/useApplicationSettings";
import { useAppearanceSettings } from "../../../hooks/settings/settingsModal/useAppearanceSettings";
import ConfirmationModal from "../modals/ConfirmationModal";
import { AppearanceSettingsTabSkeleton } from "./AppearanceSettingsTab";
import { useKbotSettings } from "../../../hooks/settings/settingsModal/useKbotSettings";
const AppearanceSettingsTab = lazy(() => import("./AppearanceSettingsTab"));
const ApplicationSettingsTab = lazy(() => import("./ApplicationSettingsTab"));
const OtherSettingsTab = lazy(() => import("./OtherSettingsTab"));

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
};

const addFontFallback = (font) => {
    if (font !== null && font.length < 24)
        return `'${font}', sans-serif, Times, serif`;
    else return font;
};

const Settings = ({ open, handleClose }) => {
    const classes = userStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
        useState(false);
    const [isUpdateConfirmationDialogOpen, setIsUpdateConfirmationDialogOpen] =
        useState(false);
    const [tabToRedirectTo, setTabToRedirectTo] = useState(null);
    const { appearanceSettings, applicationSettings, kbotButtonConfigs } =
        useSelector((state) => state.settings);
    const appearanceSettingsRef = useRef(appearanceSettings);
    appearanceSettingsRef.current = appearanceSettings;
    const applicationSettingsRef = useRef(applicationSettings);
    applicationSettingsRef.current = applicationSettings;
    const kbotButtonConfigsRef = useRef(kbotButtonConfigs);
    kbotButtonConfigsRef.current = kbotButtonConfigs;
    const [tabValue, setTabValue] = useState(0);
    const tabValueRef = useRef(tabValue);
    tabValueRef.current = tabValue;

    //  APPEARANCE
    const appearanceSettings_ = useAppearanceSettings(
        0,
        appearanceSettingsRef,
        tabValueRef,
        open
    );
    const {
        logoSettings,
        backgroundSettings,
        avatarSettings,
        titleSettings,
        companySettings,
        specialLabelSettings,
        themeSettings,
        mapSettings,
        hasAppearanceSettingsChanged,
        appearanceDeletionDialogOpen,
        setAppearanceDeletionDialogOpen,
    } = appearanceSettings_;

    // APPLICATION
    const applicationSettings_ = useApplicationSettings(
        [1, 2],
        applicationSettingsRef,
        tabValueRef,
        open
    );

    // KBOT
    const kbotSettings = useKbotSettings(
        kbotButtonConfigsRef,
        tabValueRef,
        open
    );

    const handleTabChange = async (event, newTabValue) => {
        if (tabValue === 0 && (await hasAppearanceImageSettingsChanged())) {
            setIsConfirmationDialogOpen(true);
            setTabToRedirectTo(newTabValue);
        } else if (tabValue === 2) {
            if (kbotSettings.isSettingsValid()) setTabValue(newTabValue);
        } else {
            setTabValue(newTabValue);
        }
    };

    const handleSettingsUpdate = async (redirect = false) => {
        if (!redirect) {
            setIsUpdateConfirmationDialogOpen(true);
        } else {
            setIsConfirmationDialogOpen(false);
            await updateSettings();
            setTabValue(tabToRedirectTo);
        }
    };

    const updateSettings = async () => {
        if (!kbotSettings.isSettingsValid()) return;
        if (!mapSettings.validate()) return;
        if (applicationSettings_.validatePassword(true).error) return;

        setIsLoading(true);
        if (applicationSettings_.hasApplicationSettingsChanged())
            await updateApplicationSettings_();
            applicationSettings_.setIsTeamsChange(false)

        if (
            (await hasAppearanceImageSettingsChanged()) ||
            hasAppearanceSettingsChanged()
        )
            await updateAppearanceSettings_();

        if (kbotSettings.hasKbotSettingsChanged())
            await dispatch(
                overwriteKbotButtonConfigs(kbotSettings.getStrippedKbotConfig())
            );
        await dispatch(updateSettingsFromDB());
        setIsLoading(false);
        setIsUpdateConfirmationDialogOpen(false);
        setIsConfirmationDialogOpen(false);
    };

    const updateAppearanceSettings_ = async () => {
        const appearanceSettings_ = appearanceSettingsRef.current;
        const newAppearanceSettings = {
            title: {
                text: titleSettings.text,
                _id: appearanceSettings_.title._id,
                styles: {
                    fontSize: titleSettings.fontSize,
                    fontFamily: addFontFallback(titleSettings.fontFamily),
                    color: titleSettings.currentColor,
                },
            },
            companyName: {
                text: companySettings.text,
                _id: appearanceSettings_.companyName._id,
                styles: {
                    fontSize: companySettings.fontSize,
                    fontFamily: addFontFallback(companySettings.fontFamily),
                    color: companySettings.currentColor,
                },
            },
            specialLabel: {
                text: specialLabelSettings.text,
                _id: appearanceSettings_.specialLabel._id,
                styles: {
                    fontSize: specialLabelSettings.fontSize,
                    fontFamily: addFontFallback(
                        specialLabelSettings.fontFamily
                    ),
                    color: specialLabelSettings.currentColor,
                },
            },
            theme: {
                primaryColor: themeSettings.primaryColor,
                secondaryColor: themeSettings.secondaryColor,
                primaryButtonTheme: themeSettings.primaryButtonTheme,
                secondaryButtonTheme: themeSettings.secondaryButtonTheme,
                primaryButtonTextTheme: themeSettings.primaryButtonTextTheme,
                secondaryButtonTextTheme:
                    themeSettings.secondaryButtonTextTheme,
                widthButton: themeSettings.widthButton,
                fontSizeButton: themeSettings.fontSizeButton,
                _id: appearanceSettings_.theme._id,
                detectionTextTheme: themeSettings.detectionTextTheme,
                receptionTextTheme: themeSettings.receptionTextTheme,
            },
            avatar: {
                status: avatarSettings.isAvatarOn,
                quality: avatarSettings.avatarQuality,
                animation: avatarSettings.isAvatarAnimationOn,
                selectedAnimation: avatarSettings.selectedAnimation,
                // receptionistAnimations: hasAnimationChanged() ?  removeBase64Impurities(avatarSettings.receptionistAnimations, true) : null,
                _id: appearanceSettings_.avatar._id,
            },
            selectedBackground:
                backgroundSettings.selectedBackground ||
                appearanceSettings_.selectedBackground,
            map: {
                onFindMapFail: mapSettings.findMapFail,
                maps: mapSettings.getRefinedMaps(),
            },
            _id: appearanceSettings_._id,
        };
        if (hasAnimationChanged())
            newAppearanceSettings.avatar.receptionistAnimations =
                avatarSettings.receptionistAnimations;
        if (hasLogoChanged())
            newAppearanceSettings.logo = logoSettings.refinedPictures[0];
        if (hasBackgroundChanged())
            newAppearanceSettings.backgrounds =
                backgroundSettings.refinedPictures;

        // console.log(avatarSettings);
        // console.log(newAppearanceSettings);
        // console.log(JSON.stringify(newAppearanceSettings));
        await dispatch(updateAppearanceSettings(newAppearanceSettings));
    };

    const updateApplicationSettings_ = async () => {
        const applicationSettings__ = applicationSettingsRef.current;
        const {
            isCameraOn,
            showFaceFrame,
            showCamera,
            microphoneOn,
            receptionistVoiceGender,
            receptionistVoiceEnabled,
            receptionistVoiceEndpoints,
            doorEnabled,
            doorServer,
            faceRecognitionEnabled,
            faceRecognitionServer,
            welcomeText,
            receptionScreenVoice,
            receptionScreenTitle,
            receptionScreenInfo,
            mainLanguage,
            supportedLanguages,
            password,
            confirmPassword,
            timezone,
            minDetectionArea,
            maxAudioAge,
            maxCacheSize,
            slackToken,
            gapi,
            chatAPI,
            voiceResponses,
            selectedMessageProvider,
            selectedCalendarProvider,
            teamsAuth,
            getProviderConfig,
            isTeamsChange,
        } = applicationSettings_;
        const newApplicationSettings = {
            camera: {
                on: isCameraOn,
                show: showCamera,
                showFrame: showFaceFrame,
                minDetectionArea: minDetectionArea,
            },
            microphone: microphoneOn,
            receptionistVoice: {
                on: receptionistVoiceEnabled,
                gender: receptionistVoiceGender,
                endpoints: receptionistVoiceEndpoints,
            },
            door: {
                on: doorEnabled,
                server: doorServer,
            },
            faceRecognition: {
                on: faceRecognitionEnabled,
                server: faceRecognitionServer,
            },
            slack: {
                token: slackToken,
                config: getProviderConfig("slack"),
            },
            teams: {
                auth: teamsAuth,
                config: getProviderConfig("teams"),
            },
            messageProvider: selectedMessageProvider,
            calendarProvider: selectedCalendarProvider,
            gapi,
            welcomeText,
            localStorage: {
                maxAudioAge: parseInt(maxAudioAge),
                maxCacheSize: parseInt(maxCacheSize),
            },
            receptionScreenVoice: receptionScreenVoice,
            receptionScreenTitle: receptionScreenTitle,
            receptionScreenInfo: receptionScreenInfo,
            mainLanguage: mainLanguage,
            supportedLanguages: supportedLanguages,
            password:
                password === confirmPassword
                    ? password
                    : applicationSettings__.password,
            timezone: timezone?.value !== undefined ? timezone : null,
            chatAPI,
            voiceResponses,
            _id: applicationSettings__._id,
            isTeamsChange: isTeamsChange
        };
        // console.log(newApplicationSettings);
        await dispatch(updateApplicationSettings(newApplicationSettings));
    };

    const hasAppearanceImageSettingsChanged = async () => {
        if (appearanceSettingsRef.current !== null) {
            return (
                hasLogoChanged() ||
                hasBackgroundChanged() ||
                hasAnimationChanged()
            );
        } else return false;
    };

    const hasLogoChanged = () => {
        if (appearanceSettingsRef.current !== null) {
            return logoSettings.refinedPictures[0].fileId === undefined;
        } else return false;
    };

    const hasBackgroundChanged = () => {
        if (appearanceSettingsRef.current !== null) {
            const hasChanged = backgroundSettings.refinedPictures.map(
                (pictureObj) => pictureObj.fileId === undefined
            );
            return hasChanged.includes(true);
        } else return false;
    };

    const hasAnimationChanged = () => {
        if (appearanceSettingsRef.current !== null) {
            return avatarSettings.isAnimationUpdated;
        } else return false;
    };

    const getLastUpdated = (settingsType) => {
        switch (settingsType) {
            case "application":
                if (applicationSettingsRef.current !== null) {
                    return applicationSettingsRef.current.updatedAt;
                } else setTimeout(() => getLastUpdated(settingsType), 1000);
                break;
            case "appearance":
                if (appearanceSettingsRef.current !== null) {
                    return appearanceSettingsRef.current.updatedAt;
                } else setTimeout(() => getLastUpdated(settingsType), 1000);
                break;
            default:
                return;
        }
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"lg"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab
                        label={translate("Appearances")}
                        icon={
                            <img
                                src={appearance}
                                alt="appearance"
                                className={classes.icon}
                            />
                        }
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={translate("Application")}
                        icon={
                            <img
                                src={application}
                                alt="application"
                                className={classes.icon}
                            />
                        }
                        {...a11yProps(1)}
                    />
                    <Tab
                        label={translate("Others")}
                        icon={
                            <img
                                src={others}
                                alt="others"
                                className={classes.icon}
                            />
                        }
                        {...a11yProps(2)}
                    />
                </Tabs>
                <Suspense fallback={<AppearanceSettingsTabSkeleton />}>
                    <TabPanel value={tabValue} index={0}>
                        <AppearanceSettingsTab
                            logoSettings={appearanceSettings_.logoSettings}
                            backgroundSettings={backgroundSettings}
                            avatarSettings={avatarSettings}
                            titleSettings={titleSettings}
                            companySettings={companySettings}
                            specialLabelSettings={specialLabelSettings}
                            themeSettings={themeSettings}
                            appearanceDeletionDialogOpen={
                                appearanceDeletionDialogOpen
                            }
                            setAppearanceDeletionDialogOpen={
                                setAppearanceDeletionDialogOpen
                            }
                            mapSettings={mapSettings}
                            lastUpdated={getLastUpdated("appearance")}
                        />
                    </TabPanel>
                </Suspense>
                <Suspense fallback={<AppearanceSettingsTabSkeleton />}>
                    <TabPanel value={tabValue} index={1}>
                        <ApplicationSettingsTab
                            applicationSettings={applicationSettings_}
                            lastUpdated={getLastUpdated("application")}
                        />
                    </TabPanel>
                </Suspense>
                <Suspense fallback={<AppearanceSettingsTabSkeleton />}>
                    <TabPanel value={tabValue} index={2}>
                        <OtherSettingsTab
                            applicationSettings={applicationSettings_}
                            kbotSettings={kbotSettings}
                            lastUpdated={getLastUpdated("application")}
                        />
                    </TabPanel>
                </Suspense>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {translate("Cancel")}
                </Button>
                <Button
                    onClick={() => handleSettingsUpdate()}
                    color="primary"
                    variant={"contained"}
                    disabled={isLoading}
                >
                    {translate("Update")}
                </Button>
            </DialogActions>

            {/*update appearance setting confirmation dialog*/}
            <ConfirmationModal
                open={isConfirmationDialogOpen}
                handleClose={() => setIsConfirmationDialogOpen(false)}
                handleSuccess={() => handleSettingsUpdate(true)}
                title={translate("Update Settings")}
                message={translate(
                    "You've updated an image, would you like to save?"
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />

            {/*Update settings confirmation dialog*/}
            <ConfirmationModal
                open={isUpdateConfirmationDialogOpen}
                handleClose={() => setIsUpdateConfirmationDialogOpen(false)}
                handleSuccess={updateSettings}
                title={translate("Update Settings")}
                message={translate("Are you sure you want to update?")}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
        </Dialog>
    );
};

Settings.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default memo(Settings);
