import { createSelector } from "reselect";

function selectFaceCam(state) {
    return state.faceCam;
}

export const selectUserFalseCount = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.count
);

export const selectUnusedCount = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.unusedCount
);

export const selectIsUser = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.is_user
);
export const selectDetectionImg = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.detectionImg
);

export const selectDetection = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.detection
);

export const selectDetections = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.detections
);

export const selectCameraInfo = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.cameraInfo
);

export const selectDetectionInterval = createSelector(
    selectFaceCam,
    (faceCam) => faceCam.detectionInterval
);
