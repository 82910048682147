import { makeStyles } from "@material-ui/core/styles";
import sizes from "../../sizes";

const useStyles = makeStyles(({ primaryColor, primaryButtonTextTheme }) => ({
    root: {
        // border: '2px solid black',
        width: "100%",
        height: "15%",
        bottom: 0,
        position: "absolute",
        display: "flex",
        padding: "1em 1.5em",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        // zIndex: 1000,
        // flexDirection: 'row',
    },
    container: {
        position: "absolute",
        bottom: 50,
        right: 15,
        marginRight: "2em",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        width: "7.25em",
    },
    button: {
        margin: "1.15em",
        fontSize: "0.75rem",
        [sizes.down("lg")]: {
            fontSize: "0.5rem",
            margin: "0.5em",
        },
        [sizes.down("md")]: {
            fontSize: "0.55rem",
            margin: "0.35em",
            width: "0",
        },
    },
    logo: {
        height: "3em",
        width: "10em",
    },
    footerTitle: {
        color: primaryColor,
        width: "45%",
        marginRight: "20%",
        fontSize: "1rem",
        textAlign: "center",
        [sizes.down("lg")]: {
            width: "70%",
            marginRight: "10%",
            fontSize: "0.75rem",
        },
    },
    stopIcon: {
        width: "3em",
    },
    icon: {
        width: "2em",
    },
    white: {
        filter: "invert(92%) sepia(100%) saturate(7%) hue-rotate(189deg) brightness(103%) contrast(100%)",
    },
    backButton: {
        position: "absolute",
        bottom: 140,
        // bottom: 120,
        right: 15,
    },
    removeDefaultLink: {
        textDecoration: "none !important",
        color: "inherit !important",
    },
    spin: {
        bottom: 0,
        position: "absolute",
    },
    "@global .MuiTooltip-tooltip": {
        marginBottom: "3em !important",
        fontSize: "0.75rem !important",
    },
    "@global .MuiTooltip-popper.MuiTooltip-popperArrow": {
        zIndex: "0 !important",
    },
    buttonV2: {
        // borderRadius: "unset",
        padding: "0.5em 1.5em",
        border: "6px solid " + primaryButtonTextTheme,
        borderRadius: "10px",
    },
    iconV2: {
        height: "3em",
    },
    footer_info: {
        color: '#008bcb'
    },
    '@media only screen and (max-width: 1100px) and (min-height: 1700px)': {
        footerTitle: {
            marginRight: 0,
        },
        container: {
            marginBottom: 4
        }
    },
}));

export default useStyles;
