import locales from "../locales";

const configs = {
    language: "ja",
};

export const getLanguage = () => configs.language;

export const setLanguage = (_language) => {
    const code = _language.split("-").shift();
    if (locales[code]) {
        configs.language = code;
    }
};

export const translate = (text, language = null, ...values) => {
    const _locales =
        locales[language ? language.split("-").shift() : configs.language];
    let indexValue = 0;
    
    if (!text) {
        return text;
    }

    if (_locales[text] && _locales[text].trim() !== "") {
        return _locales[text].replace(
            /\{value\}/gi,
            () => values[indexValue++] || ""
        );
    }
    return text.replace(/\{value\}/gi, () => values[indexValue++] || "");
};
