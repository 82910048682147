import { SET_LANGUAGE } from "./types";
import moment from "moment-timezone";

// set language
export const setLanguage = (language) => (dispatch) => {
    if (language === "zhTW-CN") {
        moment.locale("zh-tw");
    }
    else {
        moment.locale(language);
    }
    dispatch({
        type: SET_LANGUAGE,
        language,
    });
};
