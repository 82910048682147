import { makeStyles } from "@material-ui/core/styles";
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        textAlign: "center",
        marginTop: "7%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    container: {
        paddingTop: "2.5rem",
        width: "70%",
        height: "90%",
    },
    welcome: {
        marginTop: "9%",
    },
    box: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 12,
        width: "90%",
    },
    container_bg: {
        // backgroundColor: "rgba(222, 62, 74, 0.9)",
        backgroundColor: alpha(theme.primaryColor, 0.75),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    container_waiting: {
        height: "70%",
    },
    remove_bg: {
        backgroundColor: "unset"
    },
    subcontainer: {
        position: "relative",
        height: "80%",
        width: "90%",
        border: "1px solid white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    subcontainer2: {
        marginTop: 70,
        width: "100%",
    },
    subcontainer3: {
        marginTop: 80,
        width: "70%",
    },

    subcontainer_sst: {
        position: "absolute",
        top: 8,
        left: 12,
        display: "flex",
        alignItems: "center",
    },

    container_question: {
        marginTop: 4,
        color: theme.primaryButtonTextTheme,
    },
    answer: {
        width: "100%",
        backgroundColor: "#fff",
        paddingTop: 12,
        paddingBottom: 12,
        fontSize: "1.6em",
        "&:hover": {
            backgroundColor: theme.primaryButtonTextTheme,
        },
        color: theme.secondaryColor,
    },
    start_title: {
        color: theme.secondaryColor,
        marginBottom: "2.5rem",
        fontSize: "3rem",
    },
    start: {
        backgroundColor: theme.primaryButtonTheme,
        color: theme.primaryButtonTextTheme,
        border: "6px solid " + theme.primaryButtonTextTheme,
        borderRadius: "10px",
        width: "78%",
        fontSize: "1.8em",
        "&:hover": {
            backgroundColor: theme.primaryButtonTheme,
        },
    },
    grid: {
        // marginTop: "2em",
        // height: "13em",
        overflowY: "auto",
        justifyContent: "center",
        paddingBottom: "2em",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    gridItem: {
        textAlign: "center",
        width: "45%",
        flexWrap: "wrap",
    },
    gridItemV2: {
        textAlign: "center",
        width: "33%",
        flexWrap: "wrap",
    },
    gridCheckBox: {
        textAlign: "center",
        width: "45%",
        flexWrap: "wrap",
    },
    gridCheckBoxV2: {
        textAlign: "center",
        width: "32%",
        flexWrap: "wrap",
    },
    removeDefaultLink: {
        textDecoration: "none !important",
        color: "inherit !important",
    },
    icon: {
        position: "absolute",
        height: "7.5em",
        top: -56,
    },

    icon_arrow: {
        fontSize: "3.2em",
        color: "#fff",
        cursor: "pointer",
    },

    icon_edit: {
        color: theme.primaryButtonTheme,
    },

    icon_clear: {
        fontSize: "2.2em",
        color: theme.primaryButtonTheme,
        fontWeight: "bold"
    },
    
    icon_mic: {
        height: "4em",
    },

    icon_mic_v2: {
        height: "3em",
        marginRight: 4,
        cursor: "pointer",
    },

    warning_msg: {
        fontSize: "1.2em",
        color: theme.primaryButtonTextTheme,
    },
    startListen: {
        textAlign: "center",
        width: "63%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        cursor: "pointer",
        padding: "12px 0px",
        borderRadius: 4
    },
    start_text: {
        fontSize: "1.6em",
        color: theme.secondaryColor,
        marginLeft: 8,
        lineHeight: "1.75",
    },
    btn_home: {
        // marginTop: "9%",
        width: "70%",
        marginTop: 16,
    },
    btn_home_color: {
        width: "20%",
        marginLeft: 32,
        marginRight: 32,
    },
    loading: {
        fill: `${theme.primaryColor} !important`,
    },

    transcript_contain: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    box_transcript: {
        width: "62%",
        backgroundColor: "#fff",
        borderRadius: 4,
        "&:hover, &:focus": {
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        }
    },

    check_box_label: {
        // color: '#fff'
    },

    check_box_checked: {
        color: theme.primaryButtonTheme + ' !important'
    },

    form_control_label: {
        border: "1px solid #fff",
        borderRadius: "8px",
        backgroundColor: "#fff",
    },

    box_transcript_title: {
        maxWidth: "50%",
        alignItems: "center",
        fontSize: "1.2em"
    },

    box_transcript_text: {
        padding: "4px 12px"
    },

    box_transcript_input: {
        border: "none"
    },

    btn_back: {
        width: "20%",
        fontSize: "1.8em",
        color: theme.primaryColor,
        padding: 8,
        borderRadius: "10px",
        margin: "0px 4px",
        backgroundColor:  "#efefef",
        "&:hover": {
            backgroundColor: "#efefef",
        },
    },

    resize: {
        fontSize: "1.6em",
    },

    "@media only screen and (max-width: 1450px) and (min-height: 720px)": {
        start: {
            fontSize: "1.2em",
        },
        icon: {
            height: "6em",
            top: -42,
        },
        subcontainer2: {
            marginTop: 45,
        },
        start_text: {
            fontSize: "1.2em",
        },
        icon_mic: {
            height: "3em",
        },
        answer: {
            fontSize: "1.2em !important",
        },
        container_question: {
            fontSize: "1.4em"
        },
        btn_back: {
            fontSize: "1.4em",
        },
        // container: {
        //     height: "85%"
        // },
        resize: {
            fontSize: "1.4em",
        }
    },
}));

export default useStyles;
