import { useEffect, useRef, useState } from "react";
import { useThemeSettings } from "../../hooks/settings/useThemeSettings";

function WaveForm() {
    const canvasRef = useRef();
    const audioContextRef = useRef();
    const requestAnimationRef = useRef();
    const [stream, setStream] = useState();
    const theme = useThemeSettings(["primaryColor", "primaryButtonTheme"]);

    useEffect(() => {
        if (!stream) {
            getStream().then(setStream, console.error);
            return;
        }
        audioContextRef.current = new AudioContext();
        const ANALYSER = audioContextRef.current.createAnalyser();
        ANALYSER.fftSize = 128;
        const SOURCE = audioContextRef.current.createMediaStreamSource(stream);
        var bufferLength = ANALYSER.frequencyBinCount;
        const DATA_ARR = new Uint8Array(bufferLength);
        SOURCE.connect(ANALYSER);

        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }
        const ctx = canvas.getContext("2d");
        ctx.strokeStyle = theme.primaryButtonTheme;

        let y = 20;
        let x = 0;
        
        const renderFrame = () => {
            requestAnimationRef.current = requestAnimationFrame(renderFrame);
            // ANALYSER.getByteTimeDomainData(DATA_ARR)
            ANALYSER.getByteFrequencyData(DATA_ARR);
            // const BARS = [...DATA_ARR].map(value => (1 - (value / 128.0)) * 100)
            ctx.clearRect(0, 0, 300, 40);
            for (let i = 0; i < bufferLength; i++) {
                // x,y,width,height
                ctx.strokeRect(x, y, 1, DATA_ARR[i] / 8);
                ctx.strokeRect(x, y, 1, -(DATA_ARR[i] / 8) );
                x += 4;
            }
            x = 0;
            return;
        };
        renderFrame();
        return () => cancelAnimationFrame(requestAnimationRef.current);
    }, [stream]);

    async function getStream() {
        try {
            return await navigator.mediaDevices.getUserMedia({ audio: true });
        } catch (err) {
            console.log(err.message);
            return;
        }
    }

    return (
        <canvas id="canvas" ref={canvasRef} width="300" height="40"></canvas>
    );
}

export default WaveForm;
