import { makeStyles } from "@material-ui/core/styles";
import { hexToRgb } from "../material-dashboard-react";

const useStyles = makeStyles(({ primaryColor, secondaryColor, detectionTextTheme }) => ({
    root: {
        width: "30%",
        // border: '2px solid black',
        // width: "100%",
        // height: "100%",

        // position: "relative",
        // display: "flex",
        // justifyContent: 'center',
        // alignItems: 'center',
        // [sizes.down('sm')]: {
        //     justifyContent: 'space-around',
        // }
    },
    faceCam: {
        float: "inline-end",
        zIndex: "99999",
        //     // width: "75%",
        //     width: '100%',
        //     [sizes.down('lg')]: {
        //         width: '45%',
        //     },
        //     [sizes.down('sm')]: {
        //         width: "40%",
        //     }
        marginTop: "3%",
    },
    userBox: {
        position: "absolute",
        textAlign: "center",
        display: "flex",
        alignItems: "flex-end",
        textTransform: "capitalize",
        fontWeight: "bold",
        fontSize: "0.85rem",
        color: "rgba(255,255,255,0.7)",
    },
    boxes: {
        position: "absolute",
        border: "solid",
        borderColor: `${primaryColor}`,
        zIndex: 999,
        "&:hover": {
            cursor: "pointer",
        },
    },
    usernameFrame: {
        background: `rgba(${hexToRgb(primaryColor)}, 0.3)`,
    },
    nameContainer: {
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        // height: "70%"
    },
    username: {
        textTransform: "capitalize !important",
        color: detectionTextTheme.color,
        fontWeight: "bold",
        fontSize: detectionTextTheme.fontSize + "em",
    },
    status: {
        width: "1.25em",
        height: "1.25em",
        borderRadius: "50%",
        marginLeft: "1em",
        marginRight: "0.5em",
        background: primaryColor,
    },
    "@global #faceCam video": {
        boxShadow:
            "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
        objectFit: "cover",
    },
    '@media only screen and (max-width: 1100px) and (min-height: 1700px)': {
        face_position: {
            position: "absolute",
            right: 0,
            zIndex: 50
        },
    },
    "@media only screen and (max-width: 1450px) and (min-height: 720px)": {
        faceCam: {
            marginTop: "8%"
        }
    }
}));

export default useStyles;
