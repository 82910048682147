import axios from "axios";
import moment from "moment-timezone";
import { ADD_NOTIFICATION } from "./types";
import { translate } from "../../utils/i18n";
import {
    deleteGoogleCalendarEvent,
    getGoogleCalendarEvents,
    addGoogleCalendarEvent,
    updateGoogleCalendarEvent,
} from "./googleCalendarActions";
import store from "../../store";

export const getAllOutlookEvents =
    (appId, timeMin, timeMax) => async (dispatch) => {
        return await axios
            .get(
                `/api/v1/outlook/${appId}?timeMin=${timeMin.toISOString()}&timeMax=${timeMax.toISOString()}&timeZone=${moment.tz.guess()}`
            )
            .then(async (res) => {
                if (res.data.success) {
                    return res.data.data;
                } else {
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                    return [];
                }
            })
            .catch((err) => {
                console.log("Error");
                console.log(err);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return [];
            });
    };

export const createOutlookEvent = (appId, event) => async (dispatch) => {
    return await axios
        .post(`/api/v1/outlook/${appId}`, event)
        .then(async (res) => {
            if (res.data.success) {
                return res.data.data;
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const updateOutlookEvent =
    (appId, eventId, updatedEvent) => async (dispatch) => {
        return await axios
            .put(`/api/v1/outlook/${appId}/${eventId}`, updatedEvent)
            .then(async (res) => {
                if (res.data.success) {
                    return res.data.data;
                } else {
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                    return null;
                }
            })
            .catch((err) => {
                console.log("Error");
                console.log(err);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            });
    };

export const deleteOutlookEvent = (appId, eventId) => async (dispatch) => {
    return await axios
        .delete(`/api/v1/outlook/${appId}/${eventId}`)
        .then(async (res) => {
            if (res.data.success) {
                return res.data.data;
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const getCalendarEventsWithProvider =
    (calendarProvider, timeMin, timeMax, isSignedInGoogleCalendar) =>
    async (dispatch) => {
        let events = [];
        switch (calendarProvider) {
            case "outlook": {
                const appId =
                    store.getState()?.settings?.applicationSettings._id;
                events = await dispatch(
                    getAllOutlookEvents(appId, timeMin, timeMax)
                );
                break;
            }
            case "google": {
                if (isSignedInGoogleCalendar) {
                    events = await dispatch(
                        getGoogleCalendarEvents(timeMin, timeMax)
                    );
                    events = events.items;
                }
                break;
            }
            default: {
                events = [];
            }
        }
        return events;
    };

export const createCalendarEventWithProvider =
    (calendarProvider, params, event) => async (dispatch) => {
        switch (calendarProvider) {
            case "outlook": {
                const appId =
                    store.getState()?.settings?.applicationSettings._id;
                return await dispatch(
                    createOutlookEvent(appId, {
                        ...event,
                        params,
                        timeZone: moment.tz.guess(),
                    })
                );
            }
            case "google": {
                return await dispatch(addGoogleCalendarEvent(params, event));
            }
            default: {
                return null;
            }
        }
    };

export const updateCalendarEventWithProvider =
    (calendarProvider, params, updatedEvent) => async (dispatch) => {
        switch (calendarProvider) {
            case "outlook": {
                const appId =
                    store.getState()?.settings?.applicationSettings._id;
                return await dispatch(
                    updateOutlookEvent(appId, params.eventId, {
                        ...updatedEvent,
                        params,
                        timeZone: moment.tz.guess(),
                    })
                );
            }
            case "google": {
                return await dispatch(
                    updateGoogleCalendarEvent(params, updatedEvent)
                );
            }
            default: {
                return null;
            }
        }
    };

export const deleteCalendarEventWithProvider =
    (calendarProvider, eventId) => async (dispatch) => {
        switch (calendarProvider) {
            case "outlook":
                const appId =
                    store.getState()?.settings?.applicationSettings._id;
                return await dispatch(deleteOutlookEvent(appId, eventId));
            case "google":
                return await dispatch(deleteGoogleCalendarEvent(eventId));
            default:
                return false;
        }
    };
