import { SET_USERS_TEAMS } from "../actions/types";

const initialState = {
    members: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USERS_TEAMS:
            return {
                ...state,
                members: action.members,
            };
        default:
            return state;
    }
};
