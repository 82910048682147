import { env } from "../../config/env";
import {
    SET_IS_USER,
    SET_IS_USER_FALSE_COUNT,
    SET_DETECTION_INTERVAL,
    SET_DETECTIONS,
    SET_DETECTION,
    SET_DETECTION_IMG,
    ADD_TO_REGISTRATION_IMAGES,
    CLEAR_REGISTRATION_IMAGES,
    SET_IS_REGISTERING,
    SET_HAS_BOWED,
    SET_CAMERA_INFO,
    SET_UN_USED_COUNT,
    CLEAR_UN_USED_COUNT,
} from "../actions/types";

const initialState = {
    is_user: false,
    hasBowed: false,
    count: 0,
    detectionInterval: parseInt(process.env.REACT_APP_DETECTION_INTERVAL),
    detections: [],
    detection: null,
    detectionImg: null,
    registrationImages: [],
    cameraInfo: null,
    isRegistering: false,
    unusedCount: 0
};

export default function faceCamReducer(state = initialState, action) {
    switch (action.type) {
        case SET_IS_USER: {
            if (state.is_user !== action.is_user) {
                return {
                    ...state,
                    is_user: action.is_user,
                };
            }
            return state;
        }

        case SET_IS_USER_FALSE_COUNT: {
            if (action.increase) {
                // return the previous state to avoid unneccessary store update
                // that lead to unneccessary component rerender
                if (state.count > env.REACT_APP_FALSE_COUNT) {
                    return state
                }
                return {
                    ...state,
                    count: state.count + 1,
                };
            } else {
                return {
                    ...state,
                    count: action.count,
                };
            }
        }

        case SET_UN_USED_COUNT: {
            if (state.unusedCount > env.REACT_APP_REGISTRATION_DETECTION_INTERVAL) {
                return state
            }
            return {
                ...state,
                unusedCount: state.unusedCount + 1,
            };
        }
        case CLEAR_UN_USED_COUNT: {
            return {
                ...state,
                unusedCount: 0,
            };
        }
        
        case SET_DETECTION_INTERVAL: {
            if (action.detectionInterval !== state.detectionInterval) {
                return {
                    ...state,
                    detectionInterval: action.detectionInterval,
                };
            }
            return state;
        }

        case SET_DETECTIONS:
            if (action.detections !== state.detections) {
                return {
                    ...state,
                    detections: action.detections,
                };
            }
            return state;
        case SET_DETECTION: {
            if (action.detection !== state.detection) {
                return {
                    ...state,
                    detection: action.detection,
                };
            }
            return state;
        }
        case SET_DETECTION_IMG: {
            if (action.detectionImg !== state.detectionImg) {
                return {
                    ...state,
                    detectionImg: action.detectionImg,
                };
            }
            return state;
        }
        case ADD_TO_REGISTRATION_IMAGES:
            return {
                ...state,
                registrationImages: [
                    ...state.registrationImages,
                    action.registrationImg,
                ],
            };
        case CLEAR_REGISTRATION_IMAGES: {
            if (state.registrationImages.length) {
                return {
                    ...state,
                    registrationImages: [],
                };
            }
            return state;
        }

        case SET_IS_REGISTERING: {
            if (action.isRegistering !== state.isRegistering) {
                return {
                    ...state,
                    isRegistering: action.isRegistering,
                };
            }
            return state;
        }
        case SET_HAS_BOWED: {
            if (action.hasBowed !== state.hasBowed) {
                return {
                    ...state,
                    hasBowed: action.hasBowed,
                };
            }
            return state;
        }
        case SET_CAMERA_INFO: {
            if (action.cameraInfo !== state.cameraInfo) {
                return {
                    ...state,
                    cameraInfo: action.cameraInfo,
                };
            }
            return state;
        }

        default:
            return state;
    }
}
