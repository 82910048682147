import en from "../images/icon-14.png";
import vn from "../images/vietnam.png";
import ja from "../images/japan.png";
import ko from "../images/south_korea.png";
import zh from "../images/chinese.png";
import zhTW from "../images/chineseTW.png";
import th from "../images/thailand.png";

import enLocale from "date-fns/locale/en-US";
import viLocale from "date-fns/locale/vi";
import jaLocale from "date-fns/locale/ja";
import koLocale from "date-fns/locale/ko";
import zhLocale_cn from "date-fns/locale/zh-CN";
import zhLocale_tw from "date-fns/locale/zh-TW";
import thLocale from "date-fns/locale/th";


import {
    aimeColor,
    blackColor,
    grayColor,
    whiteColor,
} from "./material-dashboard-react";

const colors = {
    primaryColor: aimeColor[2],
    secondaryColor: blackColor,
    primaryButtonTheme: aimeColor[0],
    secondaryButtonTheme: whiteColor,
    primaryButtonTextTheme: whiteColor,
    secondaryButtonTextTheme: grayColor[0],
};
const buttonStyle = {
    widthButton: 50,
    fontSizeButton: 1,
};

const defaultDetectionTextTheme = {
    color: blackColor,
    fontSize: "1.2"
}

const languageCodes = {
    english: "en-US",
    vietnamese: "vi-VN",
    japanese: "ja-JP",
    korean: "ko-KR",
    chinese: "zh-CN",
    chineseTW: "zhTW-CN",
    thailand: "th-TH"
};

const languageCodes_ = {
    [languageCodes.english]: "english",
    [languageCodes.vietnamese]: "vietnamese",
    [languageCodes.japanese]: "japanese",
    [languageCodes.korean]: "korean",
    [languageCodes.chinese]: "chinese",
    [languageCodes.chineseTW]: "chineseTW",
    [languageCodes.thailand]: "thailand",
};

const languageDisplay = {
    english: "English",
    vietnamese: "Tiếng Việt",
    japanese: "日本語",
    korean: "한국어",
    chinese: "中文（繁體）",
    chineseTW: "中文（简体）",
    thailand: "ภาษาไทย",
};

const languageIcons = {
    english: en,
    vietnamese: vn,
    japanese: ja,
    korean: ko,
    chinese: zh,
    chineseTW: zhTW,
    thailand: th,
};
const localeMap = {
    [languageCodes.english]: enLocale,
    [languageCodes.vietnamese]: viLocale,
    [languageCodes.japanese]: jaLocale,
    [languageCodes.korean]: koLocale,
    [languageCodes.chinese]: zhLocale_cn,
    [languageCodes.chineseTW]: zhLocale_tw,
    [languageCodes.thailand]: thLocale,
};

const slack = {
    DELIVERY_STAFFS: [
        {
            name: "Thu",
            slack_id: "thu",
        },
        {
            name: "Hiền",
            slack_id: "hienlt",
        },
    ],
    DELIVERY_CHANNELS: ["reception_alert"],
    DELIVERY_MESSAGE: "Please go outside to receive mail/package",
    INTERVIEW_STAFFS: [
        {
            gender: "anh",
            name: "Huy",
            slack_id: "huypq",
        },
        {
            gender: "chị",
            name: "Hằng",
            slack_id: "hangnguyen",
        },
    ],
    INTERVIEW_CHANNELS: ["reception_alert"],
    INTERVIEW_MESSAGE: "Mr./Ms <name> is here for an interview",
    DEFAULT_MENTIONS: [
        {
            name: "Nguyễn Thị Ánh Thu",
            slack_id: "thu",
        },
        {
            name: "Lê Thanh Hiền",
            slack_id: "hienlt",
        },
        {
            name: "Phạm Quang Huy",
            slack_id: "huypq",
        },
        {
            name: "Nguyễn Thị Thu Hằng",
            slack_id: "hangnguyen",
        },
        {
            name: "Nguyễn Chính Hoàng",
            slack_id: "hoangnc",
        },
        {
            name: "Nguyễn Tuấn Hưng",
            slack_id: "hung",
        },
        {
            name: "Nguyễn Tuấn Anh",
            slack_id: "ntanh",
        },
        {
            name: "Nguyễn Tuấn Đức",
            slack_id: "duc",
        },
        {
            name: "Trịnh Văn Quân",
            slack_id: "quantv",
        },
        {
            name: "Praise Oketola Oluwabamise",
            slack_id: "Praise",
        },
        {
            name: "Nguyễn Minh Phương",
            slack_id: "phuongnm",
        },
    ],
    DEFAULT_CHANNELS: ["reception_alert"],
    DEFAULT_MESSAGE: "Mr./Ms. <name> wants to see you",
};

const teams = {
    DELIVERY_STAFFS: [
        {
            name: "Henrietta Mueller",
            teamsStaffId: "Henrietta Mueller",
        },
    ],
    DELIVERY_CHANNELS: [
        {
            teamId: "Mark 8 Project Team",
            channelId: "General",
        },
    ],
    DELIVERY_MESSAGE: "Please go outside to receive mail/package",
    INTERVIEW_STAFFS: [
        {
            gender: "anh",
            name: "Henrietta Mueller",
            teamsStaffId: "Henrietta Mueller",
        },
    ],
    INTERVIEW_CHANNELS: [
        {
            teamId: "Mark 8 Project Team",
            channelId: "General",
        },
    ],
    INTERVIEW_MESSAGE: "Mr./Ms <name> is here for an interview",
    DEFAULT_MENTIONS: [
        {
            name: "Henrietta",
            teamsStaffId: "Henrietta Mueller",
        },
        {
            name: "Lee",
            teamsStaffId: "Lee Gu",
        },
    ],
    DEFAULT_CHANNELS: [
        {
            teamId: "Mark 8 Project Team",
            channelId: "General",
        },
    ],
    DEFAULT_MESSAGE: "Mr./Ms. <name> wants to see you",
};

const DEFAULT_SLACK_CONFIG = {
    delivery: {
        staffs: slack.DELIVERY_STAFFS,
        channels: slack.DELIVERY_CHANNELS,
        message: slack.DELIVERY_MESSAGE,
        staffModal: false,
    },
    meeting: {
        staffs: slack.DEFAULT_MENTIONS,
        channels: slack.DEFAULT_CHANNELS,
        message: slack.DEFAULT_MESSAGE,
        staffModal: false,
    },
    interview: {
        staffs: slack.INTERVIEW_STAFFS,
        channels: slack.INTERVIEW_CHANNELS,
        message: slack.INTERVIEW_MESSAGE,
        staffModal: false,
    },
};

const DEFAULT_TEAMS_CONFIG = {
    delivery: {
        staffs: teams.DELIVERY_STAFFS,
        channels: teams.DELIVERY_CHANNELS,
        message: teams.DELIVERY_MESSAGE,
        staffModal: false,
    },
    meeting: {
        staffs: teams.DEFAULT_MENTIONS,
        channels: teams.DEFAULT_CHANNELS,
        message: teams.DEFAULT_MESSAGE,
        staffModal: false,
    },
    interview: {
        staffs: teams.INTERVIEW_STAFFS,
        channels: teams.INTERVIEW_CHANNELS,
        message: teams.INTERVIEW_MESSAGE,
        staffModal: false,
    },
};

const MESSAGE_PROVIDER_FIELD_NAMES = {
    slack: {
        channels: "channels",
        staffId: "slack_id",
    },
    teams: {
        channels: "teams",
        staffId: "teamsStaffId",
    },
};

/**
 * Can get more google tts voice here https://cloud.google.com/text-to-speech/docs/voices
 * Browser voices across different browsers/ browser version. Should add to the list if voice is not included
 * */
const TTSVoices = {
    [languageCodes.english]: {
        browser: {
            male: [],
            female: [],
        },
        gapi: {
            male: "en-US-Wavenet-D",
            female: "en-US-Wavenet-F",
        },
        aws: {
            male: "",
            female: "",
        }
    },
    [languageCodes.vietnamese]: {
        browser: {
            male: [],
            female: [],
        },
        gapi: {
            male: "vi-VN-Wavenet-B",
            female: "vi-VN-Standard-A",
            // female: "vi-VN-Wavenet-C",
        },
        aws: {
            male: "",
            female: "",
        }
    },
    [languageCodes.japanese]: {
        browser: {
            male: [],
            female: [],
        },
        gapi: {
            male: "ja-JP-Standard-D",
            female: "ja-JP-Wavenet-B",
        },
        aws: {
            male: "Takumi",
            female: "Tomoko",
        }
    },
    [languageCodes.korean]: {
        browser: {
            male: [],
            female: ["Google 한국의"],
        },
        gapi: {
            male: "ko-KR-Wavenet-C",
            female: "ko-KR-Wavenet-A",
        },
        aws: {
            male: "",
            female: "",
        }
    },
    [languageCodes.chinese]: {
        browser: {
            male: ["Google 普通话（中国大陆）"],
            female: [], //Google 普通话（中国大陆）
        },
        gapi: {
            male: "cmn-CN-Wavenet-B",
            female: "cmn-CN-Wavenet-A",
        },
        aws: {
            male: "",
            female: "",
        }
    },
    [languageCodes.chineseTW]: {
        browser: {
            male: [],
            female: ["Google 國語（臺灣）"], //Google 國語（臺灣）
        },
        gapi: {
            male: "cmn-TW-Wavenet-B",
            female: "cmn-TW-Wavenet-A",
        },
        aws: {
            male: "",
            female: "",
        }
    },
    [languageCodes.thailand]: {
        browser: {
            male: [],
            female: [],
        },
        gapi: {
            male: "th-TH-Standard-A",
            female: "th-TH-Standard-A",
        },
        aws: {
            male: "",
            female: "",
        }
    },
};

const languageSelectionLabels = [
    "Please select your language to begin",
    "Xin hãy chọn ngôn ngữ để bắt đầu",
    "言語を選択してください",
    "시작할 언어를 선택하십시오",
    "请选择语言开始",
    "請選擇語言開始"
];

const ttsPostProcessWords = [
    {
        word: "aimesoft",
        [languageCodes.english]: [
            "insults",
            "I'm soft",
            "aimersoft",
            "I'm a soft",
            "Airsoft",
            "I miss hot",
            "Home-Start",
            "ainsoft",
            "m soft",
            "Amy soft",
            "emsisoft",
            "emsoft",
        ],
        [languageCodes.vietnamese]: [
            "adc",
            "Misa",
            "ministop",
            "abc",
            "imi shop",
            "army shop",
        ],
    },
    {
        word: "アイメソフト",
        [languageCodes.japanese]: [
            "アニメ ソフト",
            "アニメソフト",
            "IMEソフト",
            "IME ソフト",
            "菖蒲 ソフト",
            "菖蒲ソフト",
        ],
    },
    {
        /**
         * Regex only works if 'getOnlyName' option is equal True when speechRecognitionManager is initialized.
         *  Word field can be an array of regex or a string
         *  Language field takes the regex group index
         **/
        word: [/my name is (.+)/, /I am (.+)/],
        isRegex: true,
        [languageCodes.english]: { groupToReplace: 0, replaceGroupWith: 1 },
    },
    {
        word: [/(.+)です/, /(.+)と言います/, /(.+)と申します/, /(.+)ですが/],
        isRegex: true,
        [languageCodes.japanese]: { groupToReplace: 0, replaceGroupWith: 1 },
    },
];

const defaultVoiceResponses = {
    delivery: {
        after: {
            known: {
                [languageCodes.english]:
                    "Our staff got the message and will get back to you in a minute, please wait",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。",
                [languageCodes.korean]:
                    "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                [languageCodes.chinese]:
                    "我们的员工已收到消息，请稍等",
                [languageCodes.chineseTW]:
                    "我們的員工已收到消息，請稍等",
                [languageCodes.thailand]:
                    "พนักวานของเราได้รับข้อความแล้ว กรุณารอสักครู่",
            },
            unknown: {
                [languageCodes.english]:
                    "Our staff got the message and will get back to you in a minute, please wait",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。",
                [languageCodes.korean]:
                    "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                [languageCodes.chinese]:
                    "我们的员工已收到消息，请稍等",
                [languageCodes.chineseTW]:
                    "我們的員工已收到消息，請稍等",
                [languageCodes.thailand]:
                    "พนักวานของเราได้รับข้อความแล้ว กรุณารอสักครู่",
            },
        },
    },
    meeting: {
        meeting: {
            before: {
                known: {
                    [languageCodes.english]: "",
                    [languageCodes.vietnamese]: "",
                    [languageCodes.japanese]: "",
                    [languageCodes.korean]: "",
                    [languageCodes.chinese]: "",
                    [languageCodes.chineseTW]: "",
                    [languageCodes.thailand]: "",
                },
                unknown: {
                    [languageCodes.english]: "Please tell me your name",
                    [languageCodes.vietnamese]:
                        "Xin vui lòng cho tôi biết tên của bạn",
                    [languageCodes.japanese]:
                        "かしこまりました。お名前をお聞かせくださいますか。",
                    [languageCodes.korean]: "당신의 이름을 알려주세요",
                    [languageCodes.chinese]: "请告知我你的姓名",
                    [languageCodes.chineseTW]: "請告知我你的姓名",
                    [languageCodes.thailand]: "กรุณาบอกชื่อของคุณ",
                },
            },
            after: {
                known: {
                    [languageCodes.english]:
                        "<name>, Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                    [languageCodes.chinese]:
                        "我们的员工已收到消息，请稍等",
                    [languageCodes.chineseTW]:
                        "我們的員工已收到消息，請稍等",
                    [languageCodes.thailand]:
                        "พนักวานของเราได้รับข้อความแล้ว กรุณารอสักครู่",
                },
                unknown: {
                    [languageCodes.english]:
                        "<name>, Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                    [languageCodes.chinese]:
                        "我们的员工已收到消息，请稍等",
                    [languageCodes.chineseTW]:
                        "我們的員工已收到消息，請稍等",
                    [languageCodes.thailand]:
                        "พนักวานของเราได้รับข้อความแล้ว กรุณารอสักครู่",
                },
            },
            onFindMeetingFail: {
                known: {
                    [languageCodes.english]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                    [languageCodes.vietnamese]:
                        "<name>, Tôi không tìm thấy cuộc họp nào. Bạn có muốn liên lạc với nhân viên công ty không",
                    [languageCodes.japanese]:
                        "<name> 様。会議が見つかりませんでした。代わりにスタッフに連絡しますか",
                    [languageCodes.korean]:
                        "<name>, 회의를 찾을 수 없습니다. 직원에게 연락하시겠습니까?",
                    [languageCodes.chinese]: "<name>, 我找不到你名下的任何会议。 您要联系公司的员工吗？",
                    [languageCodes.chineseTW]: "<name>, 我找不到你名下的任何會議。你要聯繫公司的員工嗎？",
                    [languageCodes.thailand]:
                    "<name>, ฉันไม่ได้พบการประชุมใด ๆ คุณต้องการติดต่อกับพนักงานบริษัทหรือไม่",
                },
                unknown: {
                    [languageCodes.english]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                    [languageCodes.vietnamese]:
                        "<name>, Tôi không tìm thấy cuộc họp nào. Bạn có muốn liên lạc với nhân viên công ty không",
                    [languageCodes.japanese]:
                        "<name> 様。会議が見つかりませんでした。代わりにスタッフに連絡しますか",
                    [languageCodes.korean]:
                        "<name>, 회의를 찾을 수 없습니다. 직원에게 연락하시겠습니까?",
                    [languageCodes.chinese]: "<name>, 我找不到你名下的任何会议。 您要联系公司的员工吗？",
                    [languageCodes.chineseTW]: "<name>, 我找不到你名下的任何會議。你要聯繫公司的員工嗎？",
                    [languageCodes.thailand]:
                    "<name>, ฉันไม่ได้พบการประชุมใด ๆ คุณต้องการติดต่อกับพนักงานบริษัทหรือไม่",
                },
            },
            onFindMeetingSuccess: {
                known: {
                    [languageCodes.english]:
                        "We've found your meeting <name>. The meeting will take place in <location>, at <time>",
                    [languageCodes.vietnamese]:
                        "<name>, tôi đã tìm thấy cuộc họp của bạn. Cuộc họp sẽ diễn ra tại <location> vào <time>",
                    [languageCodes.japanese]:
                        "<name>様のミーティングが見つかりました。ミーティングは<location>で<time>に行われます。少々お待ちください。",
                    [languageCodes.korean]:
                        "<name>님, 회의를 찾았습니다. 회의가 <time>에 <location>에서 진행될 것입니다",
                    [languageCodes.chinese]: "我已找到你的会议。会议将于<time> 在 <location> 举行",
                    [languageCodes.chineseTW]: "我已找到你的會議。會議將於<time> 在 <location> 舉行",
                    [languageCodes.thailand]:
                    "ฉันพบการประชุมของคุณแล้ว การประชุมจะจัดขึ้นที่ <location> ตอน <time>",
                    },
                unknown: {
                    [languageCodes.english]:
                        "We've found your meeting <name>. The meeting will take place in <location>, at <time>",
                    [languageCodes.vietnamese]:
                        "<name>, tôi đã tìm thấy cuộc họp của bạn. Cuộc họp sẽ diễn ra tại <location> vào <time>",
                    [languageCodes.japanese]:
                        "<name>様のミーティングが見つかりました。ミーティングは<location>で<time>に行われます。少々お待ちください。",
                    [languageCodes.korean]:
                        "<name>님, 회의를 찾았습니다. 회의가 <time>에 <location>에서 진행될 것입니다",
                    [languageCodes.chinese]: "我已找到你的会议。会议将于<time> 在 <location> 举行",
                    [languageCodes.chineseTW]: "我已找到你的會議。會議將於<time> 在 <location> 舉行",
                    [languageCodes.thailand]:
                    "ฉันพบการประชุมของคุณแล้ว การประชุมจะจัดขึ้นที่ <location> ตอน <time>",
                },
            },
        },
        seeingSomeone: {
            before: {
                known: {
                    [languageCodes.english]: "<name>, who do you want to see?",
                    [languageCodes.vietnamese]:
                        "Xin vui lòng cho tôi biết tên người bạn muốn gặp",
                    [languageCodes.japanese]:
                        "かしこまりました。お名前をご確認くださいますか。",
                    [languageCodes.korean]:
                        "이름과 만나고 싶은 사람을 알려주세요",
                    [languageCodes.chinese]: "请告诉我你想见的人的姓名",
                    [languageCodes.chineseTW]: "請告訴我你想見的人的姓名",
                    [languageCodes.thailand]: "กรุณาให้ฉันทราบชื่อคนที่คุณต้องการพบ"
                },
                unknown: {
                    [languageCodes.english]:
                        "Please tell me your name and the person you want to see",
                    [languageCodes.vietnamese]:
                        "Xin vui lòng cho tôi biết tên của bạn và người bạn muốn gặp",
                    [languageCodes.japanese]:
                        "かしこまりました。お名前をお聞かせくださいますか。",
                    [languageCodes.korean]:
                        "이름과 만나고 싶은 사람을 알려주세요",
                    [languageCodes.chinese]: "请告诉我你和你要见的人的姓名",
                    [languageCodes.chineseTW]: "請告訴我你和你要見的人的姓名",
                    [languageCodes.thailand]: "กรุณาบอกชื่อของคุณและคนที่คุณต้องการพบ"
                },
            },
            after: {
                known: {
                    [languageCodes.english]:
                        "<name>, Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                    [languageCodes.chinese]:
                        "我们的员工已收到消息，请稍等",
                    [languageCodes.chineseTW]:
                        "我們的員工已收到消息，請稍等",
                    [languageCodes.thailand]: "พนักวานของเราได้รับข้อความแล้ว กรุณารอสักครู่",
                    },
                unknown: {
                    [languageCodes.english]:
                        "Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                    [languageCodes.chinese]:
                        "我们的员工已收到消息，请稍等",
                    [languageCodes.chineseTW]:
                        "我們的員工已收到消息，請稍等",
                    [languageCodes.thailand]: "พนักวานของเราได้รับข้อความแล้ว กรุณารอสักครู่",
                },
            },
        },
    },
    interview: {
        before: {
            known: {
                [languageCodes.english]: "Please confirm your name",
                [languageCodes.vietnamese]: "Xin vui lòng xác nhận tên của bạn",
                [languageCodes.japanese]:
                    "かしこまりました。面接ですね。それではお名前を教えてください。",
                [languageCodes.korean]: "당신의 이름을 알려주세요",
                [languageCodes.chinese]:
                    "请确认你的姓名",
                [languageCodes.chineseTW]:
                    "請確認你的姓名",
                [languageCodes.thailand]: "กรุณายืนยันชื่อของคุณ",
            },
            unknown: {
                [languageCodes.english]: "Please tell me your name",
                [languageCodes.vietnamese]:
                    "Xin vui lòng cho tôi biết tên của bạn",
                [languageCodes.japanese]:
                    "かしこまりました。面接ですね。それではお名前を教えてください。",
                [languageCodes.korean]: "당신의 이름을 알려주세요",
                [languageCodes.chinese]:
                    "请告诉我你的姓名",
                [languageCodes.chineseTW]:
                    "請告訴我你的姓名",
                [languageCodes.thailand]: "กรุณาบอกชื่อของคุณ",
            },
        },
        after: {
            known: {
                [languageCodes.english]:
                    "Our staff got the message, please wait a second",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được tin nhắn, bạn vui lòng đợi chút ạ.",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。リラックスしてくださいね。",
                [languageCodes.korean]:
                    "저희 직원이 메시지를 받았습니다. 잠시만 기다려 주십시오.",
                [languageCodes.chinese]:
                    "我们的人员已收到消息，请稍等.",
                [languageCodes.chineseTW]:
                    "我們的人員已收到消息，請稍等.",
                [languageCodes.thailand]:
                    "พนักงานของพวกเราได้รับข้อความแล้ว กรุณารอสักครู่ค่ะ",
            },
            unknown: {
                [languageCodes.english]:
                    "Our staff got the message, please wait a second",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được tin nhắn, bạn vui lòng đợi chút ạ.",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。リラックスしてくださいね。",
                [languageCodes.korean]:
                    "저희 직원이 메시지를 받았습니다. 잠시만 기다려 주십시오.",
                [languageCodes.chinese]:
                    "我们的人员已收到消息，请稍等.",
                [languageCodes.chineseTW]:
                    "我們的人員已收到消息，請稍等.",
                [languageCodes.thailand]:
                    "พนักงานของพวกเราได้รับข้อความแล้ว กรุณารอสักครู่ค่ะ",
            },
        },
    },
    waitting: {
        after: {
            known: {
                [languageCodes.english]:
                    "Please wait",
                [languageCodes.vietnamese]:
                    "Bạn vui lòng đợi một chút",
                [languageCodes.japanese]:
                    "少々お待ちください。",
                [languageCodes.korean]:
                    "잠시만 기다려 주세요",
                [languageCodes.chinese]:
                    "请稍等",
                [languageCodes.chineseTW]:
                    "請稍等",
                [languageCodes.thailand]:
                    "กรุณารอสักครู่",
            },
            unknown: {
                [languageCodes.english]:
                    "Please wait",
                [languageCodes.vietnamese]:
                    "Bạn vui lòng đợi một chút",
                [languageCodes.japanese]:
                    "少々お待ちください。",
                [languageCodes.korean]:
                    "잠시만 기다려 주세요",
                [languageCodes.chinese]:
                    "请稍等",
                [languageCodes.chineseTW]:
                    "請稍等",
                [languageCodes.thailand]:
                    "กรุณารอสักครู่",
            }
        }
    }
};

const defaultWelcomeText = {
    staff: {
        [languageCodes.english]: "Hello <name>",
        [languageCodes.vietnamese]: "Xin chào <name>",
        [languageCodes.japanese]: "こんにちは<name>さん",
        [languageCodes.korean]: "안녕하세요 <name>",
        [languageCodes.chinese]: "你好 <name>",
        [languageCodes.chineseTW]: "你好 <name>",
        [languageCodes.thailand]: "สวัสดีค่ะ <name>",
    },
    guest: {
        [languageCodes.english]: "Hello",
        [languageCodes.vietnamese]: "Xin chào",
        [languageCodes.japanese]: "こんにちは",
        [languageCodes.korean]: "안녕하세요",
        [languageCodes.chinese]: "你好",
        [languageCodes.chineseTW]: "你好",
        [languageCodes.thailand]: "สวัสดีค่ะ",
    },
};

const defaultReceptionScreenVoice = {
    known: {
        [languageCodes.english]: "How can I help you?",
        [languageCodes.vietnamese]: "Tôi giúp được gì cho bạn?",
        [languageCodes.japanese]: "どんな御用でしょうか？",
        [languageCodes.korean]: "어떻게 도와 드릴까요?",
        [languageCodes.chinese]: "我可以帮助你什么?",
        [languageCodes.chineseTW]: "我可以幫助你什麼?",
        [languageCodes.thailand]: "ฉันสามารถช่วยอะไรคุณได้บ้าง",
    },
    unknown: {
        [languageCodes.english]: "How can I help you?",
        [languageCodes.vietnamese]: "Tôi giúp được gì cho bạn?",
        [languageCodes.japanese]: "どんな御用でしょうか？",
        [languageCodes.korean]: "어떻게 도와 드릴까요?",
        [languageCodes.chinese]: "我可以帮助你什么?",
        [languageCodes.chineseTW]: "我可以幫助你什麼?",
        [languageCodes.thailand]: "ฉันสามารถช่วยอะไรคุณได้บ้าง",
    },
};

const defaultReceptionScreenTitle = {
    [languageCodes.english]: "How can I help you?",
    [languageCodes.vietnamese]: "Tôi giúp được gì cho bạn?",
    [languageCodes.japanese]: "どんな御用でしょうか？",
    [languageCodes.korean]: "어떻게 도와 드릴까요?",
    [languageCodes.chinese]: "我可以帮助你什么?",
    [languageCodes.chineseTW]: "我可以幫助你什麼?",
    [languageCodes.thailand]: "ฉันสามารถช่วยอะไรคุณได้บ้าง",
};

const defaultReceptionScreenInfo = {
    [languageCodes.english]: 
        "Not in the above list? Press the microphone button or keyboard button on the right hand side and tell us your enquiry.",
    [languageCodes.vietnamese]: 
        "Không có trong danh sách trên? Nhấn nút micrô hoặc nút bàn phím ở phía bên tay phải và cho chúng tôi biết yêu cầu của bạn.",
    [languageCodes.japanese]: 
        "他のご要件がございましたら、右側にあるマイクボタンまたはキーボードボタンを押して、お問い合わせください",
    [languageCodes.korean]: 
        "위 명단에 없습니다. 마이크 버튼 또는 오른쪽에 있는 키보드 버튼을 누르시고 요청 사항을 알려주십시오.",
    [languageCodes.chinese]: 
        "没有在上面的清单？按传声器或者右边的键盘按钮，然后告知我们你的需求.",
    [languageCodes.chineseTW]: 
        "沒有在上面的清單？按傳聲器或者右邊的鍵盤按鈕，然後告知我們你的需求.",
    [languageCodes.thailand]: 
        "ไม่มีในรายชื่อ กดปุ่มไมโครโฟนหรือกดปุ่มแป้นพิมพ์ด้านขวาและให้พวกเราทราบความต้องการของคุณ",
    };

const defaultOnFindMapFailResponse = {
    known: {
        [languageCodes.english]: "",
        [languageCodes.vietnamese]: "",
        [languageCodes.japanese]: "",
        [languageCodes.korean]: "",
        [languageCodes.chinese]: "",
        [languageCodes.chineseTW]: "",
        [languageCodes.thailand]: "",
    },
    unknown: {
        [languageCodes.english]: "",
        [languageCodes.vietnamese]: "",
        [languageCodes.japanese]: "",
        [languageCodes.korean]: "",
        [languageCodes.chinese]: "",
        [languageCodes.chineseTW]: "",
        [languageCodes.thailand]: "",
    },
};

const layOutKeyboard = {
    default: {
        [languageCodes.english]: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp} {lock}",
            "{tab} q w e r t y u i o p [ ] \\",
            ".com a s d f g h j k l ; ' {enter}",
            "{shift} z x c v b n m , . / {shift}",
          "@ {space}"
        ],
    },
    shift: {
        [languageCodes.english]: [
            "~ ! @ # $ % ^ & * ( ) _ + {bksp} {lock}",
            "{tab} Q W E R T Y U I O P { } |",
            '.com A S D F G H J K L : " {enter}',
            "{shift} Z X C V B N M < > ? {shift}",
           "@ {space}"
        ],
    }
}

const QA_TITLE_START = "Aimesoftへようこそ！";
const CHECK_IN = "ありがとうございました。安全に注意して作業してください";
const CHECK_OUT = "ありがとうございました。気を付けてお帰り下さい。";
const QA_FORM_TITLE = "社員番号を入力して、顔を登録してください";
const QA_BUTTON_START_VOICE_ANSWER = "クリックして答えを入力してください。";
const QA_BUTTON_STOP_VOICE_ANSWER = "ストップ";
const GREETING = "こんにちは、花子です。健康をチェックしますよ";

export {
    slack,
    teams,
    DEFAULT_SLACK_CONFIG,
    DEFAULT_TEAMS_CONFIG,
    MESSAGE_PROVIDER_FIELD_NAMES,
    colors,
    languageCodes,
    languageCodes_,
    languageDisplay,
    languageIcons,
    TTSVoices,
    localeMap,
    languageSelectionLabels,
    ttsPostProcessWords,
    defaultVoiceResponses,
    defaultWelcomeText,
    defaultReceptionScreenVoice,
    defaultOnFindMapFailResponse,
    layOutKeyboard,
    defaultReceptionScreenTitle,
    defaultReceptionScreenInfo,
    buttonStyle,
    defaultDetectionTextTheme,
    QA_TITLE_START,
    CHECK_IN,
    CHECK_OUT,
    QA_FORM_TITLE,
    QA_BUTTON_START_VOICE_ANSWER,
    QA_BUTTON_STOP_VOICE_ANSWER,
    GREETING,
};
