import { createSelector } from "reselect";

function selectUser(state) {
    return state.user;
}

export const selectRecognizedCount = createSelector(
    selectUser,
    (user) => user.recognizedCount
);
export const selectIsUserRecognized = createSelector(
    selectUser,
    (user) => user.isUserRecognized
);
export const selectUserFaceId = createSelector(
    selectUser,
    (user) => user.userFaceId
);
export const selectUserInfo = createSelector(
    selectUser,
    (user) => user.userInfo
);
export const selectCurrentUsers = createSelector(
    selectUser,
    (user) => user.currentUsers
);
export const selectHasPreviousCurrentUsersChanged = createSelector(
    selectUser,
    (user) => user.hasPreviousCurrentUsersChanged
);
export const selectNameRomaji = createSelector(
    selectUser,
    (user) => user.romaji
);
export const selectIsRecorgnizedFalse = createSelector(
    selectUser,
    (user) => user.isRecorgnizedFalse
);