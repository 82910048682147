import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import { translate } from "../../../../../utils/i18n";
import TrackerUpdateModal from "./TrackerUpdateModal";
import Popover from "@material-ui/core/Popover";
import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import { hexToRgb } from "../../../../../styles/material-dashboard-react";
import { deleteTracker } from "../../../../../store/actions/checkInCheckOutActions";

const useStyles = makeStyles((theme) => ({
    popperHeader: {
        fontSize: "1.5em",
        fontWeight: "bold",
        color: "white",
        backgroundColor: `rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`,
        padding: "0.375em 0.75em",
    },
    filterPopper: {
        padding: "0.85em",
    },
    icon: {
        margin: "0 0",
    },
    popper: {
        minWidth: "25em",
        maxHeight: "35em",
        overflowY: "auto",
    },
}));

const TrackerGroupPopper = ({
    trackerGroup,
    onTrackerUpdate,
    type,
    isPopperOpen,
    setIsPopperOpen,
    popperOpen,
    idPopper,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [currentTracker, setCurrentTracker] = useState({});

    const handlePopperClose = () => setIsPopperOpen(false);

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
        useState(false);

    const handleUpdateModalClose = useCallback(
        () => setUpdateModalOpen(false),
        []
    );

    const handleDelete = useCallback(async () => {
        setIsLoading(true);
        await dispatch(deleteTracker(currentTracker._id));
        setIsLoading(false);
        await onTrackerUpdate();
    }, [currentTracker]);

    const handleDeleteModalOpen = useCallback((tracker) => {
        setCurrentTracker(tracker);
        setIsDeleteConfirmationDialogOpen(true);
    }, []);

    const handleUpdateModalOpen = useCallback((tracker) => {
        setCurrentTracker(tracker);
        setUpdateModalOpen(true);
    }, []);

    return (
        <div>
            <Popover
                id={idPopper}
                open={popperOpen}
                anchorEl={isPopperOpen}
                onClose={handlePopperClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <div id={"trackerContent"} className={classes.popper}>
                    <div className={classes.popperHeader}>
                        {translate(`${type} Trackers`)}
                    </div>
                    <div className={classes.filterPopper}>
                        {trackerGroup.map((tracker, index) => (
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems={"center"}
                                key={index}
                            >
                                <div>{moment(tracker.time).format("LT")}</div>
                                <div>
                                    <Tooltip
                                        title={translate("Edit")}
                                        arrow={true}
                                        placement={"bottom"}
                                    >
                                        <IconButton
                                            aria-describedby={"Edit"}
                                            onClick={() =>
                                                handleUpdateModalOpen(tracker)
                                            }
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            }
                                            edge="end"
                                            className={classes.icon}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={translate("Delete")}
                                        arrow={true}
                                        placement={"bottom"}
                                    >
                                        <IconButton
                                            aria-describedby={"Delete"}
                                            onClick={() =>
                                                handleDeleteModalOpen(tracker)
                                            }
                                            onMouseDown={(e) =>
                                                e.preventDefault()
                                            }
                                            edge="end"
                                            className={classes.icon}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Grid>
                        ))}
                    </div>
                </div>
            </Popover>

            <ConfirmationModal
                open={isDeleteConfirmationDialogOpen}
                handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
                handleSuccess={handleDelete}
                title={translate("Delete Tracker")}
                message={translate(
                    "Are you sure you want to delete this tracker?"
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
            <TrackerUpdateModal
                tracker={currentTracker}
                onTrackerUpdate={onTrackerUpdate}
                handleClose={handleUpdateModalClose}
                open={updateModalOpen}
            />
        </div>
    );
};

TrackerGroupPopper.propTypes = {
    trackerGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
    onTrackerUpdate: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["Check In", "Check Out"]).isRequired,
    isPopperOpen: PropTypes.bool,
    setIsPopperOpen: PropTypes.func.isRequired,
    popperOpen: PropTypes.bool.isRequired,
    idPopper: PropTypes.string,
};

export default TrackerGroupPopper;
