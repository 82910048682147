import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "../../../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import {
    Checkbox,
    Collapse,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Tooltip,
    Typography,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { DatePicker } from "@material-ui/pickers";
import { updateUser, updateUserFaceId } from "../../../../../store/actions/userActions";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UploadIcon from "@material-ui/icons/Publish";
import CancelIcon from "@material-ui/icons/Cancel";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import EventIcon from "@material-ui/icons/Event";
import { Transition } from "../../../settings/Settings";
import { useApplicationSettings } from "../../../../../hooks/settings/useApplicationSettings";
import KeyboardWindow from "../../../KeyboardWindow";
import UploadImage from "../../../../Customized Libraries/ReactImageUpload/UploadIcon.svg";
import { detectAllFaces, resizeCrop } from "../../../../../store/actions/faceCamActions"

const useStyles = makeStyles(({ primaryColor, secondaryColor, transitions }) => ({
    formControl: {
        margin: "1em 0",
    },
    singleFormItem: {
        width: "48.5%",
    },
    formItemGrid: {
        width: "48.5%",
    },
    formItemGridV2: {
        width: "32%",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: transitions.create("transform", {
            duration: transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    gridItem: {
        margin: "0.5em",
    },
    uploadPictureContainer: {
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center", 
        justifyContent: "center", 
        flexWrap: "wrap"
    },
    deleteImage: {
        position: "absolute",
        top: "6px",
        right: "-8px",
        color: "#fff",
        textAlign: "center",
        cursor: "pointer",
        fontWeight: "bold",
        fontSize: "16px",
        width: "30px",
        height: "30px",
        backgroundColor: primaryColor,
        borderRadius: "15px"
    },
    image: {
        height: "140px", 
        // width: "120px", 
        padding: "12px",
        margin: "5%"
    },
    uploadIcon: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    flexReverse: {
        flexDirection: "column-reverse"
    }
}));

const UserUpdateModal = ({ open, handleClose, onUserUpdate, user }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { messageProvider = "slack", faceRecognition } = useApplicationSettings([
        "messageProvider","faceRecognition"
    ]);
    // const userMessageProviderField = useMemo(
    //     () => (messageProvider === "slack" ? "slackId" : "teamsId"),
    //     [messageProvider]
    // );
    const [images, setImages] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const selectedFileRef = useRef(selectedFile);
    selectedFileRef.current = selectedFile;

    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isUpdateConfirmationDialogOpen, setIsUpdateConfirmationDialogOpen] =
        useState(false);
    const language = useSelector((state) => state.language.currentLanguage);
    const inputElement = useRef(null);
    const [focusInput, setFocusInput] = useState("firstName");
    const [isFocus, setIsFocus] = useState(false);
    const [layoutName, setLayoutName] = useState("default");

    const [form, setForm] = useState(user);
    const setFormValue = (field) => (event) => {
        setForm({
            ...form,
            [field]: event.target.value,
        });
    };
    const setFormValueDate = (field) => (date) => {
        setForm({
            ...form,
            [field]: date,
        });
    };

    const setFormValueTimeKeeping = (field) => (event) => {
        setForm({
            ...form,
            [field]: event.target.checked,
        });
    };

    const handleUploadImage = (e) => {
        const files = e.target.files;
        selectedFileRef.current = [];
        let res = [];
        let fileBase64 = [];
        for (const file of files) { 
            fileBase64.concat(getBase64(file));
            res.push(URL.createObjectURL(file));
        }
        setImages(res);
        setSelectedFile(fileBase64)
    }

    const getBase64 = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            selectedFileRef.current.push(cropImg(reader.result));
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        return selectedFileRef.current;
    }

    const deleteImage = (item) => {
        const newImages = images.filter((image,index) => index !== item);
        setImages(newImages);
        const newSelected = selectedFileRef.current.filter((file,index) => index !== item);
        setSelectedFile(newSelected);
    }

    const onKeyPress = (btn) => {
        if(btn === "{enter}"){
            if (form['firstName']?.length > 0 && form['lastName']?.length > 0) {
                handleUpdate(form)
             }
         }
         else if(btn === "{lock}"){
            setIsFocus(false);
         }
         else if(btn === "{shift}"){
             setLayoutName(layoutName === "default" ? "shift" : "default");
         }
    }

    const onChangeAll = (inputObj) => {
        setForm({
            ...form,
            [focusInput]: inputObj[focusInput],
        });
        
    }
    const triggerFileUpload = () => {
        inputElement.current.click();
    };

    const onUploadClick = (e) => {
        e.target.value = null;
    };

    const calculateArea = (width, height) => {
        return parseFloat(width) * parseFloat(height);
    };
    const cropImg = async (img) => {
        const detections = await detectAllFaces(img);
        const largestDetectionArea = detections.reduce(
            (prev, current) =>
                calculateArea(
                    prev.detection._box._width,
                    prev.detection._box._height
                ) >
                calculateArea(
                    current.detection._box._width,
                    current.detection._box._height
                )
                    ? prev
                    : current
        );
        // console.log(largestDetectionArea);
        return await resizeCrop(
            img,
            largestDetectionArea.detection._box._x,
            largestDetectionArea.detection._box._y - 40,
            largestDetectionArea.detection._box._width + 30,
            largestDetectionArea.detection._box._height + 60
        );
    }

    // duplicate so that min files length is 10 image
    const duplicateFileCrop = (file) => {
        const length_ = file.length;
        let new_file = []
        if(length_ < 11) {
            for (let i = 0; i < 11; i++) {
                if (new_file.length < 11) {
                    new_file = new_file.concat(file);
                }
                else {
                    new_file = new_file.slice(0, 10);
                    break;
                }
            }
            return new_file; 
        } else {
            return file;
        }
    }
    
    const handleUpdate = async () => {
        if (validate()) {
            let isSuccess = true;
            setIsLoading(true);
            if(selectedFileRef.current.length > 0 && user.faceId === undefined) {
                const fileCrop = await Promise.all(selectedFileRef.current);
                const new_file_crop = duplicateFileCrop(fileCrop)
                isSuccess = await dispatch(updateUserFaceId(user, new_file_crop, faceRecognition.server))
            }
            const success = await dispatch(updateUser(user._id, form));
            if (success && isSuccess) {
                dispatch(
                    addToNotifications({
                        type: "SUCCESS",
                        message: translate("User updated successfully!"),
                        size: "md",
                    })
                );
                handleClose();
                setIsLoading(false);
                await onUserUpdate();
            } else {
                setIsLoading(false);
                setIsUpdateConfirmationDialogOpen(false);
            }
        } else setIsUpdateConfirmationDialogOpen(false);
    };

    const validate = useCallback(() => {
        const emailError =
            form.email?.length > 0 &&
            !form.email.match("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
        // const userMessageProviderFieldError =
        //     form.role === "staff" &&
        //     form[userMessageProviderField]?.length === 0;
        const errors =
            // form.firstName.length === 0 ||
            // form.lastName.length === 0 ||
            form.displayName.length === 0 ||
            emailError 
            // || userMessageProviderFieldError;
        if (errors) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate("Please fill the required fields!"),
                    size: "md",
                })
            );
            return false;
        } else return true;
    }, [form]);

    useEffect(() => {
        if(!open) {
            setImages([]);
            setSelectedFile([]);
        }
    },[open]);

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={!isFocus ? handleClose : () => setIsFocus(false)}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                style={{marginBottom: "12%"}}
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate("User Information")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {translate("Update user Info")}
                    </DialogContentText>
                    <FormGroup className={classes.formContainer}>
                        <FormControl className={classes.formControl}>
                            <Grid container justifyContent="space-between">
                                <TextField
                                    label={translate("First Name")}
                                    placeholder={translate(
                                        "Enter user's first name"
                                    )}
                                    // required
                                    value={form.firstName}
                                    onFocus={() => {
                                        setIsFocus(true);
                                        setFocusInput("firstName")}}
                                    onChange={setFormValue("firstName")}
                                    variant="outlined"
                                    className={classes.formItemGridV2}
                                />
                                <TextField
                                    label={translate("Last Name")}
                                    placeholder={translate(
                                        "Enter user's last name"
                                    )}
                                    // required
                                    value={form.lastName}
                                    onFocus={() => {
                                        setIsFocus(true);
                                        setFocusInput("lastName")}}
                                    onChange={setFormValue("lastName")}
                                    variant="outlined"
                                    className={classes.formItemGridV2}
                                />

                                <TextField
                                    label={translate("Display Name")}
                                    placeholder={translate(
                                        "Enter user's display name"
                                    )}
                                    required
                                    value={form.displayName}
                                    onFocus={() => {
                                        setIsFocus(true);
                                        setFocusInput("displayName")}}
                                    onChange={setFormValue("displayName")}
                                    variant="outlined"
                                    className={classes.formItemGridV2}
                                />
                            </Grid>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Grid container justifyContent="space-between">
                                <TextField
                                    label={translate("Email")}
                                    placeholder={translate("Enter user's email")}
                                    value={form.email || ""}
                                    onFocus={() => {
                                        setIsFocus(true);
                                        setFocusInput("email")}}
                                    onChange={setFormValue("email")}
                                    variant="outlined"
                                    className={classes.formItemGridV2}
                                />
                                 <TextField
                                    id="outlined-select-role"
                                    select
                                    required
                                    className={classes.formItemGridV2}
                                    label={translate("Role")}
                                    value={form.role || ""}
                                    onChange={setFormValue("role")}
                                    helperText={translate(
                                        "Please select user's role"
                                    )}
                                    variant="outlined"
                                >
                                    <MenuItem value={"guest"}>
                                        {translate("Guest")}
                                    </MenuItem>
                                    <MenuItem value={"staff"}>
                                        {translate("Staff")}
                                    </MenuItem>
                                </TextField>
                                <DatePicker
                                    autoOk
                                    disableFuture
                                    inputVariant="outlined"
                                    label={translate("Date of birth")}
                                    format="MM/dd/yyyy"
                                    value={form.dob}
                                    className={classes.formItemGridV2}
                                    maxDate={new Date("2005-01-01")}
                                    allowKeyboardControl={false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <EventIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={setFormValueDate("dob")}
                                />
                            </Grid>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Grid container justifyContent="space-between">
                                <TextField
                                    label={translate("Slack Id")}
                                    placeholder={translate("Enter user's slack Id")}
                                    required={messageProvider === "slack"}
                                    disabled={form.role === "guest"}
                                    helperText={translate("User must be staff")}
                                    value={form.slackId || ""}
                                    onFocus={() => {
                                        setIsFocus(true);
                                        setFocusInput("slackId")}}
                                    onChange={setFormValue("slackId")}
                                    variant="outlined"
                                    className={classes.formItemGridV2}
                                />
                                <TextField
                                    label={translate("Teams Id")}
                                    placeholder={translate("Enter user's teams Id")}
                                    // required={messageProvider === "teams"}
                                    disabled={form.role === "guest"}
                                    helperText={translate("User must be staff")}
                                    value={form.teamsId || ""}
                                    onFocus={() => {
                                        setIsFocus(true);
                                        setFocusInput("teamsId")}}
                                    onChange={setFormValue("teamsId")}
                                    variant="outlined"
                                    className={classes.formItemGridV2}
                                />
                                <TextField
                                    label={translate("Romaji")}
                                    placeholder={translate("Enter romaji name")}
                                    value={form.romaji || ""}
                                    onChange={setFormValue("romaji")}
                                    variant="outlined"
                                    className={classes.formItemGridV2}
                                />
                            </Grid>
                        </FormControl>
                        {/* <FormControl className={classes.formControl}> */}
                            <FormControlLabel
                                value={form.isTimeKeeping}
                                // control={<Checkbox />}
                                control={<Switch color="primary" />}
                                label={translate("Record Attendance")}
                                labelPlacement="end"
                                checked={form.isTimeKeeping}
                                onChange={setFormValueTimeKeeping("isTimeKeeping")}
                                className={classnames(classes.formItemGridV2)}
                            />
                        {/* </FormControl> */}
                        <FormControl className={classnames(classes.formControl)}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {user.faceId === undefined && 
                                        <div className={classes.uploadIcon}>
                                            <img alt="" src={UploadImage} />
                                            <Button 
                                            variant="contained"
                                            type="button"
                                            color="primary" 
                                            onClick={triggerFileUpload}
                                        >
                                            {translate("Upload Image")}
                                            <input 
                                                hidden 
                                                accept="image/*" 
                                                name="image" 
                                                multiple 
                                                type="file" 
                                                onChange={handleUploadImage}
                                                onClick={onUploadClick}
                                                ref={inputElement} 
                                            />
                                        </Button>
                                        </div>
                                    }
                            </div>
                        </FormControl>
                    </FormGroup>
                    <div className={classes.uploadPictureContainer}>
                        {images.length > 0 && images.map((image, index) => {
                            return (
                                <div style={{position: "relative"}} key={index}>
                                    <div className={classes.deleteImage} onClick={(e) => deleteImage(index)}>X</div>
                                    <img src={image} alt="#" className={classes.image} />
                                </div>
                        )})}
                    </div>
                    {/* <div className={classes.expandContainer}>
                        <Tooltip
                            title={translate(expanded ? "Collapse" : "Expand")}
                            arrow={true}
                            placement={"bottom"}
                        >
                            <IconButton
                                className={classnames(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={() => setExpanded((val) => !val)}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon fontSize={"large"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <FormControl style={{ width: "70%", marginLeft: "1em" }}>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.gridItem}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {translate("UserId")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {form._id}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.gridItem}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {translate("VoiceId")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {form.voiceId
                                        ? form.voiceId
                                        : translate("No Id")}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.gridItem}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {translate("FaceId")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {form.faceId ? form.faceId : translate("No Id")}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.gridItem}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {translate("Voice")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    <Tooltip
                                        title={translate(
                                            form.hasUserRegisteredForVoice
                                                ? "Registered"
                                                : "Unregistered"
                                        )}
                                        arrow={true}
                                        placement={"bottom"}
                                    >
                                        {form.hasUserRegisteredForVoice ? (
                                            <CheckCircleIcon />
                                        ) : (
                                            <CancelIcon />
                                        )}
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.gridItem}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {translate("Face")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    <Tooltip
                                        title={translate(
                                            form.hasUserRegisteredForFace
                                                ? "Registered"
                                                : "Unregistered"
                                        )}
                                        arrow={true}
                                        placement={"bottom"}
                                    >
                                        {form.hasUserRegisteredForFace ? (
                                            <CheckCircleIcon />
                                        ) : (
                                            <CancelIcon />
                                        )}
                                    </Tooltip>
                                </Typography>
                            </Grid>
                        </FormControl>
                    </Collapse> */}

                    <DialogActions className={classes.button}>
                        <Button
                            onClick={handleClose}
                            disabled={isLoading}
                            color="primary"
                        >
                            {translate("Abort")}
                        </Button>
                        <Button
                            onClick={() => setIsUpdateConfirmationDialogOpen(true)}
                            disabled={isLoading}
                            color="primary"
                            variant={"contained"}
                        >
                            {translate("Update")}
                        </Button>
                    </DialogActions>
                    <ConfirmationModal
                        open={isUpdateConfirmationDialogOpen}
                        handleClose={() => setIsUpdateConfirmationDialogOpen(false)}
                        handleSuccess={handleUpdate}
                        title={translate("Update User")}
                        message={translate(
                            "Are you sure you want to update this user?"
                        )}
                        abortText={translate("No")}
                        successText={translate("Yes")}
                        isLoading={isLoading}
                    />
                </DialogContent>
            </Dialog>
            {open && isFocus && <KeyboardWindow 
                language={language}
                onChangeAll={(inputObj) => onChangeAll(inputObj)}
                inputName={focusInput} 
                onKeyPress={onKeyPress} 
                layoutName={layoutName} />
            }
        </>
    );
};
UserUpdateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onUserUpdate: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default UserUpdateModal;