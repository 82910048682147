import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        textAlign: "center",
        marginTop: "1%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },

    container: {
        paddingTop: "2.5rem",
        width: "70%",
        height: "72%",
    },

    containerV2: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    start: {
        backgroundColor: theme.primaryButtonTheme,
        color: theme.primaryButtonTextTheme,
        border: "6px solid " + theme.primaryButtonTextTheme,
        borderRadius: "10px",
        width: "78%",
        fontSize: "1.8em",
        "&:hover": {
            backgroundColor: theme.primaryButtonTheme,
        },
    },

    btn_back: {
        backgroundColor: "#5d6368",
        "&:hover": {
            backgroundColor: "#5d6368",
        },
    },

    form: {
        width: "60%",
        backgroundColor: "#eeeeee",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #333",
        marginBottom: "0.8em",
    },

    form_text: {
        width: "80%",
        // border: "1px solid #333",
        marginBottom: "7em",
        "&:hover, &focus": {
            backgroundColor: "#eeeeee",
        },
    },

    form_title: {
        fontSize: "1.8em",
        width: "80%",
        textAlign: "left",
        margin: "1em 0",
    },

    list_btn: {
        width: "68%",
    },

    answer: {
        width: "100%",
        backgroundColor: "#fff",
        paddingTop: 12,
        paddingBottom: 12,
        fontSize: "1.6em",
        "&:hover": {
            backgroundColor: theme.primaryButtonTextTheme,
        },
        color: theme.secondaryColor,
    },
    grid: {
        overflowY: "auto",
        justifyContent: "center",
        paddingBottom: "2em",
        paddingLeft: "1em",
        paddingRight: "1em",
    },
    gridItem: {
        textAlign: "center",
        width: "50%",
        flexWrap: "wrap",
    },

    removeDefaultLink: {
        textDecoration: "none !important",
        color: "inherit !important",
    },
    loading: {
        fill: `${theme.primaryColor} !important`,
    },
    resize: {
        fontSize: "1.6em"
    },
    "@media only screen and (max-width: 1450px) and (min-height: 720px)": {
        answer: {
            fontSize: "1.4em !important",
        },
        start: {
            fontSize: "1.4em",
        },
        form_title: {
            fontSize: "1.6em",
        },
        form_text: {
            marginBottom: "4em",
        },
    },
}));

export default useStyles;
