import { Grid, Button } from "@material-ui/core";
import ReactLoading from "react-loading";
import { translate } from "../../../utils/i18n";
import classnames from "classnames";
import { QA_TITLE_START } from "../../../styles/constants";

const QaStart = ({ classes, isLoading, qtype, onCheckInCheckOut }) => {
    return (
        <div className={classes.root}>
            <div className={classnames(classes.container, classes.welcome)}>
                <h1 className={classnames(classes.start_title)}>
                    {QA_TITLE_START}
                </h1>
                <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={classes.grid}
                    id={"answerContent"}
                >
                    <Grid item className={classes.gridItem}>
                        <div onClick={() => onCheckInCheckOut(1)}>
                            <Button
                                size="large"
                                variant="outlined"
                                className={classnames(
                                    classes.answer,
                                    classes.start
                                )}
                            >
                                {isLoading && qtype === 1 ? (
                                    <ReactLoading
                                        type={"balls"}
                                        width={"10%"}
                                        height={"10%"}
                                        className={classes.loading}
                                    />
                                ) : (
                                    translate("Check In")
                                )}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <div onClick={() => onCheckInCheckOut(2)}>
                            <Button
                                size="large"
                                variant="outlined"
                                className={classnames(
                                    classes.answer,
                                    classes.start
                                )}
                            >
                                {isLoading && qtype === 2 ? (
                                    <ReactLoading
                                        type={"balls"}
                                        width={"10%"}
                                        height={"10%"}
                                        className={classes.loading}
                                    />
                                ) : (
                                    translate("Check Out")
                                )}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default QaStart;
