// @ts-check
const {
    NODE_ENV,
    REACT_APP_CLIENT_NAME,
    REACT_APP_FALSE_COUNT,
    REACT_APP_REGISTRATION_DETECTION_INTERVAL,
    REACT_APP_DETECTION_INTERVAL,
    REACT_APP_RESET_COUNT,
    REACT_APP_FALSE_COUNT_DETECT,
    REACT_APP_AWS_SAMPLERATE,
    REACT_APP_TIMEOUT_SHOW_TOOLTIP,
} = process.env;

// /**
//  * @type {{
//  *    NODE_ENV:string,
//  * REACT_APP_CLIENT_NAME:string,
//  * REACT_APP_FALSE_COUNT: number,
//  * REACT_APP_REGISTRATION_DETECTION_INTERVAL: number,
//  * REACT_APP_DETECTION_INTERVAL: number
//  * }}
//  */
const env = {
    NODE_ENV,
    REACT_APP_CLIENT_NAME,
    REACT_APP_FALSE_COUNT: parseInt(REACT_APP_FALSE_COUNT, 10),
    REACT_APP_RESET_COUNT: parseInt(REACT_APP_RESET_COUNT, 10),
    REACT_APP_REGISTRATION_DETECTION_INTERVAL: parseInt(REACT_APP_REGISTRATION_DETECTION_INTERVAL, 10),
    REACT_APP_DETECTION_INTERVAL: parseInt(REACT_APP_DETECTION_INTERVAL, 10),
    REACT_APP_FALSE_COUNT_DETECT: parseInt(REACT_APP_FALSE_COUNT_DETECT),
    REACT_APP_AWS_SAMPLERATE: parseInt(REACT_APP_AWS_SAMPLERATE),
    TIMEOUT_SHOW_TOOLTIP: parseInt(REACT_APP_TIMEOUT_SHOW_TOOLTIP),
};

module.exports = { env };
