import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, TextField, Button, Grid } from "@material-ui/core";
import { translate } from "../../utils/i18n";
import useStyles from "../../styles/UI/FormEnterStaffIdStyles";
import classnames from "classnames";
import { getNameFromId } from "../../store/actions/chatActions";
import { addToNotifications } from "../../store/actions/notificationActions";
import { checkExitUserCode } from "../../store/actions/userActions";
import { cvFullWidthToHalfWidth } from "../../utils/helpers";
import ReactLoading from "react-loading";

function FormEnterStaffId({ title, reset }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const buttonRef = useRef();
    const [inputStaff, setInputStaff] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true);
        const isExit = await dispatch(checkExitUserCode(inputStaff));
        if (!isExit) {
            const res = await dispatch(getNameFromId({ user_id: cvFullWidthToHalfWidth(inputStaff) }));
            if (res) {
                setInputStaff("");
                setIsLoading(false);
                const state = {
                    firstName: "",
                    lastName: "",
                    displayName: res.result,
                    code: cvFullWidthToHalfWidth(inputStaff),
                    romaji: res.romaji,
                    role: "guest",
                    redirectTo: "/",
                    showRegistrationModal: false,
                };
                history.push({
                    pathname: "/face-registration",
                    state,
                });
            } else {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
            dispatch(addToNotifications(
                {
                    message: translate("The employee code has already been registered"),
                    type: "WARNING",
                    size: "md",
                    duration: 4,
                }
            ))
        }
    };

    const handleComeBack = () => {
        setInputStaff("");
        reset();
    };

    return (
        <Box className={classes.root}>
            <div className={classnames(classes.container, classes.containerV2)}>
                <div className={classes.form}>
                    <div className={classes.form_title}>{title}</div>
                    <div className={classes.form_text}>
                        <TextField
                            // multiline
                            required
                            // label={translate("Staff")}
                            // maxRows={4}
                            // minRows={4}
                            fullWidth
                            value={inputStaff}
                            onChange={(e) => setInputStaff(e.target.value)}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: classes.resize,
                                },
                            }}
                        />
                    </div>
                </div>
                <div className={classes.list_btn}>
                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        className={classes.grid}
                    >
                        <Grid item className={classes.gridItem}>
                            <div ref={buttonRef}>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className={classnames(
                                        classes.start,
                                        classes.btn_back
                                    )}
                                    onClick={handleComeBack}
                                >
                                    {translate("ホーム画面に戻る")}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item className={classes.gridItem}>
                            <div>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className={classes.start}
                                    style={{
                                        height:
                                            buttonRef.current &&
                                            buttonRef.current.clientHeight,
                                    }}
                                    onClick={handleSubmit}
                                >
                                    {isLoading ? (
                                        <ReactLoading
                                            type={"balls"}
                                            width={"10%"}
                                            height={"10%"}
                                            className={classes.loading}
                                        />
                                    ) : (
                                        translate("OK")
                                    )}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Box>
    );
}

export default FormEnterStaffId;
