import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "../../../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormGroup from "@material-ui/core/FormGroup";
import { Button, FormControl } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import { decodeDescription } from "../../../../../store/actions/googleCalendarActions";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import uuid from "react-uuid";
import moment from "moment-timezone";
import Switch from "@material-ui/core/Switch";
import { useApplicationSettings } from "../../../../../hooks/settings/useApplicationSettings";
import { getUsers } from "../../../../../store/actions/userActions";
import CryptoJS from "crypto-js";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import { Transition } from "../../../settings/Settings";
import AttendeeModal from "./AttendeeModal";
import { unFreezeObjOrArray } from "../../../../../hooks/settings/settingsModal/useAvatarSettings";
import {
    deleteScheduledMessageWithProvider,
    scheduleMessageWithProvider,
} from "../../../../../store/actions/teamsActions";
import { MESSAGE_PROVIDER_FIELD_NAMES } from "../../../../../styles/constants";
import {
    createCalendarEventWithProvider,
    updateCalendarEventWithProvider,
} from "../../../../../store/actions/outlookCalendarActions";
import { findIana } from "windows-iana";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: "1em",
    },
    chip: {
        margin: "0.3em",
    },
    smallTextField: {
        width: "53%",
        marginLeft: "2em",
    },
    switch: {
        marginLeft: "1em",
        marginTop: "0.25em",
    },
    helperText: {
        marginTop: "0.5em",
        fontSize: "0.8rem",
    },
}));

const ScheduleMeetingModal = ({
    open,
    handleClose,
    onEventAddOrUpdate,
    update,
    event,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const SECRET_KEY = process.env.REACT_APP_AES_SECRET_KEY;
    const {
        messageProviderConfig: config,
        messageProvider = "slack",
        calendarProvider,
    } = useApplicationSettings([
        "messageProviderConfig",
        "messageProvider",
        "calendarProvider",
    ]);
    const messageProviderFieldNames = useMemo(
        () => MESSAGE_PROVIDER_FIELD_NAMES[messageProvider],
        [messageProvider]
    );

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [summary, setSummary] = useState("");
    const [location, setLocation] = useState("");
    const [description, setDescription] = useState("");
    const [attendees, setAttendees] = useState([]);

    const [attendeeToUpdate, setAttendeeToUpdate] = useState({});

    const [notifyAttendees, setNotifyAttendees] = useState(true);
    const [notifyStaffs, setNotifyStaffs] = useState(true);
    const [scheduledStaffNotificationTime, setScheduledStaffNotificationTime] =
        useState("5");
    const [scheduledMessageIds, setScheduledMessageIds] = useState([]);
    const [initialAdditionalInfo, setInitialAdditionalInfo] = useState({});

    const [isAttendeeModalOpen, setIsAttendeeModalOpen] = useState(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdatingAttendee, setIsUpdatingAttendee] = useState(false);

    const formatDate = useCallback(
        (date) => moment(date).format("HH:mm DD/MM/YYYY"),
        []
    );

    const handleSubmit = async () => {
        const error = validate();
        if (!error) {
            let success;
            setIsLoading(true);
            if (update) success = await handleUpdate();
            else success = await handleCreate();
            setIsLoading(false);
            if (success) {
                setIsConfirmationDialogOpen(false);
                handleClose();
                await onEventAddOrUpdate();
            }
        } else {
            setIsConfirmationDialogOpen(false);
        }
    };

    const handleCreate = async () => {
        const event = buildEventObj();
        const params = {
            sendUpdates: notifyAttendees ? "all" : "none",
            sendNotifications: notifyAttendees,
        };
        const createdEvent = await dispatch(
            createCalendarEventWithProvider(calendarProvider, params, event)
        );
        if (createdEvent) {
            dispatch(
                addToNotifications({
                    type: "SUCCESS",
                    message: translate("Event created successfully!"),
                    size: "md",
                })
            );
            if (!hasMeetingStarted()) {
                if (notifyStaffs) {
                    const ids = await handleScheduleMessageWithProvider();
                    if (ids.length > 0) {
                        const _updatedEvent = buildEventObj(ids);
                        params.eventId = createdEvent.id;
                        await dispatch(
                            updateCalendarEventWithProvider(
                                calendarProvider,
                                params,
                                _updatedEvent
                            )
                        );
                    }
                } else await handleScheduleMessageWithProvider();
            }
            resetForm();
        }
        return createdEvent;
    };

    const handleUpdate = async () => {
        const updatedEvent = buildEventObj();
        const params = {
            sendUpdates: notifyAttendees ? "all" : "none",
            sendNotifications: notifyAttendees,
            eventId: event.id,
        };
        const success = await dispatch(
            updateCalendarEventWithProvider(
                calendarProvider,
                params,
                updatedEvent
            )
        );
        if (success) {
            dispatch(
                addToNotifications({
                    type: "SUCCESS",
                    message: translate("Event updated successfully!"),
                    size: "md",
                })
            );
            if (!hasMeetingStarted()) {
                if (notifyStaffs) {
                    const ids = await handleScheduleMessageWithProvider();
                    if (ids.length > 0) {
                        const _updatedEvent = buildEventObj(ids);
                        await dispatch(
                            updateCalendarEventWithProvider(
                                calendarProvider,
                                params,
                                _updatedEvent
                            )
                        );
                    }
                } else await handleScheduleMessageWithProvider();
            }
            resetForm();
        }
        return success;
    };

    const chipOnDelete = useCallback(
        (id) => {
            const newAttendees = attendees.filter(
                (attendee, index) => index !== id
            );
            setAttendees(newAttendees);
        },
        [attendees]
    );

    const handleOpenNewAttendeeModal = useCallback(() => {
        setIsAttendeeModalOpen(true);
        setIsUpdatingAttendee(false);
    }, []);

    const handleOpenUpdateAttendeeModal = useCallback(
        (_id) => {
            const attendee = attendees.find((attendee, index) => index === _id);
            setIsAttendeeModalOpen(true);
            setIsUpdatingAttendee(true);
            setAttendeeToUpdate(attendee);
        },
        [attendees]
    );

    const getScheduleMessage = useCallback(
        () =>
            translate(
                "The meeting '<title>' scheduled to begin at <startTime> will start soon"
            )
                .replace("<title>", summary)
                .replace("<startTime>", formatDate(startTime)),
        [startTime, summary]
    );

    const handleScheduleMessageWithProvider = async () => {
        let ids = [];
        const postAt = moment(startTime)
            .subtract(scheduledStaffNotificationTime, "minutes")
            .unix();

        const messageProviderField =
            messageProvider === "slack" ? "slackId" : "teamsId";
        // get users where role=staff and messageProviderField is not null
        const queryString = `${messageProviderField}[regex]=${encodeURIComponent(
            ".+"
        )}&role=staff&limit=500`;
        const staffs = await dispatch(getUsers(queryString));

        if (staffs?.success) {
            const staffsInAttendees = attendees.filter(
                (attendee) => attendee.isStaff
            );
            const validStaffsInAttendees = staffs.data.filter((staff) => {
                return !!staffsInAttendees.find(
                    (attendee) =>
                        attendee.displayName.toLowerCase() ===
                            staff.slackId.toLowerCase() ||
                        attendee.displayName.toLowerCase() ===
                            staff.displayName.toLowerCase() ||
                        (attendee.email && staff.email
                            ? attendee.email.toLowerCase() ===
                              staff.email.toLowerCase()
                            : false)
                );
            });
            if (validStaffsInAttendees.length > 0) {
                if (update) {
                    ids = await handleUpdateScheduledMessages(
                        validStaffsInAttendees
                    );
                } else {
                    ({ ids } = await dispatch(
                        scheduleMessageWithProvider({
                            message: getScheduleMessage(),
                            staffIdMessageProvider: validStaffsInAttendees.map(
                                (attendee) => attendee[messageProviderField]
                            ),
                            channel:
                                config.meeting[
                                    messageProviderFieldNames.channels
                                ],
                            postAt,
                            messageProvider,
                        })
                    ));
                }
            }
        }
        return ids;
    };

    const handleUpdateScheduledMessages = useCallback(
        async (validStaffsInAttendees) => {
            await dispatch(
                deleteScheduledMessageWithProvider({
                    scheduledMessageIds,
                    messageProvider,
                })
            );
            const postAt = moment(startTime)
                .subtract(scheduledStaffNotificationTime, "minutes")
                .unix();
            const messageProviderField =
                messageProvider === "slack" ? "slackId" : "teamsId";
            const { ids } = await dispatch(
                scheduleMessageWithProvider({
                    message: getScheduleMessage(),
                    staffIdMessageProvider: validStaffsInAttendees.map(
                        (attendee) => attendee[messageProviderField]
                    ),
                    channel: config.meeting[messageProviderFieldNames.channels],
                    postAt,
                    messageProvider,
                })
            );
            return ids;
        },
        [
            scheduledMessageIds,
            startTime,
            scheduledStaffNotificationTime,
            summary,
            messageProvider,
        ]
    );

    const hasMeetingStarted = useCallback(
        () => moment().unix() > moment(startTime).unix(),
        [startTime]
    );

    const buildAttendees = useCallback(
        (removeInvalidGAPIFields = false) => {
            let filteredAttendees = unFreezeObjOrArray(attendees);
            if (removeInvalidGAPIFields)
                filteredAttendees = filteredAttendees.filter(
                    (attendee) =>
                        attendee.email !== undefined &&
                        attendee.email.length !== 0
                );

            return filteredAttendees.map((attendee) => {
                if (removeInvalidGAPIFields) delete attendee["isStaff"];
                if (attendee.email?.length === 0) delete attendee["email"];
                delete attendee["id"];
                return attendee;
            });
        },
        [attendees]
    );

    const buildDescription = useCallback(
        (scheduledMessageIds) => {
            const additionalInfo = {
                notifyAttendees,
                notifyStaffs,
                scheduledStaffNotificationTime,
                scheduledMessageIds,
                attendees: buildAttendees(),
            };
            const encodedInfo = CryptoJS.AES.encrypt(
                JSON.stringify(additionalInfo),
                SECRET_KEY
            ).toString();
            return `${description}<br/><br/><br/>||${encodedInfo}||`;
        },
        [
            notifyAttendees,
            notifyStaffs,
            scheduledStaffNotificationTime,
            description,
            buildAttendees,
        ]
    );

    const getDateTimeForCalendarProvider = useCallback(
        (date) => {
            switch (calendarProvider) {
                case "google":
                    return moment(date).format();
                case "outlook":
                    return moment(date).format().split("+")[0];
                default:
                    return moment(date).format();
            }
        },
        [calendarProvider]
    );

    const buildEventObj = useCallback(
        (ids = []) => {
            return {
                summary,
                location,
                description: buildDescription(ids),
                start: {
                    dateTime: getDateTimeForCalendarProvider(startTime),
                },
                end: {
                    dateTime: getDateTimeForCalendarProvider(endTime),
                },
                attendees: buildAttendees(true),
            };
        },
        [
            summary,
            location,
            buildDescription,
            startTime,
            endTime,
            buildAttendees,
            getDateTimeForCalendarProvider,
        ]
    );

    const resetForm = useCallback(() => {
        setSummary("");
        setLocation("");
        setDescription("");
        setAttendees([]);
        setScheduledMessageIds([]);
    }, []);

    const validate = useCallback(() => {
        const errors =
            summary.length === 0 ||
            location.length === 0 ||
            (notifyStaffs && scheduledStaffNotificationTime.length === 0) ||
            isNaN(new Date(startTime)) ||
            isNaN(new Date(endTime));
        if (errors) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate("Please fill the required fields!"),
                    size: "md",
                })
            );
            return true;
        } else return false;
    }, [
        summary,
        location,
        notifyStaffs,
        scheduledStaffNotificationTime,
        startTime,
        endTime,
        dispatch,
    ]);

    useEffect(() => {
        if (open) {
            if (update && event) {
                const timeZone =
                    findIana(event.start.timeZone).length > 0
                        ? findIana(event.start.timeZone)
                        : event.start.timeZone;
                setStartTime(
                    new Date(
                        moment
                            .utc(event.start.dateTime)
                            .tz(timeZone)
                            .format("LLL")
                    )
                );
                setEndTime(
                    new Date(
                        moment
                            .utc(event.end.dateTime)
                            .tz(timeZone)
                            .format("LLL")
                    )
                );
                setSummary(event.summary);
                setLocation(event.location);
                const { description, additionalInfo } = decodeDescription(
                    event.description
                );
                setDescription(description);
                setInitialAdditionalInfo(additionalInfo);
                if (additionalInfo) {
                    const {
                        notifyStaffs,
                        scheduledStaffNotificationTime,
                        scheduledMessageIds,
                        notifyAttendees,
                    } = additionalInfo;
                    let { attendees = [] } = additionalInfo;
                    if (attendees.length === 0) {
                        attendees = attendees.concat(event.attendees);
                    }
                    setNotifyAttendees(notifyAttendees);
                    setNotifyStaffs(notifyStaffs);
                    setScheduledStaffNotificationTime(
                        scheduledStaffNotificationTime
                    );
                    setScheduledMessageIds(scheduledMessageIds);
                    const _attendees = attendees.map((attendee) => {
                        return { ...attendee, id: uuid() };
                    });
                    setAttendees(_attendees);
                }
            } else {
                setStartTime(new Date(moment().add(1, "hour").format()));
                setEndTime(new Date(moment().add(5, "hour").format()));
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {translate("Schedule Meeting")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {translate(
                        "Please fill the form below to schedule a meeting"
                    )}
                </DialogContentText>
                <FormGroup className={classes.formContainer}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label={translate("Meeting Title")}
                            placeholder={translate(
                                "Enter meeting title/summary"
                            )}
                            required
                            fullWidth
                            value={summary}
                            onChange={(e) => setSummary(e.target.value)}
                            className={classes.formItem}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label={translate("Location")}
                            placeholder={translate("Enter meeting location")}
                            required
                            fullWidth
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className={classes.formItem}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Grid container justifyContent="space-between">
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                label={translate("Start")}
                                value={startTime}
                                onChange={setStartTime}
                                disablePast
                                format="yyyy/MM/dd HH:mm"
                                showTodayButton
                            />
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                label={translate("End")}
                                value={endTime}
                                onChange={setEndTime}
                                disablePast
                                format="yyyy/MM/dd HH:mm"
                                showTodayButton
                            />
                        </Grid>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <TextField
                            label={translate("Meeting description")}
                            placeholder={translate("Enter meeting description")}
                            fullWidth
                            multiline
                            minRows={2}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className={classes.formItem}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <div style={{ marginTop: "0.5em" }}>
                            <DialogContentText id="alert-dialog-slide-description">
                                {translate("Attendees")}:
                            </DialogContentText>
                            <div style={{ maxWidth: "80%" }}>
                                {attendees.map((attendee, id) => (
                                    <Chip
                                        variant="outlined"
                                        className={classes.chip}
                                        size={"medium"}
                                        key={id}
                                        label={attendee.displayName}
                                        onClick={() =>
                                            handleOpenUpdateAttendeeModal(
                                                id
                                            )
                                        }
                                        onDelete={() =>
                                            chipOnDelete(id)
                                        }
                                    />
                                ))}
                            </div>
                            <IconButton
                                onClick={handleOpenNewAttendeeModal}
                                color="primary"
                                aria-label="add staff"
                                component="span"
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <DialogContentText id="alert-dialog-slide-description">
                            {translate("Notify Attendees")}:
                        </DialogContentText>
                        <Switch
                            checked={notifyAttendees}
                            onChange={(e) =>
                                setNotifyAttendees(e.target.checked)
                            }
                            color="primary"
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <DialogContentText id="alert-dialog-slide-description">
                            {translate("Notify staffs")}:
                        </DialogContentText>
                        <Grid
                            container
                            spacing={3}
                            style={{ marginTop: "0.25em" }}
                        >
                            <Switch
                                checked={notifyStaffs}
                                onChange={(e) =>
                                    setNotifyStaffs(e.target.checked)
                                }
                                color="primary"
                                className={classes.switch}
                            />

                            <TextField
                                label={translate(
                                    messageProvider === "slack"
                                        ? "Minutes before slack notification"
                                        : "Minutes before teams notification"
                                )}
                                placeholder={translate(
                                    "Enter time in minutes before notifying staffs"
                                )}
                                disabled={!notifyStaffs}
                                required={notifyStaffs}
                                value={scheduledStaffNotificationTime}
                                onChange={(e) =>
                                    setScheduledStaffNotificationTime(
                                        e.target.value
                                    )
                                }
                                className={classes.smallTextField}
                            />
                        </Grid>
                    </FormControl>
                </FormGroup>
                <DialogActions className={classes.dialogAction}>
                    <Button
                        disabled={isLoading}
                        onClick={handleClose}
                        color="primary"
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        disabled={isLoading}
                        onClick={() => setIsConfirmationDialogOpen(true)}
                        color="primary"
                        variant={"contained"}
                    >
                        {translate(update ? "Update Event" : "Create Event")}
                    </Button>
                </DialogActions>
                <ConfirmationModal
                    open={isConfirmationDialogOpen}
                    handleClose={() => setIsConfirmationDialogOpen(false)}
                    handleSuccess={handleSubmit}
                    title={translate(update ? "Update Event" : "Create Event")}
                    message={translate(
                        `Are you sure you want to ${
                            update ? "update" : "create"
                        } this event?`
                    )}
                    abortText={translate("No")}
                    successText={translate("Yes")}
                    isLoading={isLoading}
                />
                <AttendeeModal
                    handleClose={() => setIsAttendeeModalOpen(false)}
                    open={isAttendeeModalOpen}
                    update={update}
                    isUpdatingAttendee={isUpdatingAttendee}
                    setAttendees={setAttendees}
                    attendees={attendees}
                    attendeeToUpdate={attendeeToUpdate}
                />
            </DialogContent>
        </Dialog>
    );
};

ScheduleMeetingModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onEventAddOrUpdate: PropTypes.func.isRequired,
    update: PropTypes.bool,
    event: PropTypes.object,
};

ScheduleMeetingModal.defaultProps = {
    update: false,
};

export default ScheduleMeetingModal;
