import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
    ({ primaryColor, secondaryColor, transitions }) => ({
        formItem: {
            marginTop: "1em !important",
            width: "100%",
        },
        button: {
            paddingBottom: "1.5em",
        },
        formControl: {
            margin: "1em 0",
        },
        singleFormItem: {
            width: "48.5%",
        },
        formItemGrid: {
            width: "48.5%",
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: transitions.create("transform", {
                duration: transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        gridItem: {
            margin: "0.5em",
        },
        uploadPictureContainer: {
            display: "flex", 
            flexDirection: "row", 
            alignItems: "center", 
            justifyContent: "center", 
            flexWrap: "wrap"
        },
        deleteImage: {
            position: "absolute",
            top: "0px",
            right: "0px",
            color: "#fff",
            textAlign: "center",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
            width: "30px",
            height: "30px",
            backgroundColor: primaryColor,
            borderRadius: "15px"
        },
        image: {
            height: "140px", 
            padding: "12px"
        },
        uploadIcon: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        flexReverse: {
            flexDirection: "column-reverse"
        },
        loading: {
            fill: `${primaryColor} !important`,
        },
    })
);

export default useStyles;