import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from "react";
import userStyles from "../../../styles/UI/modals/FaceRegistrationModalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { translate } from "../../../utils/i18n";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createUser, updateUser } from "../../../store/actions/userActions";
import { addToNotifications } from "../../../store/actions/notificationActions";
import {
    Collapse,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Tooltip,
    Typography,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import UploadIcon from "@material-ui/icons/Publish";
import DateFnsUtils from "@date-io/date-fns";
import ConfirmationModal from "./ConfirmationModal";
import { Transition } from "../settings/Settings";
import { useApplicationSettings } from "../../../hooks/settings/useApplicationSettings";
import KeyboardWindow from "../KeyboardWindow";
import { registerUser, registerUserNoImage } from "../../../store/actions/userActions";
import UploadImage from "../../Customized Libraries/ReactImageUpload/UploadIcon.svg";
import { detectAllFaces, resizeCrop } from "../../../store/actions/faceCamActions"
import ReactLoading from "react-loading";

const initFormError = (form) => {
    const keys = Object.keys(form);
    const _formError = {};
    keys.forEach((key) => {
        _formError[key] = { error: false, text: null };
    });
    return _formError;
};

const FaceRegistrationModal = (props) => {
    const classes = userStyles();
    const { 
        open, 
        handleClose, 
        history, 
        role: role_, 
        canSetRole, 
        isAddNewUser = false,
        isChangeListUser = false ,
        setIsChangeListUser = null 
    } = props;
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        displayName: "",
        dob: null,
        email: "",
        slackId: "",
        teamsId: "",
        role: role_,
        isTimeKeeping: true,
    });
    const [images, setImages] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const selectedFileRef = useRef(selectedFile);
    const inputElement = useRef(null);
    selectedFileRef.current = selectedFile;
    const [formError, setFormError] = useState(initFormError(form));
    const { messageProvider = "slack", faceRecognition } = useApplicationSettings([
        "messageProvider","faceRecognition"
    ]);
    const userMessageProviderField = useMemo(
        () => (messageProvider === "slack" ? "slackId" : "teamsId"),
        [messageProvider]
    );
    const [focusInput, setFocusInput] = useState("firstName");
    const [isForcus, setIsForcus] = useState(false);

    const userInfo = useSelector((state) => state.user.userInfo);
    const userFaceId = useSelector((state) => state.user.userFaceId);
    const language = useSelector((state) => state.language.currentLanguage);
    const [layoutName, setLayoutName] = useState("default");
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateConfirmationDialogOpen, setIsUpdateConfirmationDialogOpen] =
        useState(false);

    const setFormValue = (field) => (event) => {
        const required = ["firstName", "lastName", userMessageProviderField];
        if (required.includes(field)) {
            resetFormError(field);
        }

        setForm({
            ...form,
            [field]: event.target.value,
        });
    };

    const setFormValueDate = (field) => (date) => {
        setForm({
            ...form,
            [field]: date,
        });
    };

    const resetFormError = (field) => {
        setFormError({
            ...formError,
            [field]: { error: false, text: null },
        });
    };

    const setFormValueTimeKeeping = (field) => (event) => {
        setForm({
            ...form,
            [field]: event.target.checked,
        });
    };

    const resetForm = () => {
        setForm({
            firstName: "",
            lastName: "",
            displayName: "",
            dob: null,
            email: "",
            slackId: "",
            teamsId: "",
            role: role_,
            isTimeKeeping: true,
        });
        setExpanded(false);
        setIsLoading(false);
    };

    const triggerFileUpload = () => {
        inputElement.current.click();
    };

    const onUploadClick = (e) => {
        e.target.value = null;
    };

    const handleUpdate = useCallback(async () => {
        setIsLoading(true);
        const _form = {
            ...form,
            email:
                form.email === null || form.email?.length === 0
                    ? null
                    : form.email,
            [userMessageProviderField]:
                form[userMessageProviderField] === null ||
                form[userMessageProviderField]?.length === 0
                    ? null
                    : form[userMessageProviderField],
        };
        if (_form.displayName.length === 0) {
            _form.displayName = `${_form.firstName} ${_form.lastName}`;
        }
        if (userInfo?._id) {
            const user = await dispatch(updateUser(userInfo._id, _form));
            if (user) {
                dispatch(
                    addToNotifications({
                        message: translate("User info updated!"),
                        type: "SUCCESS",
                        size: "md",
                    })
                );
                resetForm();
            }
        } else {
            const user = await dispatch(
                createUser({
                    ..._form,
                    faceId: userFaceId,
                    hasUserRegisteredForFace: true,
                })
            );
            if (user) {
                dispatch(
                    addToNotifications({
                        message: translate("User info created!"),
                        type: "SUCCESS",
                        size: "md",
                    })
                );
                resetForm();
            }
        }
        setIsLoading(false);
        setIsUpdateConfirmationDialogOpen(false);
        handleClose();
    }, [form, userFaceId, userInfo, userMessageProviderField]);

    const calculateArea = (width, height) => {
        return parseFloat(width) * parseFloat(height);
    };

    const cropImg = async (img) => {
        const detections = await detectAllFaces(img);
        const largestDetectionArea = detections.reduce(
            (prev, current) =>
                calculateArea(
                    prev.detection._box._width,
                    prev.detection._box._height
                ) >
                calculateArea(
                    current.detection._box._width,
                    current.detection._box._height
                )
                    ? prev
                    : current
        );
        // console.log(largestDetectionArea);
        return await resizeCrop(
            img,
            largestDetectionArea.detection._box._x,
            largestDetectionArea.detection._box._y - 40,
            largestDetectionArea.detection._box._width + 30,
            largestDetectionArea.detection._box._height + 60
        );
    }
    
    // duplicate so that min files length is 10 image
    const duplicateFileCrop = (file) => {
        const length_ = file.length;
        let new_file = []
        if(length_ < 11) {
            for (let i = 0; i < 11; i++) {
                if (new_file.length < 11) {
                    new_file = new_file.concat(file);
                }
                else {
                    new_file = new_file.slice(0, 10);
                    break;
                }
            }
            return new_file; 
        } else {
            return file;
        }
    }

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        const _form = {
            ...form,
            email:
                form.email === null || form.email?.length === 0
                    ? null
                    : form.email,
            [userMessageProviderField]:
                form[userMessageProviderField] === null ||
                form[userMessageProviderField]?.length === 0
                    ? null
                    : form[userMessageProviderField],
        };
        if (_form.displayName.length === 0) {
            _form.displayName = `${_form.firstName} ${_form.lastName}`;
        }
        if (!isAddNewUser){
            history.push({
                pathname: "/face-registration",
                state: _form,
            });
        } else {
            if(selectedFileRef.current.length > 0){
                const fileCrop = await Promise.all(selectedFileRef.current);
                const new_file_crop = duplicateFileCrop(fileCrop)
                await dispatch(
                    registerUser(
                        _form,
                        new_file_crop,
                        faceRecognition.server
                    )
                )
            }
            else {
                await dispatch(registerUserNoImage(_form))
            }
            setIsChangeListUser(!isChangeListUser);
        }
        resetForm();
        handleClose();
    }, [form, userMessageProviderField]);

    const handleError = useCallback(
        (type, values) => {
            switch (type) {
                case "email":
                    if (
                        values?.length > 0 &&
                        !values.match("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
                    )
                        return {
                            error: true,
                            text: translate("Field must be a valid mail"),
                        };
                    return { error: false, text: null };
                case "required":
                    let error = false;
                    const errors = {};
                    values.map((value) => {
                        if (form[value] === null || form[value].length === 0) {
                            error = true;
                            errors[value] = {
                                error: true,
                                text: translate("Field is required"),
                            };
                        } else errors[value] = { error: false, text: null };
                    });
                    setFormError({
                        ...formError,
                        ...errors,
                    });
                    return error;
                default:
                    return { error: false, text: null };
            }
        },
        [form, formError]
    );

    const validateForm = useCallback(() => {
        const { email, role } = form;
        const required = ["firstName", "lastName"];
        // if (role === "staff") required.push(userMessageProviderField);
        const errors = [
            handleError("required", required),
            handleError("email", email).error,
        ];
        if (errors.includes(true)) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate(
                        "Please resolve all the errors in the form"
                    ),
                    size: "md",
                })
            );
            return false;
        } else return true;
    }, [form, handleError]);

    const handleUpdateOrRegister = useCallback(() => {
        if (validateForm()) {
            if (userFaceId && !isAddNewUser) {
                setIsUpdateConfirmationDialogOpen(true);
            } else {
                handleSubmit();
            }
        }
    }, [userFaceId, validateForm, handleSubmit]);

    const onKeyPress = (btn) => {
        if(btn === "{enter}"){
            if (form['firstName']?.length > 0 && form['lastName']?.length > 0) {
                handleSubmit(form)
             }
         }
         else if(btn === "{lock}"){
            setIsForcus(false);
         }
         else if(btn === "{shift}"){
             setLayoutName(layoutName === "default" ? "shift" : "default");
         }
    }

    const onChangeAll = useCallback((inputObj) => {
        setForm({
            ...form,
            [focusInput]: inputObj[focusInput],
        });
    }, [focusInput]);

    const handleUploadImage = (e) => {
        const files = e.target.files;
        selectedFileRef.current = [];
        let res = [];
        let fileBase64 = [];
        for (const file of files) { 
            fileBase64.concat(getBase64(file));
            res.push(URL.createObjectURL(file));
        }
        setImages(res);
        setSelectedFile(fileBase64)
    }

    const getBase64 = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            selectedFileRef.current.push(cropImg(reader.result));
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        return selectedFileRef.current;
    }
    const deleteImage = (item) => {
        const newImages = images.filter((image,index) => index !== item);
        setImages(newImages);
        const newSelected = selectedFileRef.current.filter((file,index) => index !== item);
        setSelectedFile(newSelected);
    }
    useEffect(() => {
        if (open) {
            if (
                userFaceId &&
                Object.keys(userInfo).length !== 0 &&
                userInfo.constructor === Object && !isAddNewUser
            )
                // if user is recognized but there is info in db. Update fields
                setForm(userInfo);
            else {
                resetForm()
                setSelectedFile([]);
                setImages([]);
            };
        }
    }, [open]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={!isForcus ? !isLoading && handleClose : () => setIsForcus(false) } 
                fullWidth={true}
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                style={{marginBottom: "12%"}}
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate("Staff Information")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {translate(
                            "Enter your staff information for face registration"
                        )}
                    </DialogContentText>
                    <FormGroup className={classes.formContainer}>
                        <FormControl className={classes.formControl}>
                            <Grid container justifyContent="space-between">
                                <TextField
                                    label={translate("First Name")}
                                    placeholder={translate(
                                        "Enter your first name"
                                    )}
                                    required
                                    value={form.firstName}
                                    onChange={setFormValue("firstName")}
                                    onFocus={() => {
                                        setIsForcus(true);
                                        setFocusInput("firstName")}}
                                    
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                    error={formError.firstName.error}
                                    helperText={formError.firstName.text}
                                />
                                <TextField
                                    label={translate("Last Name")}
                                    placeholder={translate(
                                        "Enter your last name"
                                    )}
                                    required
                                    value={form.lastName}
                                    onChange={setFormValue("lastName")}
                                    onFocus={() => {
                                        setIsForcus(true);
                                        setFocusInput("lastName")}}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                    error={formError.lastName.error}
                                    helperText={formError.lastName.text}
                                />
                            </Grid>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Grid container justifyContent="space-between">
                                <TextField
                                    label={translate("Display Name")}
                                    placeholder={translate(
                                        "Enter your display name"
                                    )}
                                    value={form.displayName}
                                    onChange={setFormValue("displayName")}
                                    onFocus={() => {
                                        setIsForcus(true);
                                        setFocusInput("displayName")}}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                />
                                <TextField
                                    label={translate("Email")}
                                    placeholder={translate("Enter your email")}
                                    value={form.email || ""}
                                    onChange={setFormValue("email")}
                                    onFocus={() => {
                                        setIsForcus(true);
                                        setFocusInput("email")}}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                    error={
                                        handleError("email", form["email"])
                                            .error
                                    }
                                    helperText={
                                        handleError("email", form["email"]).text
                                    }
                                />
                            </Grid>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Grid container justifyContent="space-between">
                                <TextField
                                    id="outlined-select-role"
                                    select
                                    // required
                                    disabled={!canSetRole}
                                    className={classes.formItemGrid}
                                    label={translate("Role")}
                                    value={form.role || ""}
                                    onChange={setFormValue("role")}
                                    helperText={translate(
                                        "Please select your role"
                                    )}
                                    variant="outlined"
                                >
                                    <MenuItem value={"guest"}>
                                        {translate("Guest")}
                                    </MenuItem>
                                    <MenuItem value={"staff"}>
                                        {translate("Staff")}
                                    </MenuItem>
                                </TextField>
                                {messageProvider === "slack" ? (
                                    <>
                                    {/* // <TextField
                                    //     label={translate("Slack Id")}
                                    //     placeholder={translate(
                                    //         "Enter your slack Id"
                                    //     )}
                                    //     required={form.role !== "guest"}
                                    //     disabled={form.role === "guest"}
                                    //     value={form.slackId || ""}
                                    //     onChange={setFormValue("slackId")}
                                    //     variant="outlined"
                                    //     className={classes.formItemGrid}
                                    //     error={
                                    //         form.role === "staff"
                                    //             ? formError.slackId.error
                                    //             : false
                                    //     }
                                    //     helperText={
                                    //         form.role === "staff"
                                    //             ? formError.slackId.text
                                    //             : ""
                                    //     }
                                    // /> */}
                                    <DatePicker
                                        autoOk
                                        disableFuture
                                        inputVariant="outlined"
                                        label={translate("Date of birth")}
                                        format="MM/dd/yyyy"
                                        value={form.dob}
                                        maxDate={new Date("2005-01-01")}
                                        allowKeyboardControl={false}
                                        className={classes.formItemGrid}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <EventIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={setFormValueDate("dob")}
                                    />
                                    <FormControl 
                                        className={classnames(
                                            classes.formControl,
                                            classes.singleFormItem
                                        )}
                                    >
                                        <FormControlLabel
                                            value={form.isTimeKeeping}
                                            // control={<Checkbox />}
                                            control={<Switch color="primary" />}
                                            label={translate("Record Attendance")}
                                            labelPlacement="end"
                                            checked={form.isTimeKeeping}
                                            onChange={setFormValueTimeKeeping("isTimeKeeping")}
                                        />
                                    </FormControl>
                                    </>
                                ) : (
                                    <TextField
                                        label={translate("Teams Id")}
                                        placeholder={translate(
                                            `Enter your ${userMessageProviderField}`
                                        )}
                                        // required={form.role !== "guest"}
                                        disabled={form.role === "guest"}
                                        value={form.teamsId || ""}
                                        onChange={setFormValue("teamsId")}
                                        onFocus={() => {
                                            setIsForcus(true);
                                            setFocusInput("teamsId")}}
                                        variant="outlined"
                                        className={classes.formItemGrid}
                                        error={
                                            form.role === "staff"
                                                ? formError.teamsId.error
                                                : false
                                        }
                                        helperText={
                                            form.role === "staff"
                                                ? formError.teamsId.text
                                                : ""
                                        }
                                    />
                                )}
                            </Grid>
                        </FormControl>
                        {messageProvider !== "slack" &&
                        <>
                            <FormControl
                                className={classnames(
                                    classes.formControl,
                                    // classes.singleFormItem
                                )}
                            >
                                <Grid container justifyContent="space-between">
                                    <DatePicker
                                        autoOk
                                        disableFuture
                                        inputVariant="outlined"
                                        label={translate("Date of birth")}
                                        format="MM/dd/yyyy"
                                        value={form.dob}
                                        maxDate={new Date("2005-01-01")}
                                        allowKeyboardControl={false}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <EventIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={setFormValueDate("dob")}
                                        className={classes.formItemGrid}
                                    />
                                </Grid>
                            </FormControl>
                                <FormControlLabel
                                    value={form.isTimeKeeping}
                                    // control={<Checkbox />}
                                    control={<Switch color="primary" />}
                                    label={translate("Record Attendance")}
                                    labelPlacement="end"
                                    checked={form.isTimeKeeping}
                                    onChange={setFormValueTimeKeeping("isTimeKeeping")}
                                />
                        </>
                        }
                        <FormControl className={classnames(classes.formControl,classes.formUploadBtn)}>
                            {isAddNewUser &&
                                <>
                                    <div className={classes.uploadIcon}>
                                        <img src={UploadImage} alt="" style={{height: "50px"}}/>
                                        <Button 
                                            variant="contained"
                                            type="button"
                                            color="primary" 
                                            onClick={triggerFileUpload}
                                        >
                                            {translate("Upload Image")}
                                            <input 
                                                hidden 
                                                accept="image/*" 
                                                name="image" 
                                                multiple 
                                                type="file" 
                                                onChange={handleUploadImage}
                                                onClick={onUploadClick}
                                                ref={inputElement} 
                                            />
                                        </Button>
                                    </div>
                                </>
                            }
                        </FormControl>
                    </FormGroup>

                    <div className={classes.uploadPictureContainer}>
                        {images.length > 0 && images.map((image, index) => (
                            <div style={{position: "relative"}} key={index}>
                                <div className={classes.deleteImage} onClick={(e) => deleteImage(index)}>X</div>
                                <img src={image} alt="#" className={classes.image} />
                            </div>
                        ))}
                    </div>

                    {!isAddNewUser &&
                    <>
                        <div className={classes.expandContainer}>
                            <Tooltip
                                title={translate(expanded ? "Collapse" : "Expand")}
                                arrow={true}
                                placement={"bottom"}
                            >
                                <IconButton
                                    className={classnames(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                    })}
                                    onClick={() => setExpanded((val) => !val)}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon fontSize={"large"} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <FormControl
                                style={{ width: "70%", marginLeft: "1em" }}
                            >
                                {form._id && (
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        className={classes.gridItem}
                                    >
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {translate("UserId")}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {form._id}
                                        </Typography>
                                    </Grid>
                                )}
                                {form.voiceId !== undefined && (
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        className={classes.gridItem}
                                    >
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {translate("VoiceId")}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {form.voiceId
                                                ? form.voiceId
                                                : translate("No Id")}
                                        </Typography>
                                    </Grid>
                                )}
                                {(userFaceId || form.faceId !== undefined) && (
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        className={classes.gridItem}
                                    >
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {translate("FaceId")}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {form.faceId
                                                ? form.faceId
                                                : userFaceId
                                                ? userFaceId
                                                : translate("No Id")}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    className={classes.gridItem}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {translate("Voice")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        <Tooltip
                                            title={translate(
                                                form.hasUserRegisteredForVoice
                                                    ? "Registered"
                                                    : "Unregistered"
                                            )}
                                            arrow={true}
                                            placement={"bottom"}
                                        >
                                            {form.hasUserRegisteredForVoice ? (
                                                <CheckCircleIcon />
                                            ) : (
                                                <CancelIcon />
                                            )}
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    className={classes.gridItem}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {translate("Face")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        <Tooltip
                                            title={translate(
                                                form.hasUserRegisteredForFace ||
                                                    userFaceId
                                                    ? "Registered"
                                                    : "Unregistered"
                                            )}
                                            arrow={true}
                                            placement={"bottom"}
                                        >
                                            {form.hasUserRegisteredForFace ||
                                            userFaceId ? (
                                                <CheckCircleIcon />
                                            ) : (
                                                <CancelIcon />
                                            )}
                                        </Tooltip>
                                    </Typography>
                                </Grid>
                            </FormControl>
                        </Collapse>
                    </>
                    }
                    <DialogActions className={classes.button}>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            disabled={isLoading}
                        >
                            {translate("Abort")}
                        </Button>
                        <Button
                            onClick={handleUpdateOrRegister}
                            color="primary"
                            variant={"contained"}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <ReactLoading
                                    type={"balls"}
                                    width={"40%"}
                                    height={"40%"}
                                    className={classes.loading}
                                />
                            ) : 
                            translate(userFaceId && !isAddNewUser ? "Update" : "Register")
                            }
                        </Button>
                    </DialogActions>
                    <ConfirmationModal
                        open={isUpdateConfirmationDialogOpen}
                        handleClose={() =>
                            setIsUpdateConfirmationDialogOpen(false)
                        }
                        handleSuccess={handleUpdate}
                        title={translate("Update Your Info")}
                        message={translate("Are you sure you want to update?")}
                        abortText={translate("No")}
                        successText={translate("Yes")}
                        isLoading={isLoading}
                    />
                </DialogContent>
            </Dialog>
            {open && isForcus && <KeyboardWindow 
                    language={language}
                    onChangeAll={(inputObj) => onChangeAll(inputObj)}
                    inputName={focusInput} 
                    onKeyPress={onKeyPress} 
                    layoutName={layoutName}
            />}
        </MuiPickersUtilsProvider>
    );
};

FaceRegistrationModal.propTypes = {
    history: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    role: PropTypes.oneOf(["guest", "staff"]),
    canSetRole: PropTypes.bool,
};

FaceRegistrationModal.defaultProps = {
    role: "staff",
    canSetRole: true,
};

export default memo(FaceRegistrationModal);